import ArvoreMenu from "./ArvoreMenu";
import BuscaMenu from "./BuscaMenu";
import ExternoMenu from "./ExternoMenu";

export default class Navegacao {

    constructor(arvores=[], busca=[], externo=[]) {
        this.arvores = arvores;
        this.busca = busca;
        this.externo = externo;
    }

    static build(json={}){
        const arvores = json.menu.map(j => ArvoreMenu.build(j));
        const busca = json.busca.map(j => BuscaMenu.build(j));
        const externo = json.externo.map(j => ExternoMenu.build(j));
        return new Navegacao(arvores, busca, externo);
    }

}