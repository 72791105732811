<template>
  <section>
    <PortalPainelDestaque />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { Getters } from '../store/types';
import PortalPainelDestaque from '../components/destaque/PortalPainelDestaque';

export default {

    name: 'Servicos',

    components: {
        PortalPainelDestaque,
    },

    computed: {
        ...mapGetters({
            carregado: Getters.NOTIFICACOES.STATUS_CARREGADO
        })
    },

}
</script>