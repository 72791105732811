import Tarefa from "./Tarefa";
import Aviso from "./Aviso";
import Novidade from "./Novidade";
// import Novidade from "./Novidade";

export default class Notificacao {

    constructor(tarefas=[], avisos=[], novidades=[]) {
        this.tarefas = tarefas;
        this.avisos = avisos;
        this.novidades = novidades;
    }

    static buildNovidades(tarefas, avisos){
        
        return tarefas.filter(t => !t.adiado)
                        .concat(avisos)
                        .map(obj => Novidade.build(obj))
                        .sort((n1, n2) => n1.data - n2.data )
                        .slice(0 ,5)
    }

    static build(json={}){
        const tarefas = json.tarefas.map(j => Tarefa.build(j));
        const avisos = json.avisos.map(j => Aviso.build(j));

        const novidades = Notificacao.buildNovidades(tarefas, avisos);

        return new Notificacao(tarefas, avisos, novidades);
    }

}