export default class Novidade {

    constructor(tipo, codigo, codigoArea, nomeArea, iconeArea, titulo, url, data, visualizacao) {
        this.tipo = tipo;
        this.codigo = codigo;
        this.codigoArea = codigoArea;
        this.nomeArea = nomeArea;
        this.iconeArea = iconeArea;
        this.titulo = titulo;
        this.url = url;
        this.data = data;
        this.visualizacao = visualizacao;
    }

    isVisualizado(){
        return !!this.visualizacao;
    }

    getKey() {
        return `${this.tipo}:${this.codigo}:${this.codigoArea}`;
    }

    static build(objeto={}){
        const tipo = objeto.cadastradoEm ? 'alerta' : 'aviso';
        return new Novidade(
            tipo,
            objeto.codigo,
            objeto.codigoArea || '',
            objeto.nomeArea || '',
            objeto.iconeArea || '',
            tipo === "alerta" ? objeto.titulo : objeto.assunto,
            objeto.url || '',
            tipo === "alerta" ? objeto.adiadoPara || objeto.cadastradoEm : objeto.dataInicio,
            tipo === "alerta" ? objeto.visualizadoEm : objeto.visualizacao,
        );
    }

}