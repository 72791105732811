export default class SituacaoProServ {

  constructor(tipsitprs, dsctipsitprs) {
      this.tipsitprs = tipsitprs
      this.dsctipsitprs = dsctipsitprs
  }

  toString(){
    return this.dsctipsitprs
  }

  static fromJSON(json={}){
    return new SituacaoProServ(
      json.tipsitprs,
      json.dsctipsitprs,
      json.cadastradoEm
    )
  }
}