import { VARIANTES } from '../constantes';

export const DROPDOWN_VARIANTES = [
    ...VARIANTES,
    {'pt': 'link', 'en': 'link', 'nome': 'Link'}, 
];

export const DROPDOWN_TAMANHOS = [
    {'pt': 'pq', 'en': 'sm', 'nome': 'pequeno'}, 
    {'pt': '', 'en': '', 'nome': ''}, 
    {'pt': 'gd', 'en': 'lg', 'nome': 'grande'}, 
];