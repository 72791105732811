const Processamento = () => import(/* webpackChunkName: "chunk-nova-folha" */ '../views/Processamento')
const Analise = () => import(/* webpackChunkName: "chunk-nova-folha" */ '../views/Analise')
const CargaDados = () => import(/* webpackChunkName: "chunk-nova-folha" */ '../views/CargaDados')
const ConsultaDados = () => import(/* webpackChunkName: "chunk-nova-folha" */ '../views/ConsultaDados')
const Calendario = () => import(/* webpackChunkName: "chunk-nova-folha" */ '../views/Calendario')
export const routes = [
  {
    path: '/',
    name: 'Processamento',
    component: Processamento,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'Nova Folha' }
      ]
    }
  },
  {
    path: '/analise',
    name: 'Analise',
    component: Analise,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'Nova Folha', link: '/novaFolha/' },
        { nome: 'Análise' }
      ]
    }
  },
  {
    path: '/consulta',
    name: 'ConsultaDados',
    component: ConsultaDados,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'Nova Folha', link: '/novaFolha/' },
        { nome: 'Consulta de dados' }
      ]
    }
  },
  {
    path: '/cronograma',
    name: 'Cronograma',
    component: Calendario,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'Nova Folha', link: '/novaFolha/' },
        { nome: 'Cronograma' }
      ]
    }
  },
  {
    path: '/cargas',
    name: 'CargaDados',
    component: CargaDados,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'Nova Folha', link: '/novaFolha/' },
        { nome: 'Cargas de dados' }
      ]
    }
  }
]