import { PortalServico } from '@/utils';
import Aviso from './Aviso';
import Notificacao from './Notificacao';

/**
 * @type {NotificacoesServico}
 */
export default class NotificacoesServico extends PortalServico {

    async adiarTarefa(codigo, codigoArea, novaData){
        return this.post(`/wsportal/notificacoes/tarefa/adiar/${codigoArea}/${codigo}/`, { novaData });
    }

    async baixarTarefa(codigo, codigoArea){
        return this.post(`/wsportal/notificacoes/tarefa/baixar/${codigoArea}/${codigo}/`, null);
    }

    async obterAvisoweb(aviso) {
        const self = this;
        const resultado = await self.get(`/wsportal/notificacoes/aviso/${aviso.codigoArea}/${aviso.codigo}`);
        return Aviso.build(resultado);
    }

    async obterNotificacoes() {
        const self = this;
        const resultado = await self.get('/wsportal/notificacoes/');
        return Notificacao.build(resultado);
    }

    async visualizarTarefa(codigo, codigoArea){
        return this.post(`/wsportal/notificacoes/tarefa/visualizar/${codigoArea}/${codigo}/`);
    }

    async listarNoticias(){
        return this.get('/wsportal/notificacoes/feed-noticias');
    }

}