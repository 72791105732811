<template>
  <div class="container">
    <uspAlerta
      class="col-lg-10 offset-lg-1"
      :variante="alert.variant"
      v-show="!!alert.message"
    >
      <span v-html="alert.message"></span>
    </uspAlerta>

    <p>E-mails cadastrados:</p>

    <uspLista :horizontal="true">
      <uspListaItem>
        <span class="d-flex flex-column"
          ><b>E-mail USP:</b> {{ emails["usp"] }}</span
        >
      </uspListaItem>

      <uspListaItem
        v-for="tipo in tipos"
        :key="tipo.flag"
        :class="{ active: tipo.flag == flagSelecionada }"
        class="list-group-item list-group-item-action d-flex flex-row justify-content-between align-items-center"
      >
        <span class="d-flex flex-column"
          ><b>{{ tipo.label }}:</b> {{ emails[tipo.prop] }}</span
        >
        <button
          class="btn btn-sm btn-outline-primary"
          :disabled="tipo.flag == flagSelecionada"
          @click="selecionar(tipo.flag)"
        >
          <i class="fas fa-sm fa-edit"></i> Editar
        </button>
      </uspListaItem>
    </uspLista>

    <div v-if="flagSelecionada" class="mt-5">
      <fieldset>
        <legend>Alterar: {{ tipo.label }} ({{ tipo.desc }})</legend>

        <form
          @submit.prevent="alterarEmail(tipo.flag)"
          v-if="flagSelecionada"
          novalidate
        >
          <div class="form-group">
            <label class="sr-only" :for="tipo.nameAlt">
              {{ tipo.label }} Atual
            </label>
            <input
              :id="tipo.nameAlt"
              disabled
              type="email"
              :name="tipo.nameAlt"
              class="form-control"
              :placeholder="tipo.label + ' Atual'"
              :value="emails[tipo.prop]"
            />
          </div>
          <div class="form-group">
            <label class="sr-only" :for="tipo.nameNovo">
              Novo {{ tipo.label }}
            </label>
            <input
              :id="tipo.nameNovo"
              type="email"
              :name="tipo.nameNovo"
              class="form-control"
              :placeholder="'Novo ' + tipo.label"
              v-model="dados['novo-' + tipo.flag]"
            />
          </div>
          <div class="form-group">
            <label class="sr-only" :for="tipo.nameConf"
              >Confirmar Novo {{ tipo.label }}
            </label>
            <input
              :id="tipo.nameConf"
              type="email"
              :name="tipo.nameConf"
              class="form-control"
              :placeholder="'Confirmar Novo ' + tipo.label"
              v-model="dados['conf-' + tipo.flag]"
            />
          </div>
          <button
            type="submit"
            class="btn btn-primary btn-block mb-2"
            :disabled="carregando"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-if="carregando"
            ></span>
            Alterar {{ tipo.label }}
          </button>
        </form>
      </fieldset>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const validarEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export default {
  name: "AlterarEmail",

  data() {
    return {
      flagSelecionada: null,
      tipos: [
        {
          flag: "P",
          label: "E-mail Principal",
          desc: "recebimento de informativos",
          nameAtual: "emailPrincAtual",
          nameConf: "emailPrincConf",
          nameNovo: "emailPrincNovo",
          prop: "principal",
        },
        {
          flag: "A",
          label: "E-mail Alternativo",
          desc: "recuperação de senha",
          nameAtual: "emailAltAtual",
          nameConf: "emailAltConf",
          nameNovo: "emailAltNovo",
          prop: "alternativo",
        },
      ],
      // erros: [],
      alert: {
        variant: "perigo",
        message: "",
        //  target: "",
      },
      carregando: false,
      // loginUsuario: "",
      emails: {},
      dados: {},
    };
  },

  created() {
    this.obterEmails();
  },

  computed: {
    ...mapGetters({
      login: "auth/login",
    }),
    erro() {
      return this.erros.join("<br/>");
    },
    validoEmailNovo() {
      return validarEmail(this.emailNovo);
    },

    tipo() {
      return this.tipos.find((el) => el.flag == this.flagSelecionada);
    },
  },
  methods: {
    ...mapActions({
      obter: "auth/obterEmails",
      salvar: "auth/alterarEmail",
    }),
    selecionar(flag) {
      this.flagSelecionada = flag;
    },
    alterarEmail(tipo) {
      const self = this,
        emailNovo = self.dados["novo-" + tipo],
        emailConf = self.dados["conf-" + tipo];

      if (self.validarEmails(tipo, emailNovo, emailConf)) {
        self
          .salvar({
            tipo: tipo,
            email: emailNovo,
          })
          .then((resposta) => {
            self.alert = {
              message: resposta.mensagem,
              variant: resposta.retorno === "erro" ? "perigo" : "sucesso",
            };
          })
          .catch((erro) => {
            self.alert.message = erro.mensagem;
            self.alert.variant = "perigo";
          })
          .finally(() => (self.carregando = false));
      }
    },
    obterEmails() {
      const self = this;
      self.carregando = true;
      self
        .obter()
        .then((resposta) => {
          this.emails = resposta.retorno === "erro" ? {} : resposta;
          self.alert = {
            message: resposta.mensagem,
            variant: resposta.retorno === "erro" ? "perigo" : "sucesso",
          };
        })
        .catch((erro) => {
          this.emails = {};
          self.alert = {
            message: erro,
            variant: "perigo",
          };
        })
        .finally(() => (self.carregando = false));
    },
    validarEmails(tipo, emailNovo, emailConf) {
      let erros = [];
      this.alert = {};
      if (!emailNovo) {
        erros.push(
          tipo === "A"
            ? "E-mail alternativo novo não foi preenchido."
            : "E-mail principal novo não foi preenchido."
        );
      } else if (!validarEmail(emailNovo)) {
        erros.push(
          tipo === "A"
            ? "E-mail alternativo novo não é válido."
            : "E-mail principal novo não é valido."
        );
      }
      if (!emailConf) {
        erros.push(
          tipo === "A"
            ? "E-mail alternativo de confirmação não foi preenchido."
            : "E-mail principal de confirmação não foi preenchido."
        );
      } else if (!validarEmail(emailConf)) {
        erros.push(
          tipo === "A"
            ? "E-mail alternativo de confirmação não é válido."
            : "E-mail principal de confirmação não é valido."
        );
      }

      if (emailConf !== emailNovo) {
        erros.push(
          tipo === "A"
            ? "E-mail alternativo novo e de confirmação não são iguais."
            : "E-mail principal novo e de confirmação não são iguais."
        );
      }

      if (erros.length > 0) {
        let mensagem = `Não foi possível alterar o e-mail ${
          tipo === "A" ? "alternativo" : "principal"
        }:<ul> `;
        erros.forEach((erro) => (mensagem += `<li>${erro}</li>`));
        mensagem += "</ul>";
        this.alert = {
          variant: "perigo",
          message: mensagem,
        };
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.ativo {
  background: orange;
}

.list-group-item {
  flex-grow: 1;
  flex-basis: 100%;
}

legend {
  margin-bottom: 2rem;
  font-size: 1.1rem;
  font-weight: 600;
}
</style>
