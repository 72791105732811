

export default class CriterioProServ {

  constructor(codtipcrtprs, nomtipcrtprs, dsctipcrtprs, vlrmaxcrtprs, ordaptcrt, staexijus){
    this.codtipcrtprs = codtipcrtprs
    this.nomtipcrtprs = nomtipcrtprs
    this.dsctipcrtprs = dsctipcrtprs
    this.vlrmaxcrtprs = vlrmaxcrtprs
    this.ordaptcrt = ordaptcrt
    this.staexijus = staexijus
  }

  static fromJSON(json={}){
    return new CriterioProServ(
      json.codtipcrtprs,
      json.nomtipcrtprs,
      json.dsctipcrtprs,
      json.vlrmaxcrtprs,
      json.ordaptcrt,
      json.staexijus
    )
  }

}