<template>
  <ul 
    class="pagination" 
    :class="estiloTamanho"
  >
    <li class="page-item">
      <a
        class="page-link"
        :aria-label="anteriorTxt"
        @click.prevent="trocarPaginaAnterior"
      >
        <span 
          v-if="icones" 
          aria-hidden="true"
        >
          &laquo;
        </span>
        <span 
          v-else
        >
          {{ anteriorTxt }}
        </span>
      </a>
    </li>

    <li 
      v-show="paginas > numPaginas"
      class="page-item disabled"
    >
      <a class="page-link disabled">
        ...
      </a>
    </li>

    <li
      v-for="n in paginas"
      v-show="
        (n > atual - (Math.ceil(numPaginas / 2)) && n < atual + Math.ceil(numPaginas / 2)) ||
          (n <= numPaginas && atual < Math.ceil(numPaginas / 2)) ||
          n > paginas.length - numPaginas
      "
      :key="n"
      class="page-item"
      :class="{ active: atual === n }"
      :aria-current="atual === n ? 'page' : false"
    >
      <a 
        class="page-link" 
        @click.prevent="trocarPagina(n)"
      >
        {{ n }}
        <span 
          v-if="atual === n"
          class="sr-only"
        >
          (atual)
        </span>
      </a>
    </li>

    <li 
      v-show="paginas > numPaginas"
      class="page-item disabled"
    >
      <a class="page-link disabled">
        ...
      </a>
    </li>

    <li class="page-item">
      <a
        :aria-label="proximoTxt"
        class="page-link"
        @click.prevent="trocarProximaPagina"
      >
        <span 
          v-if="icones" 
          aria-hidden="true"
        >
          &raquo;
        </span>
        <span v-else>
          {{ proximoTxt }}
        </span>
      </a>
    </li>
  </ul>
</template>

<script>
import { PAGINACAO_TAMANHOS } from "./constantes";

export default {
  name: 'UspPaginacao',

  props: {
    icones: {
      type: Boolean,
      default: true,
    },
    paginas: {
      type: Number,
      required: true,
    },
    inicio: {
      type: Number,
      default: 1,
    },
    numPaginas:{
      type: Number,
      default: 5
    },
    anteriorTxt: {
      type: String,
      default: "Anterior",
    },
    proximoTxt: {
      type: String,
      default: "Próximo",
    },
    tamanho: {
      type: String,
      default: "",
      validator: (value) => {
        return (
          PAGINACAO_TAMANHOS.map(function(e) {
            return e.pt;
          }).indexOf(value) !== -1
        );
      },
    },
  },

  data() {
    return {
      atual: this.inicio,
    };
  },

  computed: {
    estiloTamanho() {
      const tamanhoBS = PAGINACAO_TAMANHOS.find(
        (elemento) => elemento.pt == this.tamanho
      ).en;
      return this.tamanho != "normal" ? `pagination-${tamanhoBS}` : "";
    },
  },

  watch: {
    atual(nova) {
      this.$emit("paginar", nova);
    },
  },

  methods: {
    trocarProximaPagina() {
      if (this.atual !== this.paginas) {
        this.atual++;
      }
    },
    trocarPaginaAnterior() {
      if (this.atual !== 1) {
        this.atual--;
      }
    },
    trocarPagina(n) {
      this.atual = n;
    },
  },
  
}
</script>

<style lang="scss">
@import "./src/componentes/estilos/temas.scss";
$pagination-border-width: 1;

/* Gerais */
.pagination {
  margin: 0.5em 0;
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.9rem;
    margin-left: -1px;
    line-height: 1.25;
    border-radius: 30px;
    text-align: center;
    margin-right: 5px;
    margin-right: 0.25rem;
  }

  .page-item:first-child .page-link {
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
  }

  .page-item.disabled .page-link {
    pointer-events: none;
    cursor: auto;
  }
}

.pagination-sm {
  .page-link {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Tema */

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .pagination {
      .page-item .page-link {
        color: map-get($tema, "usp-elemento-cor-do-texto");
        background-color: transparent;
        border: 1px solid #dee2e6;
        &:hover {
          cursor: pointer;
        }
      }

      .page-item.active .page-link {
        color: map-get($tema, "usp-item-cor-do-texto");
        background-color: map-get($tema, "usp-item-selecionado");
        border-color: map-get($tema, "usp-item-selecionado");
      }

      .page-item.disabled .page-link {
        color: #6c757d;
        border-color: #dee2e6;
        opacity: 0.7;
      }
    }

    .pagination-sm {
      .page-link {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
</style>
