import { Actions, Getters, Mutations } from '@/processos/store/types.js';
import AndamentoServico from '@/processos/dominio/AndamentoServico.js'
import Ocorrencia from '../dominio/modelos/Ocorrencia';
import TipoOcorrencia from '../dominio/modelos/TipoOcorrencia';
import Temporario from '../dominio/modelos/Temporario';
import Assinatura from '../dominio/modelos/Assinatura';

const api = AndamentoServico.build({ formDataArquivos: 'arquivo' })

const mimeTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']
const tamanhoMaxMB = 20;

const validarArquivo = (arquivo) => {
    if(!arquivo){
        return 'Arquivo deve ser um valor válido!'
    }
    if(!mimeTypes.includes(arquivo.type)){
        return `Tipo passado no arquivo ${arquivo.name} não permitido (${arquivo.type}), valores permitidos são: ${mimeTypes.join(', ')}.` 
    }
    let tamanhoMb = arquivo.size / (10**6)
    if(tamanhoMb > tamanhoMaxMB){
        return `Arquivo ${arquivo.name} muito grande (${tamanhoMb.toFixed(2)}MB), máximo permitido é de ${tamanhoMaxMB}MB.`
    }
    return null;
}

// Estado inicial
const initial_state = {
    erros: [],
    andamentos: [],
    assinatura: {},
    carregando: true,
    documentos: []
}

// getters
const initial_getters = {
    [Getters.ANDAMENTOS.ASSINATURA]: (st) => st.assinatura,
    [Getters.ANDAMENTOS.DOCUMENTOS]: (st) => st.documentos,
    [Getters.ANDAMENTOS.CARREGANDO]: (st) => st.carregando,
    [Getters.ANDAMENTOS.ERROS]: (st) => st.erros,
    [Getters.ANDAMENTOS.EXTENCOES_ACEITAS]: () => mimeTypes.map(tipo => '.'+tipo.split('/')[1]).join(', '),
    [Getters.ANDAMENTOS.LISTA]: (st) => st.andamentos,
    [Getters.ANDAMENTOS.ULTIMO_ANDAMENTO]: (st) => st.andamentos[st.andamentos.length - 1],
    [Getters.ANDAMENTOS.ERROS]: (st) => st.erros,
    [Getters.ANDAMENTOS.STATUS_EDICAO]: (st) => st.andamentos.findIndex(a => a.novasOcorrencias?.length > 0) !== -1
}

// actions
const actions = {
    [Actions.ANDAMENTOS.ANEXAR]: ({ commit }, { arquivo, ocorrencia }) => {
        return api.carregarArquivo(arquivo)
            .then(dados => commit(Mutations.ANDAMENTOS.ANEXO, { ocorrencia, dados}))
            .catch(error => {
                if(error.mensagemDesenvolvedor){
                    console.error(error.mensagemDesenvolvedor);
                }
                commit(Mutations.ANDAMENTOS.ERRO, error);
            })
    },
    [Actions.ANDAMENTOS.ASSINAR]: ({ commit }, { numpro, codarqpdl, codpesasi, tipasi, numand, numseqocoand }) => {
        commit(Mutations.ANDAMENTOS.ERROS, [])
        return api.assinar(numpro, codarqpdl, codpesasi, tipasi )
                .then(retorno => {
                    commit(Mutations.ANDAMENTOS.STATUS_ASSINATURA, { codpesasi, numand, numseqocoand })
                    commit(Mutations.PROCESSOS.ACAO_AVISO, retorno.mensagem)
                })
    },
    [Actions.ANDAMENTOS.BUSCAR]: ({ commit }, { numpro, busca, codetrcfc, codetrdoc}) => {
        return api.buscaArquivos(numpro, busca, codetrcfc, codetrdoc)
        .then(retorno => {
            commit(Mutations.ANDAMENTOS.DOCUMENTOS, retorno)
        })
    },
    [Actions.ANDAMENTOS.CARREGAR_ARQUIVO]: ({ commit, dispatch, getters }, arquivos) => {
        Array.from(arquivos)
            .filter(arquivo => { 
                let erro = validarArquivo(arquivo)
                if(erro){
                    commit(Mutations.ANDAMENTOS.ERRO, erro);
                    return false;
                }
                return true;
            })
            .forEach(arquivo => {
                const andamento = getters[Getters.ANDAMENTOS.ULTIMO_ANDAMENTO]
                const tipo = new TipoOcorrencia('ocoAnexacao', 'Anexação')
                const temporario = new Temporario(null, arquivo.name, arquivo.size, null, arquivo.type)
                const ocorrencia = new Ocorrencia(andamento.numand)

                ocorrencia.assinatura = new Assinatura()
                ocorrencia.tipo = tipo
                ocorrencia.temporario = temporario

                commit(Mutations.ANDAMENTOS.NOVA_OCORRENCIA, { andamento, ocorrencia })
                dispatch(Actions.ANDAMENTOS.ANEXAR, { arquivo, ocorrencia })
        });
    },
    [Actions.ANDAMENTOS.CRIAR_DOCUMENTO]: ({ commit, getters }) => {
        commit(Mutations.ANDAMENTOS.ERROS, [])
        const andamento = getters[Getters.ANDAMENTOS.ULTIMO_ANDAMENTO]
        const tipo = new TipoOcorrencia('ocoGeracao', 'Geração de Documento')
        const ocorrencia = new Ocorrencia(andamento.numand)
        ocorrencia.tipo = tipo
        commit(Mutations.ANDAMENTOS.NOVA_OCORRENCIA, { andamento, ocorrencia })
    },
    [Actions.ANDAMENTOS.CRIAR_INFORMACAO]: ({ commit, getters }) => {
        commit(Mutations.ANDAMENTOS.ERROS, [])
        const andamento = getters[Getters.ANDAMENTOS.ULTIMO_ANDAMENTO]
        const tipo = new TipoOcorrencia('ocoInformacao', 'Informação')
        const ocorrencia = new Ocorrencia(andamento.numand)
        ocorrencia.tipo = tipo
        commit(Mutations.ANDAMENTOS.NOVA_OCORRENCIA, { andamento, ocorrencia })
    },
    [Actions.ANDAMENTOS.GERAR_ANEXO]: ({ commit, getters }, { documento, ocorrencia }) => {
        commit(Mutations.ANDAMENTOS.ERROS, [])
        const andamento = getters[Getters.ANDAMENTOS.ULTIMO_ANDAMENTO]
        documento.sgleta = andamento.entrada.estacao.sgleta
        documento.texto = documento.texto.replaceAll('<p>','')
                                         .replaceAll('</p>','<br><br>')

        return api.gerarDocumento(documento)
            .then( retorno => {
                if(documento.acao == 'salvar'){
                    commit(Mutations.ANDAMENTOS.DOCUMENTO_GERADO, { ocorrencia, dados: retorno })
                }
                return retorno
            })
    },
    [Actions.ANDAMENTOS.LISTAR]: ({ commit }, { numpro }) => {
        commit(Mutations.ANDAMENTOS.CARREGANDO, true)
        commit(Mutations.ANDAMENTOS.LISTA, [])
        commit(Mutations.ANDAMENTOS.ERROS, [])
        return api.listar(numpro)
                 .then((retorno) => {
                    commit(Mutations.ANDAMENTOS.LISTA, retorno)
                    commit(Mutations.ANDAMENTOS.LIMPAR_DOCUMENTOS)
                 })
                 .finally(() => commit(Mutations.ANDAMENTOS.CARREGANDO, false))
    },
    [Actions.ANDAMENTOS.REMOVER_ARQUIVO]: ({ commit }, { codarqtmp }) => {
        commit(Mutations.ANDAMENTOS.ERROS, [])
        return api.removerArquivo(codarqtmp)
    },
    [Actions.ANDAMENTOS.REMOVER_NOVA_OCORRENCIA]: ({ commit, dispatch }, ocorrencia) => {
        if(ocorrencia.temporario){
            dispatch(Actions.ANDAMENTOS.REMOVER_ARQUIVO, { codarqtmp: ocorrencia.temporario.codarqtmp })
        }
        commit(Mutations.ANDAMENTOS.REMOVER_NOVA_OCORRENCIA, ocorrencia)
        commit(Mutations.ANDAMENTOS.ERROS, [])
        commit(Mutations.PROCESSOS.CONFIRMAR, {})
    },
    [Actions.ANDAMENTOS.REMOVER_OCORRENCIA]: ({ commit }, { numpro, numand, numseqocoand }) => {
        commit(Mutations.ANDAMENTOS.ERROS, [])
        commit(Mutations.PROCESSOS.CONFIRMAR_SALVANDO, true)
        return api.removerOcorrencia(numpro, numand, numseqocoand)
                   .then((retorno) => {
                        commit(Mutations.ANDAMENTOS.REMOVER_OCORRENCIA, { numand, numseqocoand })
                        commit(Mutations.PROCESSOS.CONFIRMAR, {})
                        commit(Mutations.PROCESSOS.ACAO_AVISO, retorno.mensagem)
                        return retorno
                   })
                   .catch(error => commit(Mutations.PROCESSOS.CONFIRMAR_ERRO, error.erro))
                   .finally(() => commit(Mutations.PROCESSOS.CONFIRMAR_SALVANDO, false))
    },
    [Actions.ANDAMENTOS.SALVAR_OCORRENCIA]: ({ commit, rootState }, { numpro, ocorrencia, dados }) => {
        let metodo = '',
            numseqocoand = ocorrencia.numseqocoand,
            tipo = ocorrencia.tipo.codetroco
        commit(Mutations.ANDAMENTOS.ERROS, [])
        if(tipo === 'ocoInformacao'){
            metodo = numseqocoand ? 'alterarInformacao' : 'inserirInformacao' 
        } else if(tipo === 'ocoAnexacao'){
            if(numseqocoand){
                metodo = 'alterarArquivo'
                dados.codarqpdl = ocorrencia.arquivo.codarqpdl
            } else {
                const tipoDoc = rootState.estrutura.tiposDocumento.find(td => td.codetrvlrpdl === dados.codetrdoc)
                dados.codarqtmp = ocorrencia.temporario.codarqtmp
                if(!tipoDoc){
                    dados.dsctipdoc = dados.codetrdoc
                    delete dados.codetrdoc
                }
                metodo = 'inserirArquivo'
            }
        }

        if(metodo) {
            return api[metodo](numpro, dados, ocorrencia.numand, ocorrencia.numseqocoand)
                    .then((retorno) => {
                        commit(Mutations.ANDAMENTOS.OCORRENCIA, { numpro, ocorrencia, dados: retorno.dados || dados })
                        if(!ocorrencia.numseqocoand){
                            commit(Mutations.ANDAMENTOS.REMOVER_NOVA_OCORRENCIA, ocorrencia)
                            commit(Mutations.PROCESSOS.ACAO_AVISO, retorno.mensagem)
                        }
                        if(retorno.dados?.arquivo){
                            commit(Mutations.ESTRUTURA.NOVO_TIPO_DOCUMENTO, { codetrvlrpdl: retorno.dados.arquivo.codetrdoc, dscetrvlrpdl: retorno.dados.arquivo.dscetrdoc})
                        }
                        return retorno
                    })
        }
    }
}

// mutations
const mutations = {
    [Mutations.ANDAMENTOS.ANEXO]: (_st, { ocorrencia, dados }) => {
        ocorrencia.temporario = Temporario.buildFromJson(dados)
        ocorrencia.assinatura = new Assinatura()
    },
    [Mutations.ANDAMENTOS.ASSINATURA]: (st, ocorrencia) => {
        st.assinatura = ocorrencia ? {
            mostrar: true,
            ocorrencia
        } : {}
    },
    [Mutations.ANDAMENTOS.CARREGANDO]: (st, carregando) => st.carregando = carregando,
    [Mutations.ANDAMENTOS.DOCUMENTOS]: (st, documentos) => st.documentos = documentos,
    [Mutations.ANDAMENTOS.LIMPAR_DOCUMENTOS]: (st) => st.documentos = st.andamentos.flatMap(a => a.ocorrencias.filter(o => o.tipo.codetroco === 'ocoAnexacao').map(o => o.arquivo)),
    [Mutations.ANDAMENTOS.LISTA]: (st, andamentos) => st.andamentos = andamentos,
    [Mutations.ANDAMENTOS.OCORRENCIA]: (st, { ocorrencia, dados }) => {
        if(ocorrencia.numseqocoand){
            ocorrencia.setDados(dados)
        } else {
            st.andamentos[st.andamentos.length - 1]
              .ocorrencias
              .push(dados)
        }        
    },
    [Mutations.ANDAMENTOS.DOCUMENTO_GERADO]: (_st, { ocorrencia, dados }) => {
        ocorrencia.tipo = new TipoOcorrencia('ocoAnexacao', 'Anexação')
        ocorrencia.temporario = Temporario.buildFromJson(dados)
        ocorrencia.assinatura = new Assinatura()

    },
    [Mutations.ANDAMENTOS.ERRO]: (st, erro) => st.erros.push(erro),
    [Mutations.ANDAMENTOS.ERROS]: (st, erros) => st.erros = erros,
    [Mutations.ANDAMENTOS.NOVA_OCORRENCIA]: (_st, { andamento, ocorrencia }) => {
        andamento.novasOcorrencias.push(ocorrencia)
    },
    [Mutations.ANDAMENTOS.REMOVER_OCORRENCIA]: (st, { numand, numseqocoand, indice}) => {
        let andamento = numand ? st.andamentos.find(a => a.numand === numand) : st.andamentos[st.andamentos.length - 1]
        if(numseqocoand){
            andamento.ocorrencias = andamento.ocorrencias.filter(o => o.numseqocoand !== numseqocoand)
        } else if(indice){
            andamento.ocorrencias.splice(indice-1, 1)
        }
    },
    [Mutations.ANDAMENTOS.REMOVER_NOVA_OCORRENCIA]: (st, novaOcorrencia) => {
        const ultimoAndamento = st.andamentos[st.andamentos.length - 1]
        ultimoAndamento.novasOcorrencias = ultimoAndamento.novasOcorrencias.filter(no => no !== novaOcorrencia)
    },
    [Mutations.ANDAMENTOS.STATUS_ASSINATURA]: (st, { codpesasi, numand, numseqocoand }) => {
        const andamento = st.andamentos.find(a => a.numand == numand)
        const ocorrencia = andamento.ocorrencias.find(o => o.numseqocoand == numseqocoand)

        ocorrencia.assinatura = new Assinatura('Aguardando assinatura de '+codpesasi, 'ENV')
    }
}

export default {
    namespaced: false,
    state: initial_state,
    getters: initial_getters,
    actions,
    mutations
}