<template>
  <uspCartao 
    :fechado="fechado"
    :icone="icone"
    :titulo="titulo" 
  >
    <template #corpo>
      <!-- Filtros -->
      <div class="filtros">
        <slot name="filtros" />
      </div>
      <!-- Conteúdo entre os filtros e o list-group -->
      <slot name="corpo" />

      <!-- Lista de itens -->
      <slot name="conteudo">
        <div 
          v-if="!acionavel"
          class="list-group"
        >
          <div
            v-for="item in paginaAtual"
            :key="item.key"
            class="list-group-item"
          >
            <slot :item="item">
              <!-- Fallback -->
              {{ item.descricao }}
            </slot>
          </div>
        </div>
        <div 
          v-else
          class="list-group"
        >
          <a
            v-for="item in paginaAtual"
            :key="item.key" 
            :href="criadorLinkAcionavel ? criadorLinkAcionavel(item) : '#'"
            :target="acionarTarget"
            class="list-group-item list-group-item-action"
            @click="acionar($event, item)"
          >
            <slot :item="item">
              <!-- Fallback -->
              {{ item.descricao }}
            </slot>
          </a>
        </div>
      </slot>
    </template>

    <!-- Rodapé -->
    <template #rodape>
      <div 
        v-show="!fechado"
        class="d-flex justify-content-between align-items-center"
      >
        <div class="float-left">
          <uspPaginacao
            v-show="lista.length > 0"
            :icones="true"
            tamanho="pq"
            :paginas="paginas.length"
            @paginar="paginar"
          />
        </div>
        <div class="float-right">
          <slot name="acoes" />
        </div>
      </div>
    </template>
  </uspCartao>
</template>

<script>

export default {
  name: 'PortalCardPaginado',

  props: {
    acionavel: {
      type: Boolean,
      default: false
    },
    acionarTarget: {
      type: String,
      default: '_blank'
    },
    criadorLinkAcionavel: {
      type: Function,
      default: null
    },
    fechado: {
      type: Boolean,
      default: false,
    },
    titulo: {
      type: String,
      required: true,
    },
    icone: {
      type: String,
      default: ''
    },
    fechavel: {
      type: Boolean,
      default: true,
    },
    lista: {
      type: Array,
      default: () => []
    },
    mensagem: {
      type: Object,
      default: () => {}
    },
    porPagina: {
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      pagina: 1
    };
  },


  computed: {
    paginas() {
      let resultado = [], atual = []

      resultado.push(atual);

      this.lista.forEach((item, i) => {
        atual.push(item);
        if ((i + 1) % this.porPagina === 0) {
          atual = []
          resultado.push(atual)
        }
      })
      
      if (resultado[resultado.length - 1].length === 0) {
        resultado.pop()
      }

      return resultado
    },
    paginaAtual() {
      return this.paginas[this.pagina - 1]
    },
  },

  watch: {
    lista(){
      this.pagina = 1
    }
  },

  methods: {
    acionar(event, item){
      if(!this.criadorLinkAcionavel){
        event.preventDefault()
        this.$emit('acionar', item)
      }
    },
    paginar(n) {
      this.pagina = n;
    },
  },

};
</script>