<template>
  <uspCartao
    titulo="Noticias para a Comunidade USP"
  >
    <template #cabecalho>
      <a 
        class="link-cabecalho"
        href="https://jornal.usp.br/editorias/institucional/comunicados/"
        target="_blank"
      >
        Noticias para a Comunidade USP
        <em class="fas fa-external-link-alt" />
      </a>
    </template>
    <template #corpo>
      <div 
        v-if="!carregando && lista.length === 0"
        class="d-flex justify-content-center align-items-center flex-column"
      >
        <img 
          src="imgs/usp-inbox.svg"
          alt="Ilustração de uma caixa vazia"
        >
        <p class="font-weight-bold text-center mt-5"> 
          &nbsp;
          Não há avisos no momento.
        </p>
      </div>
      <uspSkeleton v-if="carregando">
        <div>
          <div class="w-100" />
        </div>
        <div>
          <div class="w-100" />
        </div>
        <div>
          <div class="w-100" />
        </div>
      </uspSkeleton>
      <transition-group 
        v-if="!carregando && lista.length > 0"
        tag="div" 
        :name="transitionName" 
        class="slides-group"
      >
        <div 
          :key="'noticia-'+atual"
          class="noticia" 
        >
          <a
            :href="noticia.postURL"
            target="_blank"
          >
            <img 
              class="noticia-thumb img-fluid img-thumbnail"
              :src="imagem"
              :alt="titulo"
            >
          </a>
          <div class="noticia-conteudo">
            <h4>
              <a
                :href="noticia.postURL"
                target="_blank"
              >
                {{ titulo }}
              </a>
            </h4>
            <span class="data">
              {{ publicacao }}
            </span>
            <p>{{ descricao }}</p>
          </div>
        </div>
      </transition-group>
      <nav
        class="nav-paginacao" 
        aria-label="Navegação de notícias"
      >
        <uspPaginacao 
          :num-paginas="numPaginas"
          :paginas="paginas"
          @paginar="escolher"
        />
      </nav>
    </template>
  </uspCartao>
</template>

<script>
import NotificacoesServico from '@/portal/dominio/notificacoes/NotificacoesServico';

  export default {
    name: 'PortalNoticiasCartao',
    data(){
      return {
        api: NotificacoesServico.build({}),
        atual: 1,
        erro: '',
        carregando: true,
        lista: [],
        numPaginas: 8,
        transitionName: 'fade'
      }
    },

    computed: {
      autor(){
        return this.noticia.autor
      },
      descricao(){
        return this.elemento.innerText
      },
      elemento(){
        const element = document.createElement('div')
        element.innerHTML = this.noticia.descricao || ''
        return element
      },
      imagem(){
        return this.elemento.querySelector('img').getAttribute('src')
      },
      noticia(){
        return this.lista[this.atual - 1] || {};
      },
      titulo(){
        return this.noticia.titulo || ''
      },
      publicacao(){
        const data = new Date(this.noticia.dataPublicacao)
        const dia = data.getDate() >= 10 ? data.getDate() : '0'+data.getDate()
        const mes = data.getMonth()+1 >= 10 ? data.getMonth()+1 : '0'+(data.getMonth()+1)
        return `${dia}/${mes}/${data.getFullYear()}`
      },
      paginas(){
        return this.lista.length
      }
    },

    mounted(){
      const self = this;
      window.addEventListener('resize', this.contarNumPaginas);
      this.contarNumPaginas();
      self.carregando = true;
      self.api.listarNoticias()
        .then(lista => self.lista = lista)
        .catch(erro => self.erro = erro)
        .finally(() => self.carregando = false)
    },

    unmounted(){
      window.removeEventListener('resize', this.contarNumPaginas);
    },

    methods: {
      escolher(idx){
        this.atual = idx;
      },
      contarNumPaginas() {
        let width = window.innerWidth;
        if(width <= 540){
          this.numPaginas = 3;
        } else if(width > 540 && width <= 768) {
          this.numPaginas = 5;
        } else {
          this.numPaginas = 8;
        }
      }
    }
  }

</script>

<style lang="scss" scoped>
@import "./src/componentes/estilos/temas.scss";
.noticia {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;

    h4 {
      margin: 0;
    }
    
    .noticia-thumb {
      width: 350px;
      margin:  0.8rem;
    }

    .noticia-conteudo {
      flex: 1;
    }
}

.nav-paginacao {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

@each $tema in $temas {
    $elemento: map-get($tema, "elemento");
    #{$elemento} {
      .link-cabecalho {
        color: map-get($tema, 'usp-elemento-cor-do-texto')
      }
      .link-cabecalho:hover {
        color: map-get($tema, 'usp-cabecalho-cor-do-texto-ativo')
      }
    }
  }
</style>