import BuscaMenu from '@/portal/dominio/navegacao/BuscaMenu';
import ItemMenu from '@/portal/dominio/navegacao/ItemMenu';
import NavegacaoServico from '@/portal/dominio/navegacao/NavegacaoServico';
import { Actions, Getters, Mutations } from '../types';

const MENU_CARREGANDO = 'MENU_CARREGANDO',
      MENU_CARREGADO = 'MENU_CARREGADO';

const api = NavegacaoServico.build({})

const inserirMenuJanus = (menu, arvores, busca) => {
    if(arvores.length > 0 && busca.length > 0 && menu.length > 0){
        const pos = arvores.find(a => a.codigo === 'pos-graduacao');
        
        if(pos){
            const lista = ItemMenu.buildLista(menu, pos);
            pos.addMenuprivado(lista);
            lista.forEach(item => {
                item.nomeSistema = "Janus";
                inserirMenuBusca(busca, item)
            });
        }
    }
};

const inserirMenuBusca = (busca, item, nome='') => {
    if(item.ePai()){
        if(nome) nome += ' >> ';
        nome += item.titulo;
        item.filhos.forEach(filho => inserirMenuBusca(busca, filho, nome));
    } else {
        let b = new BuscaMenu(
            item.codigo,
            item.arvore.codigo,
            item.arvore.nome,
            (nome ? nome + ' >> ' : '')+" "+item.titulo,
            false,
            item.objetoweb,
            item.parametros,
            item.tipo,
            'Janus'
        );
        busca.push(b);
    }
}

// Estado inicial
const state = {
    arvores: [],
    objetoweb: {},
    busca: [],
    externo: [],
    menuJanus: [],
    status: MENU_CARREGANDO,
    statusJanus: MENU_CARREGANDO
};

// getters
const getters = {
  [Getters.NAVEGACAO.ARVORES]: (st) => st.arvores,
  [Getters.NAVEGACAO.BUSCA]: (st) => st.busca,
  [Getters.NAVEGACAO.EXTERNO]: (st) => st.externo,
  [Getters.NAVEGACAO.STATUS_CARREGADO]: (st) => st.status === MENU_CARREGADO,
  [Getters.NAVEGACAO.STATUS_CARREGADO_JANUS]: (st) => (arvore) => arvore.codigo !== 'pos-graduacao' || st.statusJanus === MENU_CARREGADO,
  [Getters.NAVEGACAO.OBJETOWEB]: (st) => st.objetoweb
};

// actions
const actions = {
    [Actions.NAVEGACAO.CARREGAR_PRIVADA]: ({ commit }) => {
        commit(Mutations.NAVEGACAO.STATUS_CARREGANDO);
        const prmsPedroweb = api.obterNavegacaoPrivadaPedroweb()
            .then(nav => {
            commit(Mutations.NAVEGACAO.NAVEGACAO, nav);
            commit(Mutations.NAVEGACAO.STATUS_MENU, MENU_CARREGADO);
        });
        const prmsJanus = api.obterNavegacaoPrivadaJanus()
            .then((menu) => {
                commit(Mutations.NAVEGACAO.MENU_JANUS, menu);
                commit(Mutations.NAVEGACAO.STATUS_MENU_JANUS, MENU_CARREGADO);
            });

        return Promise.all([prmsPedroweb, prmsJanus]);
    },
    [Actions.NAVEGACAO.CARREGAR_PUBLICA]: ({ commit }) => {
        commit(Mutations.NAVEGACAO.STATUS_CARREGANDO);
        return api.obterNavegacaoPublica()
            .then(nav => {
            commit(Mutations.NAVEGACAO.NAVEGACAO, nav);
            commit(Mutations.NAVEGACAO.STATUS_MENU, MENU_CARREGADO);
            commit(Mutations.NAVEGACAO.STATUS_MENU_JANUS, MENU_CARREGADO);
        });
    },
    [Actions.NAVEGACAO.CRIAR_SESSAO_JANUS]: () => {
        return api.obterSessaoJanus();
    },
    [Actions.NAVEGACAO.CARREGAR_OBJETOWEB_AREA]: ({ commit }, { objeto, area, auth }) => {
        return api.obterNavegacaoObjetoArea(objeto, area, auth)
                 .then(objetoweb => {
                    commit(Mutations.NAVEGACAO.OBJETOWEB, objetoweb);
                    return objetoweb;
                });
    },
    [Actions.NAVEGACAO.CARREGAR_OBJETOWEB_LEGADO]: ({ commit }, { objeto, contexto, auth }) => {
        return api.obterNavegacaoObjetoLegado(objeto, contexto, auth)
                 .then(objetoweb => {
                    commit(Mutations.NAVEGACAO.OBJETOWEB, objetoweb);
                    return objetoweb;
                });
    }
};

// mutations
const mutations = {   
    [Mutations.NAVEGACAO.MENU_JANUS]: (st, menu) => {
        st.menuJanus = menu;
        if(st.status === MENU_CARREGADO){
            inserirMenuJanus(menu, st.arvores, st.busca);
        }
    },
    [Mutations.NAVEGACAO.OBJETOWEB]: (st, objetoweb) => st.objetoweb = objetoweb,
    [Mutations.NAVEGACAO.NAVEGACAO]: (st, nav) => {
        st.externo = nav.externo;
        st.arvores = nav.arvores;
        st.busca = nav.busca;
        inserirMenuJanus(st.menuJanus, st.arvores, st.busca);
    },
    [Mutations.NAVEGACAO.STATUS_CARREGANDO]: (st) => {
        st.status = MENU_CARREGANDO;
        st.statusJanus = MENU_CARREGANDO;
        st.arvores = [];
        st.busca = [];
        st.externo = [];
    },
    [Mutations.NAVEGACAO.STATUS_MENU]: (st, status) => st.status = status,
    [Mutations.NAVEGACAO.STATUS_MENU_JANUS]: (st, status) => st.statusJanus = status,
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
};