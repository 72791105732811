<template>
  <div class="usp-skeleton">
    <slot>
      <div
        v-for="n in quantidade"
        :key="n"
        class="usp-skeleton-item"
      >
        <div />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "UspSkeleton",

  props: {
    quantidade: {
      type: Number,
      required: false,
      default: 5,
    },
  },
};
</script>

<style lang="scss">
@import "../../estilos/temas";


.usp-skeleton {
  & > div {
    gap: 15px;
    display: flex;
    div {
      height: 1.3rem;
      width: 100%;
      margin-top: 1.5rem;
      border-radius: 1.25rem;
      
      &:nth-child(2n) {
        opacity: 0.7;
        animation-delay: 0.1s;
        width: 100%;
      }
      &:nth-child(3n) {
        opacity: 0.5;
        animation-delay: 0.3s;
      }
      &:nth-child(4n) {
        opacity: 0.4;
        animation-delay: 0.5s;
      }
      &:nth-child(5n) {
        opacity: 0.3;
        animation-delay: 0.6s;
      }
      &:nth-child(6n) {
        opacity: 0.2;
        animation-delay: 0.7s;
      }
      &:nth-child(7n) {
        opacity: 0.1;
        animation-delay: 0.9s;
      }
    }
  }
}

@keyframes loading {
  0% {
  }
  100% {
    background-position: -200%;
  }
}


@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .usp-skeleton div>div{
      background-color: map-get($tema, "usp-skeleton-cor-de-fundo");  
      background: linear-gradient(90deg, map-get($tema, "usp-skeleton-cor-de-fundo"), map-get($tema, "usp-skeleton-realce"), map-get($tema, "usp-skeleton-cor-de-fundo"));
      background-size: 200%;
      animation: loading 1.5s infinite;
  }
}
}

</style>
