import { PortalLogger as log } from '@/utils';
import { StorageKeys } from '../../constantes';

import router from '@/router';
import UsuarioServico from '../../dominio/usuario/UsuarioServico';

const api = UsuarioServico.build({});

// Estado inicial
const state = {
    emails: {},
    token: localStorage.getItem(StorageKeys.AUTH.USUARIO_TOKEN) || '',
    usuarioLogado: localStorage.getItem(StorageKeys.AUTH.USUARIO_DISPLAY) || '',
    necessitaAtualizarEmailCelular: false
};

// getters
const getters = {
    autenticado: st => !!st.usuarioLogado,
    usuarioLogado: st => st.usuarioLogado,
    emails: st => st.emails,
    login: st => st.usuarioLogado ? st.usuarioLogado.replace(" ", "").split("-")[0] : null,
    necessitaAtualizarEmailCelular: st => st.necessitaAtualizarEmailCelular,
};

// actions
const actions = {
    autenticar: (context, dados) => {
        return api.autenticar(dados.usuario, dados.token)
            .then(usuario => {
                context.commit('usuarioLogado', usuario);
            });
    },
    deslogar: (context) => {
        return api.deslogar()
           .finally(() => {
                log.info('Sessão encerrada, descarregando objetos autenticados');
                context.commit('usuarioLogado');
                context.commit('necessitaAtualizarEmailCelular');
                if(router.currentRoute.meta.logado){
                    router.push({ name: 'home' });
                }
           });
    },
    carregarSessao: (context) => {
        return new Promise( (resolve, reject) => {
            if(context.state.usuarioLogado){
                log.info('Encontrada sessão anterior, validando sua existência');
                return api.validar()
                        .then( dados => {
                            log.info('Sessão valida, carregando objetos autenticados');
                            context.commit('usuarioLogado', dados);
                            resolve(true);
                        }).catch( (erro) => {
                            log.info('Sessão invalida, deslogando propriamente');
                            context.dispatch('deslogar');
                            reject(erro);
                        });
            }
            log.info("Não há sessão a ser validada");
            resolve(false);
        });
    },
    cadastrarSenha: (context, dados) => {
        return api.cadastrarSenha(dados)
    },
    primeiroAcesso: (context, dados) => {
        return api.primeiroAcesso(dados.usuario, dados.token)
    },
    esqueciSenha: (context, dados) => {
        return api.esqueciSenha(dados.usuario, dados.token)
    },
    alterarSenha: (context, dados) => {
        return api.alterarSenha(dados)
    },
    obterEmails: ({ commit }) => {
        return api.obterEmails()
                .then(dados => {
                    commit('emails', dados);
                    return dados;
                });
    },
    necessitaAtualizarEmailCelular: ({ commit }) => {
        return api.necessitaAtualizarEmailCelular()
                .then(dados => {
                    commit("necessitaAtualizarEmailCelular", dados.necessitaAtualizar === "S");
                    return dados;
                });
    },
    obterCelular: () => {
        return api.obterCelular()
                .then(dados => {
                    return dados;
                });
    },
    podeAtualizarEmailPrincipal: () => {
        return api.podeAtualizarEmailPrincipal();
    },
    salvarCelular: (context, dados) => {
        return api.salvarCelular(dados)
    },
    alterarEmail: (context, dados) => {
        return api.alterarEmail(dados)
    },
    confirmarAlteracaoEmail: (context, dados) => {
        return api.confirmarAlteracaoEmail(dados)
    },
};

// mutations
const mutations = {
    usuarioLogado:(st, usuario) => {
        if(usuario){
            st.usuarioLogado = usuario.nomeUsuario;
            localStorage.setItem(StorageKeys.AUTH.USUARIO_DISPLAY, usuario.nomeUsuario);
            if(!usuario.cookieSession){
                st.token = usuario.idSessaoUsuario;
                localStorage.setItem(StorageKeys.AUTH.USUARIO_TOKEN, usuario.idSessaoUsuario);
            }
        } else {
            st.usuarioLogado = '';
            st.token = ''
            localStorage.removeItem(StorageKeys.AUTH.USUARIO_TOKEN);
            localStorage.removeItem(StorageKeys.AUTH.USUARIO_DISPLAY);
        }
    },
    emails: (st, emails) => st.emails = emails,
    necessitaAtualizarEmailCelular: (st, necessitaAtualizarEmailCelular) => st.necessitaAtualizarEmailCelular = necessitaAtualizarEmailCelular,
    token: (st, token) => {
        st.token = token;
        localStorage.setItem(StorageKeys.AUTH.USUARIO_TOKEN, token);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};