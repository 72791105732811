import eventBus from '@/eventBus';

const AssinaAssinarDocumento = () => import(/* webpackChunkName: "chunk-usp-assina" */ '../views/AssinaAssinarDocumento')
const AssinaCriarPacote = () => import(/* webpackChunkName: "chunk-usp-assina" */ '../views/AssinaCriarPacote')
const AssinaInicio = () => import(/* webpackChunkName: "chunk-usp-assina" */ '../views/AssinaInicio')
const AssinaRetornoEmbedded = () => import(/* webpackChunkName: "chunk-usp-assina" */ '../views/AssinaRetornoEmbedded')
const AssinaCadastrarGestor = () => import(/* webpackChunkName: "chunk-usp-assina" */ '../views/AssinaCadastrarGestor')

import LoteDocumentoServico from '../dominio/loteDocumento/LoteDocumentoServico.js';
const api = LoteDocumentoServico.build({});

const consultaUsuarioUspAssina = function(to, _f, next) {
  if (to.params.numnivseg) {
    next();
  } else {
    api.buscarUsuario()
      .then(resp => {
        const numnivseg = "" + ((resp || [])[0] || {}).numnivseg;
        if (numnivseg) {
          to.params.numnivseg = numnivseg;
          next();
        } else {
          next("/");
          eventBus.mostrarMensagemToast({
            titulo: "Usuário não cadastrado!",
            mensagem: "Não foi encontrado cadastro do usuário em USPAssina!",
            variante: "danger"
          });
        }
      })
      .catch((erro) => {
        next("/");
        eventBus.mostrarMensagemToast({
          titulo: "Erro ao buscar usuário em USPAssina!",
          mensagem: (erro || "").mensagem || erro,
          variante: "danger"
        });
      });
  }
  /*
  if (!localStorage.getItem("numnivsegUSPAssina")) {
    api.buscarUsuario()
      .then(resp => {
        const numnivseg = "" + ((resp || [])[0] || {}).numnivseg;
        if (numnivseg) {
          localStorage.setItem("numnivsegUSPAssina", numnivseg);
          next();
        } else {
          localStorage.removeItem("numnivsegUSPAssina");
          next("/");
          eventBus.mostrarMensagemToast({
            titulo: "Usuário não cadastrado!",
            mensagem: "Não foi encontrado cadastro do usuário em USPAssina!",
            variante: "danger"
          });
        }
      })
      .catch((erro) => {
        localStorage.removeItem("numnivsegUSPAssina");
        next("/");
        eventBus.mostrarMensagemToast({
          titulo: "Erro ao buscar usuário em USPAssina!",
          mensagem: (erro || "").mensagem || erro,
          variante: "danger"
        });
      });
  } else {
    next();
  }
  */
}

export const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: AssinaInicio,
    beforeEnter(to, from, next) {
      consultaUsuarioUspAssina(to, from, next);
    },
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'USP Assina' },
      ]
    }
  },
  {
    path: '/lote',
    name: 'Novo',
    component: AssinaCriarPacote,
    props: true,
    beforeEnter(to, from, next) {
      sessionStorage.removeItem('loteAtual');
      consultaUsuarioUspAssina(to, from, next);
    },
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'USP Assina', link: '/assina/' },
        { nome: 'Novo Lote' }
      ]
    }
  },
  {
    path: '/lote/:codlotdocasi',
    name: 'Lote',
    component: AssinaCriarPacote,
    props: true,
    beforeEnter(to, from, next) {
      api.obter(Object.assign({}, to.query, to.params))
        .then(resp => {
            sessionStorage.setItem('loteAtual', JSON.stringify(resp));
            to.params.numnivseg = resp.numnivseg;
            //store.commit('set_loteAtual', resp);
            !resp.codlotdocasi ? next({name: 'Assina:Novo'}) : next()
        })
        .catch(() => next({name: 'Assina:Novo'}));
    },
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'USP Assina', link: '/assina/' },
        { nome: 'Lote #${codlotdocasi}' }
      ]
    }
  },
  {
    path: '/assinar/:hash',
    name: 'Assinar',
    component: AssinaAssinarDocumento,
    props(route) {
      return {dadosHash: route.params};
    },
    beforeEnter(to, from, next) {
      api.obterDadosHash(to.params)
      .then(resp => {
        Object.assign(to.params, resp);
        (resp || {}).coddocuspasi && (resp || {}).codpcpasidoc ? next() : ((resp || {}).codlotdocasi ? next({name: 'Assina:Lote', params: {codlotdocasi: resp.codlotdocasi}}) : next({name: 'Assina:Inicio'}));
      })
      .catch(() => next({name: 'Assina:Inicio'}));
    },
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'USP Assina', link: '/assina/' },
        { nome: 'Assinatura de Documento' }
      ]
    }
  },
  {
    path: '/retorno/:codlotdocasi',
    name: 'RetornoEmbedded',
    component: AssinaRetornoEmbedded,
    props: route => ({
      codlotdocasi: route.params.codlotdocasi,
      event: (route.query || {}).event || ''
    }),
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'USP Assina', link: '/assina/' },
        { nome: 'Assinatura #${codlotdocasi}' }
      ]
    }
  },
  {
    path: '/cadastrarGestor',
    name: 'CadastrarGestor',
    component: AssinaCadastrarGestor,
    beforeEnter(to, from, next) {
      api.buscarUsuario()
        .then(resp => {
          ((resp || [])[0] || {}).numnivseg == '1' ? next() : next({name: 'Assina:Inicio'});
        })
        .catch(() => next({name: 'Assina:Inicio'}));
    },
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'USP Assina', link: '/assina/' },
        { nome: 'Cadastrar Gestor' }
      ]
    }
  }
]