<template>
  <uspModal :titulo="titulo" ref="UspModal">
    <template slot="body">
      <form>
        <div class="form-group row">
          <div class="col-12">
            <uspAlerta
              :variante=alert.variant
              v-show="!!alert.message"
            >
              <span v-html="alert.message"></span>
            </uspAlerta>
          </div>
          <div class="col-12">
            <label for="usuario" class="sr-only">Usuário / Número USP</label>
            <input
              v-model="usuario"
              placeholder="Número USP"
              type="text"
              required="required"
              class="form-control"
              :disabled=carregando
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <label for="email" class="sr-only">E-mail</label>
            <input
              v-model="email"
              placeholder="seuemail@exemplo.com"
              type="email"
              class="form-control"
              required="required"
              :disabled=carregando              
            />
          </div>
        </div>
      </form>
    </template>
    <template slot="footer">
      <button class="btn btn-outline-danger" :disabled=carregando v-on:click="()=>this.$refs.UspModal.closeModal()">{{ msgBotaoFechar }}</button>
      <button class="btn btn-success" :disabled=enviadoComSucesso v-on:click=solicitarSenha()>
        <div class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="carregando"><span class="sr-only">Loading...</span></div>        
        Solicitar senha
      </button>
    </template>
  </uspModal>
</template>

<script>
import uspModal from '@/componentes/components/uspModal';

const tipos = {
      primeiroAcesso: 'Primeiro acesso',
      esqueciSenha: 'Esqueci minha senha'
    }

function getEstadoInicial(){
  return {
    titulo: "",
    tipo: "",
    usuario: "",
    email: "",
    carregando: false,
    enviadoComSucesso: false,
    msgBotaoFechar: 'Cancelar',
    alert: {
      variant: 'perigo',
      message: ""
    }
  };
}

export default {
  name: 'PortalHomeSolicitarSenha',
  
  components: {
    uspModal,
  },

  data() {
    return getEstadoInicial();
  },

  methods:{
    abrirModal(tipo){
      const self = this
      Object.assign(self.$data, getEstadoInicial());
      self.titulo = tipos[tipo]
      self.tipo = tipo,
      self.$refs.UspModal.openModal()
    },
    solicitarSenha(){
        const self = this
        self.carregando = true
        self.$recaptcha('login')
            .then(token => {
              self.$store.dispatch(`auth/${self.tipo}`,{
                  usuario: {
                    codpes: self.usuario,
                    senusu: self.email
                  },
                  token
              }).then(( response ) => {
                self.alert.message = response.mensagem
                self.alert.variant = 'sucesso'
                self.enviadoComSucesso = true
                self.msgBotaoFechar = 'Fechar'
              }).catch((erro) => {
                self.alert.message = erro.mensagem
                self.alert.variant = 'perigo'
              }).finally(() => self.carregando = false)
        })
    },
  }
};
</script>