import { Actions, Getters, Mutations } from '@/processos/store/types.js';
import ProcessoServico from '@/processos/dominio/ProcessosServico.js'

const api = ProcessoServico.build({})

// Estado inicial
const state = {
    acao_aviso: '',
    carregando: true,
    confirmar: {},
    confirmarErro: '',
    confirmarSalvando: false,
    erro: { dados: '' },
    obtendo: { acoes: true, dados: true },
    permissoes: {},
    processo: {},
    processos: [],
}

// getters
const getters = {
    [Getters.PROCESSOS.ACAO]: (st) => (name) => st.permissoes[name],
    [Getters.PROCESSOS.ACAO_AVISO]: (st) => st.acao_aviso,
    [Getters.PROCESSOS.ACOES]: (st) => st.permissoes,
    [Getters.PROCESSOS.ASSUNTO]: (st) => st.processo.assunto,
    [Getters.PROCESSOS.CARREGANDO]: (st) => st.carregando,
    [Getters.PROCESSOS.CONFIRMAR]: (st) => st.confirmar,
    [Getters.PROCESSOS.CONFIRMAR_ERRO]: (st) => st.confirmarErro,
    [Getters.PROCESSOS.CONFIRMAR_SALVANDO]: (st) => st.confirmarSalvando,
    [Getters.PROCESSOS.DEVOLVER]: (st, _g, rSt) => st.permissoes.saida === 'S' && st.permissoes.andamento === 'S' && rSt.andamento.andamentos.length > 1,
    [Getters.PROCESSOS.DADOS]: (st) => st.processo,
    [Getters.PROCESSOS.ERRO_DADOS]: (st) => st.erro.dados,
    [Getters.PROCESSOS.LISTA]: (st) => st.processos,
    [Getters.PROCESSOS.OBTENDO_ACOES]: (st) => st.obtendo.acoes,
    [Getters.PROCESSOS.OBTENDO_DADOS]: (st) => st.obtendo.dados
}

// actions
const actions = {
    [Actions.PROCESSOS.ARQUIVAR]: ({ commit, dispatch }, { numpro, dados }) => {
        return api.arquivar(numpro, dados)
                  .then((retorno) => {
                        dispatch(Actions.PROCESSOS.CARREGAR, { numpro })
                        commit(Mutations.PROCESSOS.ACAO_AVISO, retorno.mensagem)
                  })
    },
    [Actions.PROCESSOS.CANCELAR_SAIDA]: ({ commit, dispatch }, { numpro, dados }) => {
        return api.darSaida(numpro, { sgletadst: '', txtdch: dados.txtdch })
                  .then((retorno) => {
                        dispatch(Actions.PROCESSOS.CARREGAR, { numpro })
                        commit(Mutations.PROCESSOS.ACAO_AVISO, retorno.mensagem)
                  })
    },
    [Actions.PROCESSOS.CARREGAR]: ({ dispatch }, dados) => {
        dispatch(Actions.PROCESSOS.CARREGAR_ACOES, dados)
        dispatch(Actions.ANDAMENTOS.LISTAR, dados)
        dispatch(Actions.PROCESSOS.CARREGAR_DADOS, dados)
    },
    [Actions.PROCESSOS.CARREGAR_ACOES]: ({ commit }, { numpro }) => {
        commit(Mutations.PROCESSOS.OBTENDO, { acoes: true })
        commit(Mutations.PROCESSOS.ACOES, {})
        return api.listarPermissoes(numpro)
                    .then(permissoes => commit(Mutations.PROCESSOS.ACOES, permissoes))
                    .catch(error => commit(Mutations.PROCESSOS.ERRO, { acoes: error.erro }))
                    .finally(() => commit(Mutations.PROCESSOS.OBTENDO, { acoes: false }))
    },
    [Actions.PROCESSOS.CARREGAR_DADOS]: ({ commit, dispatch }, { numpro }) => {
        commit(Mutations.PROCESSOS.OBTENDO, { dados: true })
        commit(Mutations.PROCESSOS.ITEM, {}) 
        return api.obter(numpro)
                    .then(processo => { 
                        dispatch(Actions.ESTRUTURA.TIPO_DOCUMENTO, { codasu: processo.assunto.codasu })
                        commit(Mutations.PROCESSOS.ITEM, processo) 
                    })
                    .catch(error => commit(Mutations.PROCESSOS.ERRO, { dados: error.erro }))
                    .finally(() => commit(Mutations.PROCESSOS.OBTENDO, { dados: false }))
    },
    [Actions.PROCESSOS.DAR_SAIDA]: ({ commit, dispatch }, { numpro, dados }) => {
        return api.darSaida(numpro, dados)
                  .then((retorno) => {
                        dispatch(Actions.PROCESSOS.CARREGAR, { numpro })
                        commit(Mutations.PROCESSOS.ACAO_AVISO, retorno.mensagem)
                  })
    },
    [Actions.PROCESSOS.DESARQUIVAR]: ({ commit, dispatch }, { numpro, dados }) => {
        return api.desarquivar(numpro, dados)
                  .then((retorno) => {
                        dispatch(Actions.PROCESSOS.CARREGAR, { numpro })
                        commit(Mutations.PROCESSOS.ACAO_AVISO, retorno.mensagem)
                  })
    },
    [Actions.PROCESSOS.DEVOLVER]: ({ commit, dispatch }, { numpro, dados }) => {
        return api.devolver(numpro, { sgletadst: dados.sgleta })
                  .then((retorno) => {
                        dispatch(Actions.PROCESSOS.CARREGAR, { numpro })
                        commit(Mutations.PROCESSOS.ACAO_AVISO, retorno.mensagem)
                  })
    },
    [Actions.PROCESSOS.ENTRAR]: ({ commit, dispatch }, { numpro, dados }) => {
        return api.entrar(numpro, dados)
                  .then((retorno) => {
                        dispatch(Actions.PROCESSOS.CARREGAR, { numpro })
                        commit(Mutations.PROCESSOS.ACAO_AVISO, retorno.mensagem)
                  })
    },
    [Actions.PROCESSOS.LISTAR]: ({ commit }, dados) => {
        commit(Mutations.PROCESSOS.CARREGANDO, true)
        commit(Mutations.PROCESSOS.LISTA, [])
        return api.buscar(dados)
                 .then((retorno) => commit(Mutations.PROCESSOS.LISTA, retorno))
                 .finally(() => commit(Mutations.PROCESSOS.CARREGANDO, false))
    },
    [Actions.PROCESSOS.SALVAR]: ({ commit }, processo) => {
        return api[processo.numpro ? 'alterar' : 'inserir'](processo)
                .then((retorno) => {
                    if(retorno.numpro) commit(Mutations.PROCESSOS.ITEM, retorno)
                    return retorno
                })
    },
}

// mutations
const mutations = {
    [Mutations.PROCESSOS.ACAO_AVISO]: (st, aviso) => st.acao_aviso = aviso,
    [Mutations.PROCESSOS.ACOES]: (st, permissoes) => st.permissoes = permissoes,
    [Mutations.PROCESSOS.CARREGANDO]: (st, carregando) => st.carregando = carregando,
    [Mutations.PROCESSOS.CONFIRMAR]: (st, confirmar) => st.confirmar = confirmar,
    [Mutations.PROCESSOS.CONFIRMAR_ERRO]: (st, confirmarErro) => st.confirmarErro = confirmarErro,
    [Mutations.PROCESSOS.CONFIRMAR_SALVANDO]: (st, confirmarSalvando) => st.confirmarSalvando = confirmarSalvando,
    [Mutations.PROCESSOS.ERRO]: (st, erro) => st.erro = erro,
    [Mutations.PROCESSOS.ITEM]: (st, processo) => {
        let idx = st.processos.findIndex(p => p.numpro = processo.numpro)
        if(idx !== -1){
            st.processos[idx] = processo
        } else {
            st.processos.unshift(processo)
        }
        st.processo = processo
    },
    [Mutations.PROCESSOS.LISTA]: (st, processos) => st.processos = processos,
    [Mutations.PROCESSOS.OBTENDO]: (st, dados) => Object.keys(dados).forEach(k => st.obtendo[k] = dados[k]),
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}