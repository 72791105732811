import AvaliacaoProServ from "./AvaliacaoProServ"

export default class AvaliadorProServ {

  constructor(codpesavd, nompesavd, dtaultalt, nota, desconsiderado, avaliacoes){
    this.codpesavd = codpesavd
    this.nompesavd = nompesavd
    this.nota = nota
    this.dtaultalt = dtaultalt
    this.desconsiderado = desconsiderado
    this.avaliacoes = avaliacoes || []
  }

  toString(){
    return `${this.codpesavd} - ${this.nompesavd}`
  }

  static fromJSON(json={}){
    const avaliacoes = []
    json.avaliacoes.map(item => AvaliacaoProServ.fromJSON(item))
      .forEach(avaliacao => {
        let ava = avaliacoes.find(a => a.tipcrtprs.nomtipcrtprs === avaliacao.tipcrtprs.nomtipcrtprs)
        if(!ava){
          avaliacoes.push(avaliacao);
          ava = avaliacao
        }
        ava.filhos.push(avaliacao);
      });

    return new AvaliadorProServ(
      json.codpesavd,
      json.nompesavd,
      json.dtaultalt,
      json.nota,
      json.desconsiderado,
      avaliacoes
    )
  }

}