<template>
  <uspDropdown 
    :botao="false" 
    alinhamento="direita" 
    class="mr-3" 
    aria-label="Opções de acessibilidade"
  >
    <template #conteudo>
      <em class="fas fa-universal-access fa-lg" />
    </template>
    <template #itens>
      <uspDropdownItemTitulo>Tamanho da fonte</uspDropdownItemTitulo>
      <uspDropdownItem 
        @click="alterarTamanhoFonte('.8rem')"
      >
        <em class="fas fa-minus fa-sm" /> 
        Diminuir fonte
      </uspDropdownItem>
      <uspDropdownItem 
        @click="alterarTamanhoFonte('1rem')"
      >
        <em class="fas fa-font fa-sm" /> 
        Tamanho padrão
      </uspDropdownItem>
      <uspDropdownItem 
        @click="alterarTamanhoFonte('1.1rem')"
      >
        <em class="fas fa-plus fa-sm" /> 
        Aumentar fonte
      </uspDropdownItem>
      <uspDropdownSeparador />
      <uspDropdownItemTitulo>Tema</uspDropdownItemTitulo>
      <uspDropdownItem 
        @click="alternarTema('padrao')"
      >
        <em class="fas fa-palette fa-sm" /> 
        Padrão
      </uspDropdownItem>
      <uspDropdownItem 
        @click="alternarTema('claro')"
      >
        <em class="fas fa-sun fa-sm" /> 
        Claro
      </uspDropdownItem>
      <uspDropdownItem 
        @click="alternarTema('contraste')"
      >
        <em class="fas fa-adjust fa-sm" /> 
        Escuro
      </uspDropdownItem>
    </template>
  </uspDropdown>
</template>

<script>
import uspDropdownItem from "@/componentes/components/uspDropdown/uspDropdownItem.vue";
import uspDropdownItemTitulo from "@/componentes/components/uspDropdown/uspDropdownItemTitulo.vue";
import uspDropdownSeparador from "@/componentes/components/uspDropdown/uspDropdownSeparador.vue";
import Acessibilidade from "./Acessibilidade.js";

export default {
   name: "UspAcessibilidade",

  components: {
    uspDropdownItem,
    uspDropdownItemTitulo,
    uspDropdownSeparador,
  },

  mounted(){
    Acessibilidade.aplicarAjustes();
  },

  methods: {
    alternarSidebar() {
      Acessibilidade.alternarSidebar();
    },
    alterarTamanhoFonte(tamanho) {
      Acessibilidade.alterarTamanhoFonte(tamanho);
    },
    alternarTema(tema) {
      Acessibilidade.alternarTema(tema);
    },
  }
}
</script>
