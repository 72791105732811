import Estacao from "./Estacao"

export default class Saida {

    constructor(dtasai, estacao) {
        this.dtasai = dtasai
        this.estacao = estacao
    }

    static buildFromJson(json={}){
        return new Saida(
            json.dtasai,
            new Estacao(json.sgletadst)
        )
    }
}