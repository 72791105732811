<template>
  <div 
    :class="estilo" 
    role="alert"
  >
    <slot />
    <button
      v-if="fechavel"
      type="button"
      class="close"
      aria-label="Close"
      @click="fechar"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import { ALERTA_VARIANTES } from "./constantes";

export default {
  name: 'UspAlerta',

  props: {
    variante: {
      type: String,
      default: "primario",
      validator: (value) => {
        return ALERTA_VARIANTES.map(function(e) { return e.pt; }).indexOf(value) !== -1
      },
    },
    fechavel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classeBS(){
      return ALERTA_VARIANTES.find(elemento => elemento.pt == this.variante).en
    },
    estilo() {
      const classes = ['alert']
      classes.push(`alert-${this.classeBS}`)
      if(this.classes){
        classes.push(this.classes)
      }
      return classes
    },
  },

  methods: {
    fechar: function() {
      this.$emit('fechar')
    },
  },
};
</script>

<style lang="scss" scoped>
$usp-border-radius: 0.8rem;
$alert-color-level: -10;
$alert-bg-level: 1;

.alert {
  font-size: 0.9rem;
  border-radius: $usp-border-radius!important;
  border: none;
  i {
    margin-right: 1rem;
  }
  a:hover {
    text-decoration: none;
  }

  .close{
    color: #fff;
    opacity: .8;
    position: absolute;
    right: 1.25rem;
    top: 0.75rem;
    &:hover{
      opacity: .5;
    }
  }
}
</style>
