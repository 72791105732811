import Vue from 'vue';
import Vuex from 'vuex';

import PortalModules from '@/portal/store'
import ProcessosModules from '@/processos/store'
import ProServModules from '@/proserv/store'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules:{
        ...PortalModules,
        ...ProcessosModules,
        ...ProServModules
    },
    strict: debug
})