<template>
  <div :class="divClass">
    <label :for="inputId">
      {{ label }}
    </label>
    <input 
      :id="inputId"
      v-model="codpes"
      type="number"
      class="form-control"
      step="1"
      min="1"
      :disabled="carregando"
      :required="obrigatorio"
      @change="obter"
    >
    <span :class="classeSpan">
      {{ textoNome }}
    </span>
  </div>
</template>

<script>
  import { StorageKeys } from '@/portal/constantes';
  export default {
    name: 'UspNusp',
    props: {
      /**
       * Classe a ser colocada na DIV
       */
      divClass:{
         type: String,
         default: 'form-group'
      },
      /**
       * Vetor de erros de validação
       */
      erroValidacao: {
        type: String,
        default: ''
      },
      /**
       * Texto a ser colocado no label.
       */
      label: {
        type: String,
        default: 'NUSP'
      },
      /**
       * Id utilizado pelo INPUT numérico
       */
      inputId: {
          type: String,
          default: 'codpes'
      },
      /**
       * marca se o campo vai ser tratado como obrigatório
       */
      obrigatorio: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        carregando: false,
        codpes: '',
        nompes: ''
      }
    },
    computed: {
      classeSpan(){
        return 'nome'+(this.erroValidacao && !this.carregando && !this.nompes ? ' invalid-feedback' : '');
      },
      textoNome() {
        if(this.carregando) return 'Carregando dados...'
        else if(this.nompes) return this.nompes
        else if(this.erroValidacao) return this.erroValidacao;
        return 'Digite o NUSP';
      }
    },
    methods:{
      async obter(){
        const self = this;
        self.carregando = true
        if(self.codpes){
          let response
          try {
            response = await fetch(
              `/proxy/wsportal/owc/pessoas/${self.codpes}`,{
                headers: {
                  "Usp-Key": localStorage.getItem(StorageKeys.AUTH.USUARIO_TOKEN)
                },
              }
            );
          } catch (error) {
            console.error(error);
          }
          if(response?.ok){
            const pessoa = await response.json();
            self.codpes = pessoa.codpes
            self.nompes = pessoa.nompes
            self.$emit('input', pessoa.codpes)
          } else {
            const erro = await response.json();
            self.codpes = ''
            self.nompes = erro.mensagem
          }
        }
        self.carregando = false
      }
    }
  }
</script>

<style lang="scss" scoped>

  span.nome {
    display: flex;
    flex-flow: row nowrap;
    padding: 0.3rem;
    justify-content: center;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

</style>