import Unidade from "./Unidade"

export default class Estacao {

    constructor(sgleta, nometa, nomareatc, tipeta, unidade) {
        this.sgleta = sgleta
        this.nometa = nometa
        this.nomareatc = nomareatc
        this.tipeta = tipeta
        this.unidade = unidade
    }

    getCodigo(){
        return this.sgleta
    }

    getTexto(){
        let complemento = this.nomareatc ? ` (${this.nomareatc})` : ""
        return `${this.sgleta} - ${this.nometa}` + complemento
    }

    toString(){
        return this.getTexto()
    }

    static buildFromJson(json={}){
        return new Estacao(
            json.sgleta,
            json.nometa, 
            json.nomareatc,
            json.tipeta,
            Unidade.buildFromJson(json)
        )
    }
}