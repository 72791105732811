<template>
  <uspCartao titulo="Conheça o novo portal de serviços computacionais da USP">
    <template slot="corpo">
      <div class="usp-capa">
        <router-link :to="{name: 'saiba'}">
          <img src="//i3.ytimg.com/vi/T1TdFNj09A4/maxresdefault.jpg" class="img-fluid" />
          <span class="usp-tag">Novidade</span>
          <h4>Conheça o novo portal de serviços computacionais da USP</h4>
        </router-link>
      </div>
      <ul class="usp-links">
        <li class="header">SAIBA MAIS</li>
        <li>
          <router-link :to="{name: 'saiba', hash: '#onde-sistemas'}" >
            <i class="fas fa-question"></i>
            Onde estão os sistemas Júpiter, Janus, Mercúrio e Marte?
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'saiba', hash: '#o-que-sao'}" >
            <i class="fas fa-question"></i>
            O que são os serviços computacionais da USP?
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'saiba', hash: '#mobile'}" >
            <i class="fas fa-question"></i>
            O portal de serviços funciona no meu dispositivo móvel?
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'saiba', hash: '#quem-pode'}" >
            <i class="fas fa-question"></i>
            Quem pode ter acesso ao portal de serviços?
          </router-link>
        </li>
      </ul>
    </template>
  </uspCartao>
</template>
<script>
export default {
  name: 'PortalHomeSobre',  
};
</script>

<style>
</style>