export default class PessoaProServ {

  constructor(codpes, nompes) {
      this.codpes = codpes
      this.nompes = nompes
  }

  getCodigo(){
      return this.codpes
  }

  getTexto(){
      return `${this.codpes} - ${this.nompes}`
  }

  toString(){
      return this.getTexto()
  }

  static fromJSON(json={}){
    return new PessoaProServ(
      json.codpes,
      json.nompes
    )
  }
}