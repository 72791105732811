import Home from '../views/Home.vue'
import Legado from '../views/Legado.vue'
import SaibaMais from '../views/SaibaMais.vue'
import Servicos from '../views/Servicos.vue'
import Cadastrar from '../views/CadastrarSenha.vue'
import Configuracoes from '../views/Configuracoes.vue'

export const routes = [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        breadcrumb: []
      }
    },{
      path: '/saiba-mais/',
      name: 'saiba',
      component: SaibaMais,
      meta: {
        breadcrumb:[
          { nome: 'Saiba Mais' }
        ]
      }
    },{
      path: '/painel/',
      name: 'servicos',
      component: Servicos,
      meta: {
        logado: true,
        breadcrumb: []
      }
    },{
      path: '/servicos/*',
      name: 'legado',
      component: Legado,
      meta: {
        breadcrumb:[
          { nome: 'Serviços' }
        ]
      }
    },{
      path: '/cadastrar-senha/',
      name: 'cadastrar-senha',
      component: Cadastrar,
      meta: {
        breadcrumb:[
          { nome: 'Cadastrar Senha' }
        ]
      }
    },{
      path: '/configuracoes/',
      name: 'configuracoes',
      component: Configuracoes,
      meta: {
        logado: true,
        breadcrumb:[
          { nome: 'Configurações' }
        ]
      }
    }
];