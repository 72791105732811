// Tipo de Navegação
export const NAVEGACAO_PORTAL = 'PORTAL';
export const NAVEGACAO_PERFIL = 'PERFIL';
export const NAVEGACAO_LEGADO = 'LEGADO';
export const NAVEGACAO_JANUS = 'JANUS';

// Situações de carregamento
export const MENU_CARREGANDO = 'MENU_CARREGANDO';
export const MENU_CARREGADO = 'MENU_CARREGADO';

export const NOTIFICACOES_CARREGANDO = 'NOTIFICACOES_CARREGANDO';
export const NOTIFICACOES_CARREGADO = 'NOTIFICACOES_CARREGADO';

export const ALERTAS_CARREGANDO = 'ALERTAS_CARREGANDO';
export const ALERTAS_CARREGADO = 'ALERTAS_CARREGADO';

export const StorageKeys = {
    AUTH: {
        REDIRECT: 'AUTH_REDIRECT',
        USUARIO_DISPLAY: 'AUTH_USUARIO_DISPLAY',
        USUARIO_TOKEN: 'AUTH_USUARIO_TOKEN'
    }
};