export default class Temporario {

    constructor(codarqtmp, nomarqtmp, tamarqtmp, qtdpgntmp, mimetype, sha256) {
        this.codarqtmp = codarqtmp
        this.nomarqtmp = nomarqtmp
        this.tamarqtmp = tamarqtmp
        this.qtdpgntmp = qtdpgntmp
        this.sha256 = sha256
        this.mimetype = mimetype
    }

    static buildFromJson(json={}){
        return new Temporario (
            json.codarqtmp,
            json.nomarqtmp,
            json.tamarqtmp,
            json.qtdpgntmp,
            json.mimetype,
            json.sha256
        )
    }
}