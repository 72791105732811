export const TAMANHOS = {
  EXTRA_SMALL: 'TAMANHO_EXTRA_SMALL',
  SMALL: 'TAMANHO_SMALL',
  MEDIUM: 'TAMANHO_MEDIUM',
  LARGE: 'TAMANHO_LARGE',
  EXTRA_LARGE: 'TAMANHO_EXTRA_LARGE'
};

const MAPA_BREAKPOINTS = {
  [TAMANHOS.EXTRA_SMALL]: { inicio: 0, fim: 576 },
  [TAMANHOS.SMALL]: { inicio: 576, fim: 768 },
  [TAMANHOS.MEDIUM]: { inicio: 768, fim: 992 },
  [TAMANHOS.LARGE]: { inicio: 992, fim: 1200 },
  [TAMANHOS.EXTRA_LARGE]: { inicio: 1200, fim: Infinity }
}

export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0,
        tamanho: ''
      },
    }
  },
  mounted(){
    console.log('iniciando mixin');
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    console.log('desmontando mixin');
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      let width = window.innerWidth;
      let height = window.innerHeight;
      let tamanho = '';
      Object.entries(MAPA_BREAKPOINTS)
        .forEach(e => {
          if(width < e[1].fim && width >= e[1].inicio){
            tamanho = e[0];
          }
        });
      this.window = { width, height, tamanho };
      console.log('Resize feito', width, height, tamanho)
    },
  },
};