import { render, staticRenderFns } from "./PortalPainelBusca.vue?vue&type=template&id=63fda103&scoped=true&"
import script from "./PortalPainelBusca.vue?vue&type=script&lang=js&"
export * from "./PortalPainelBusca.vue?vue&type=script&lang=js&"
import style0 from "./PortalPainelBusca.vue?vue&type=style&index=0&id=63fda103&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63fda103",
  null
  
)

export default component.exports