const Constantes =  {
  "papeis": {
    "A": "Assinante",
    "G": "Gestor",
    "O": "Observador"
  },
  "situacoes": {
    "CRI": "Criado",
    "ENV": "Para assinar",
    "ASS": "Assinado",
    "REJ": "Rejeitado"
  }
}

export default {
  install(Vue) {
    Vue.prototype.$uassConst = key => Constantes[key]
  }
}