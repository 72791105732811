import { PortalServico } from '@/utils/';

/**
 * @type {LoteDocumentoServico}
 */
export default class LoteDocumentoServico extends PortalServico {

    listar(params) {
        return this.post('/uspproc/listar/assinatura/listarLoteDocumento', params);
    }

    salvar(params) {
        return this.post('/uspproc/executar/assinatura/salvarLoteDocumento', params);
    }

    excluir(params) {
      return this.post('/uspassinaws/excluirLote/' + params.codlotdocasi, params);
    }

    rejeitar(params) {
        return this.post('/uspassinaws/rejeitarLote/' + params.codlotdocasi, params);
    }

    obter(params) {
        return this.post('/uspproc/obter/assinatura/obterLoteDocumento', params);
    }

    enviar(params) {
        return this.post('/uspassinaws/enviar', params);
    }

    verificarAtualizacao(params) {
        return this.post('/uspproc/obter/assinatura/verifAtualAssinLoteDocumento', params);
    }

    registrarParticipante(params) {
        return this.post('/uspproc/executar/assinatura/salvarParticipanteLoteDocAss', params);
    }

    listarParticipante(params) {
        return this.post('/uspproc/listar/assinatura/listarParticipanteLoteDocAss', params);
    }

    removerParticipante(params) {
        return this.post('/uspproc/executar/assinatura/removerParticipanteDocAss', params);
    }

    reenviarEmail(params) {
      return this.post('/uspassinaws/reenviarEmail', params);
    }

    obterUrlAssinarEmbedded(params) {
        return this.post('/uspassinaws/obterUrlAssinarEmbedded', params);
    }

    obterUrlAcrobatSign(params) {
        return this.post('/uspassinaws/obterUrlAcrobatSign', params);
    }

    buscarUsuario(beanBusca) {
        return this.post('/uspproc/listar/assinatura/buscarPessoaLoteDocAss', beanBusca);
    }

    buscarUsuarioSemNUSP(beanBusca) {
        return this.post('/uspproc/listar/assinatura/buscarUsuarioSemNUSP', beanBusca);
    }

    registrarDocumento(file, params) {
        return this.post('/uspproc/upload/assinatura/salvarDocumentoLoteDocAss', params, [file] );
    }

    listarDocumentos(params) {
        return this.post('/uspproc/listar/assinatura/listarDocumentoLoteDocAss', params);
    }

    listarTipoAssinatura() {
        return this.post('/uspproc/listar/assinatura/listarTipoAssinatura', {});
    }

    obterDocumento(params) {
        return this.download('/uspproc/download/assinatura/obterDocumentoLoteDocAss?coddocuspasi=' + params.coddocuspasi +
            '&codlotdocasi=' + params.codlotdocasi);
    }

    removerDocumento(params) {
        return this.post('/uspassinaws/removerDocumentoLoteDocAss', params);
    }

    obterDadosHash(params) {
        return this.post('/uspproc/obter/assinatura/obterDadosHashAssinar', params);
    }

    assinarDocumento(params) {
        return this.post('/uspassinaws/assinarDocumento', params);
    }

    cadastrarGestor(params) {
      return this.post('/uspproc/executar/assinatura/criarUsuarioAssina', params);
    }

    sincronizarDocusign(params) {
        return this.post('/uspassinaws/sincronizarDadosBD', params);
      }
}