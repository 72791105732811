let exports = {};

exports.alternarBarraLateral = () => {
    const el = document.body;
    el.classList.add("efeito-suavizar");
    el.classList.toggle("usp-menu-expandido");
    setTimeout(() => { el.classList.remove("efeito-suavizar"); }, 500);
    localStorage.setItem("menu-expandido",!JSON.parse(localStorage.getItem("menu-expandido")));  
  }

export default exports;