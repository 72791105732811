const Mandato = () => import(/* webpackChunkName: "chunk-nereu" */ '../views/Mandato')

export const routes = [
    {
      path: '/:codclg/:sglclg',
      name: 'mandato',
      component: Mandato,
      props: true,
      meta: {
        breadcrumb:[
          { nome: 'Mandatos' },
        ]
      }
    },
];