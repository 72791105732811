export default class Acao {

    constructor(dtaacao, acao) {
        this.dtaacao = dtaacao
        this.acao = acao
    }

    toString(){
        return `${this.acao} (${this.dtaacao})`
    }

    static buildFromJson(json={}){
        return new Acao(
            json.dtaacao,
            json.acao
        )
    }
}