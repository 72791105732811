import { PortalServico } from "@/utils";
import SolicitacaoProServ from "./SolicitacaoProServ";
import UnidadeProServ from "./UnidadeProServ";
import SetorProServ from "./SetorProServ";
import VertenteProServ from "./VertenteProServ";
import PessoaProServ from "./PessoaProServ";
import FuncaoProServ from "./FuncaoProServ";
import DetalheProServ from "./DetalheProServ";
import AndamentoProServ from "./AndamentoProServ";
import ArquivoProServ from "./ArquivoProServ";
import SituacaoProServ from "./SituacaoProServ";

const API_PREFFIX = '/proserv'

export default class ProServServico extends PortalServico {

  inserir(dados){
    const self = this
    return self[dados.codsolprs ? 'put' : 'post'](
      dados.codsolprs ? `${API_PREFFIX}/solicitacoes/${dados.anosolprs}/${dados.codsolprs}` : `${API_PREFFIX}/solicitacoes/`, 
      dados
    ).then(solicitacao => SolicitacaoProServ.fromJSON(solicitacao));
  }

  inserirDetalhe(propriedade, texto, anosolprs, codsolprs){
    const self = this
    const dados = {}
    if(propriedade){
      dados[propriedade] = texto
    }
    return  self.post(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/detalhes`, dados)
       .then(item => DetalheProServ.fromJSON(item));
  }

  alterarDetalhe(propriedade, texto, anosolprs, codsolprs){
    const self = this
    const dados = {}
    if(propriedade){
      dados[propriedade] = texto
    }
    return  self.put(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/detalhes`, dados)
    .then(item => DetalheProServ.fromJSON(item))
  }

  listarAreasConhecimento(){
    const self = this
    return self.get(`${API_PREFFIX}/estrutura/areas-conhecimento/`)
  }

  listarAndamentos(anosolprs, codsolprs){
    const self = this;
    return self.get(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/andamentos`)
                .then(resultado => resultado.map(item => AndamentoProServ.fromJSON(item)));
  }

  removerSolicitacao(anosolprs, codsolprs){
    const self = this;
    return self.delete(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}`)
  }

  listarDepartamentos(codund){
    const self = this
    return self.get(`${API_PREFFIX}/estrutura/unidades/${codund}/departamentos`)
                .then(lista => lista.map(item => SetorProServ.fromJSON(item)));
  }

  listarFuncoes(){
    const self = this;
    return self.get(`${API_PREFFIX}/estrutura/funcoes/`)
                .then(lista => lista.map(item => FuncaoProServ.fromJSON(item)));
  }

  listarSituacoes(vtesolprs){
    const self = this;
    return self.get(`${API_PREFFIX}/estrutura/situacoes/?vtesolprs=${vtesolprs}`)
                .then(lista => lista.map(item => SituacaoProServ.fromJSON(item)));
  }

  listarSolicitacoes(dados){
    const self = this;
    let url = `${API_PREFFIX}/solicitacoes/`
    if(dados){
      url += '?'+Object.keys(dados).map(chave => `${chave}=${dados[chave]}`).join('&')
    }
    return self.get(url)
                .then(lista => lista.map(item => SolicitacaoProServ.fromJSON(item)));
  }

  listarUnidades(){
    const self = this
    return self.get(`${API_PREFFIX}/estrutura/unidades/`)
                .then(lista => lista.map(item => UnidadeProServ.fromJSON(item)));
  }

  listarVertentes(){
    const self = this
    return self.get(`${API_PREFFIX}/estrutura/vertentes/`)
                .then(lista => lista.map(item => VertenteProServ.fromJSON(item)));
  }

  obterDadosPessoa(codpes){
    const self = this;
    return self.get(`${API_PREFFIX}/estrutura/pessoas/${codpes}`)
                .then(item => PessoaProServ.fromJSON(item));
  }

  obterSolicitacao(anosolprs, codsolprs){
    const self = this;
    return self.get(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}`)
                .then(item => SolicitacaoProServ.fromJSON(item));
  }

  enviarSolicitacao(anosolprs, codsolprs, tipsitprs){
    const self = this;
    return self.patch(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/situacao`, { tipsitprs })
  }

  listarArquivos(anosolprs, codsolprs){
    const self = this;
    return self.get(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/arquivos`)
                .then(resultado => resultado.map(item => ArquivoProServ.fromJSON(item)))
  }

  obterArquivo(anosolprs, codsolprs,numseqarqprs){
    const self = this
    return self.get(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/arquivos/${numseqarqprs}`, { "Content-Type": 'application/octet-stream'}, { responseType: 'blob' })
  }

  inserirArquivo(anosolprs, codsolprs, arquivo){
    const self = this
    const formData = new FormData()
    formData.append('arquivo', arquivo)
    return self.post(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/arquivos`, formData)
                .then(item => ArquivoProServ.fromJSON(item));
  }

  removerArquivo(anosolprs, codsolprs, numseqarqprs){
    const self = this;
    return self.delete(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/arquivos/${numseqarqprs}`)
  }

  listarAprovacoes(vtesolprs, tipsitprs){
    const self = this;
    return self.get(`${API_PREFFIX}/solicitacoes/aprovacao/${vtesolprs}?tipsitprs=${tipsitprs}`)
            .then(resultado => resultado.map(item => SolicitacaoProServ.fromJSON(item)))
  }

  salvarRecurso(anosolprs, codsolprs, form){
    const self = this;
    return self.patch(`${API_PREFFIX}/solicitacoes/${anosolprs}/${codsolprs}/recurso`, form)
  }

}