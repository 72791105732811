export default class ExternoMenu {

    constructor(codigo, nome, url){
        this.codigo = codigo;
        this.nome = nome;
        this.url = url;
    }

    static build(json={}){
        return new ExternoMenu(
            json.codigo,
            json.nome,
            json.url
        )
    }

}