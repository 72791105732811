import Estacao from "./Estacao"
import Unidade from "./Unidade"

export default class Abertura {

    constructor(dtaabt, unidade, estacao) {
        this.dtaabt = dtaabt
        this.unidade = unidade
        this.estacao = estacao
    }

    toString(){
        return `${this.estacao.sgleta} em ${this.dtaabt}`;
    }

    static buildFromJson(json={}){
        return new Abertura(
            json.dtaabt,
            new Unidade(json.sglundabt, json.nomundabt, json.codundabt),
            new Estacao(json.sgletaabt, json.nometaabt)
        )
    }
}