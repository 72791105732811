export default class ArquivoProServ {

  constructor(numseqarqprs, nomarqdla, tamarqdla){
    this.numseqarqprs = numseqarqprs
    this.nomarqdla = nomarqdla
    this.tamarqdla = tamarqdla
  }

  getTamanho(){
    let fator = 1
    let tamanho = this.tamarqdla
    while(tamanho >= 1024){
      tamanho = tamanho / 1024
      fator++
    }
    return tamanho.toFixed(2)+' '+this.getUnidade(fator)
  }

  getUnidade(fator){
    switch (fator) {
      case 1: return 'B';
      case 2: return 'KB';
      case 3: return 'MB'
    }
  }

  getIcone(){
    let partes = this.nomarqdla.split('.')
    let extensao = partes[partes.length - 1]
    switch (extensao) {
      case 'xls':
      case 'xlsx':
        return 'fas fa-file-excel'
      case 'pdf':
        return 'fas fa-file-pdf';
      default:
        return 'fas fa-file-image'
    }
  }

  toString(){
    return `${this.nomarqdla} - ${this.getTamanho()}`
  }

  static fromJSON(json={}){
    return new ArquivoProServ(
      json.numseqarqprs,
      json.nomarqdla,
      json.tamarqdla
    )
  }

}