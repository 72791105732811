import { PortalLogger as log } from "@/utils";

export const CHAVE_PERFIS = 'PORTAL-PERFIS';

export default class GerenciadorPerfil {

    constructor() {
        this._perfis = JSON.parse(localStorage.getItem(CHAVE_PERFIS) || '{}');
    }

    escolher(objeto){
      if(!objeto || (objeto.perfis || []).length === 0){
          log.debug(`Objetoweb ${objeto?.objetoweb} da área ${objeto?.codigoArea} não possui perfis para escolha!`);
          return {};
      }
      let nivel = this._perfis[objeto.codigoArea];
      let perfil = objeto.perfis.find(p => p.nivel == nivel);
      if(perfil){
          log.debug(`Escolhido perfil ${perfil.nivel} do objetoweb ${objeto.objetoweb} da área ${objeto.codigoArea}`);
      } else {
          perfil = objeto.perfis.find(p => p.nivel === objeto.perfilPadrao?.nivel);
          if(perfil){
              log.debug(`Retornando o perfil padrão ${perfil.nivel} do objetoweb ${objeto.objetoweb} da área ${objeto.codigoArea}`);
          } else {
              perfil = objeto.perfis[0] || {};
              log.debug(`Retornando o primeiro perfil ${perfil.nivel} do objetoweb ${objeto.objetoweb} da área ${objeto.codigoArea}`);
          }
      }
      return perfil;
    }

    trocar(perfil){
        this._perfis[perfil.codigoArea] = perfil.nivel;
        localStorage.setItem(CHAVE_PERFIS, JSON.stringify(this._perfis));
    }

}