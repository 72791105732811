import { PortalServico } from "@/utils"
import { ESTRUTURA_BASE_URL } from "./contantes"
import Assunto from "./modelos/Assunto"
import Estacao from "./modelos/Estacao"
import TipoConferencia from "./modelos/TipoConferencia"
import TipoDocumento from "./modelos/TipoDocumento"
import Unidade from "./modelos/Unidade"

/**
 * @type {EstruturaServico}
 */
 export default class EstruturaServico extends PortalServico {

    static build (options = {}) {
        return super.build({
            ...options,
            headers: {
                'X-Username': 'Portal'
            }
        })
    }

    listarAssunto(){
        const self = this
        return self.get(`${ESTRUTURA_BASE_URL}/assunto`)
            .then(lista => lista.map(i => Assunto.buildFromJson(i)))
    }

    listarEstacaoUnidade(codund){
        const self = this
        return self.get(`${ESTRUTURA_BASE_URL}/estacao?codund=${codund}`)
            .then(lista => lista.map(i => Estacao.buildFromJson(i)))
    }

    listarEstacaoPessoa(codpes){
        const self = this
        return self.get(`${ESTRUTURA_BASE_URL}/estacao/usuario/${codpes}`)
            .then(lista => lista.map(i => Estacao.buildFromJson(i)))
    }

    listarEstacaoUsuario(){
        const self = this
        return self.get(`${ESTRUTURA_BASE_URL}/estacao/usuario`)
            .then(lista => lista.map(i => Estacao.buildFromJson(i)))
    }

    listarTiposConf(){
        const self = this
        return self.get(`${ESTRUTURA_BASE_URL}/conferenciaDoc`)
        .then(lista => lista.map(i => TipoConferencia.buildFromJson(i)))
    }

    listarTiposDoc(codasu){
        const self = this
        return self.get(`${ESTRUTURA_BASE_URL}/assunto/${codasu}/tipodoc`)
        .then(lista => lista.map(i => TipoDocumento.buildFromJson(i)))
    }

    listarUnidades(){
        const self = this
        return self.get(`${ESTRUTURA_BASE_URL}/unidade`).then(lista => lista.map(i => Unidade.buildFromJson(i)))
    }

    listarMembros(sgleta){
        const self = this
        return self.get(`${ESTRUTURA_BASE_URL}/estacao/${sgleta}/acesso?tipo=UET`)
    }


 }