<template>
  <div>
    <PortalHomeSolicitarSenha ref="SolicitarSenha" />
    <uspCartao titulo="Login">
      <template #corpo>
        <uspAlerta 
          v-show="!!erro"
          variante="perigo"
          fechavel
        >
          {{ erro }}
        </uspAlerta>
        <form 
          novalidate
          @submit.prevent="logar"
        >
          <div class="form-group">
            <label
              class="sr-only"
              for="codpes"
            >
              Usuário / Número USP
            </label>
            <input
              v-model="nusp"
              type="text"
              name="codpes"
              autocomplete="username"
              class="form-control"
              placeholder="Número USP"
            >
          </div>
          <div class="form-group">
            <label
              class="sr-only"
              for="senusu"
            >
              Senha
            </label>
            <input
              v-model="senha"
              type="password"
              name="senusu"
              autocomplete="current-password"
              class="form-control"
              placeholder="Senha"
            >
          </div>
          <uspBotao
            type="submit"
            class="btn btn-primary btn-block mb-2"
            :disabled="carregando || !valido"
            texto="Acessar minha conta"
          >
            <span
              v-if="carregando"
              class="spinner-border"
              role="status"
              aria-hidden="true"
            />
          </uspBotao>
          <nav class="nav d-flex justify-content-between">
            <a
              class="nav-link"
              href="https://id.usp.br/primeiro-acesso"
              target="_blank"
            >
              Primeiro Acesso
            </a>
            <a
              class="nav-link"
              href="https://id.usp.br/senha-unica"
              target="_blank"
            >
              Esqueci minha senha
            </a>
          </nav>
        </form>
      </template>
    </uspCartao>
  </div>
</template>

<script>
import { StorageKeys } from "@/portal/constantes";
import { mapActions, mapGetters } from "vuex";
import PortalHomeSolicitarSenha from "./PortalHomeSolicitarSenha.vue";

export default {
  name: "PortalHomeLogin",

  components: {
    PortalHomeSolicitarSenha,
  },

  data() {
    return {
      carregando: false,
      erro: "",
      msgErroDefault: "Ocorreu um erro ao logar. Por favor, tente novamente mais tarde",
      nusp: "",
      senha: "",
    };
  },

  computed: {
    ...mapGetters({
      redirect: "auth/redirect",
    }),
    valido(){
        return this.validoUsuario &&
               this.validoSenha
      },
      /**
       * Valida que o NUSP tenha ao menos 5 dígitos
       */
       validoUsuario(){
        return (this.nusp || '').length >= 5
      },
      /**
       * Valida que a senha tenha ao menos 8 dígitos
       */
      validoSenha(){
        return (this.senha || '').length >= 8
      },
  },

  methods: {
    ...mapActions({
      autenticar: "auth/autenticar",
    }),
    logar() {
      const self = this;
      const redirect = localStorage.getItem(StorageKeys.AUTH.REDIRECT);
      self.carregando = true;
      // Execute reCAPTCHA with action "login".
      self.$recaptcha("login").then((token) => {
        this.autenticar({
          usuario: {
            codpes: self.nusp,
            senusu: self.senha,
          },
          token,
        })
          .then(() => {
            if (redirect) {
              self.$router.push(redirect);
              localStorage.removeItem(StorageKeys.AUTH.REDIRECT);
            } else {
              self.$router.push({ name: "servicos" });
            }
          })
          .catch((erro) => (self.erro = erro.mensagem))
          .finally(() => (self.carregando = false));
      }).catch((erro) => (self.erro = self.msgErroDefault));
    },
  },
};
</script>

<style scoped lang="scss">
input.form-control {
  background: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 1.875rem;
  color: #333;
  transition: 0.5s;
}
</style>
