<template>
  <div 
    :id="idInterno"
    v-clicar-fora="fechar"
    class="dropdown" 
    :class="{ show: exibir }"
  >
    <a
      :id="idLink"
      class="dropdown-toggle"
      :class="estilo"
      role="button"
      aria-haspopup="true"
      :aria-expanded="`${exibir}`"
      @click="exibir = !exibir"
    >
      <slot name="conteudo">
        <em
          v-if="icone" 
          :class="icone"
        />
        {{ texto }}
      </slot>
    </a>

    <div
      class="dropdown-menu"
      :class="{ show: exibir, 'dropdown-menu-right': alinharDireita }"
      :aria-labelledby="idLink"
      @click="fechar"
    >
      <slot name="itens">
        <a
          v-for="opcao in opcoes"
          :key="opcao.id"
          class="dropdown-item"
          :disabled="opcao.desabilitado"
          @click="clicar(opcao.dados)"
        >
          <em 
            v-if="opcao.icone" 
            :class="opcao.icone + ' fa-sm mr-2'"
          />
          {{ opcao.titulo }}
        </a>
      </slot>
    </div>
  </div>
</template>

<script>
import UspIdUnico from "@/componentes/plugins/UspIdUnico";
import { DROPDOWN_VARIANTES, DROPDOWN_TAMANHOS } from "./constantes";

export default {
  name: 'UspDropdown',
  props: {
    /**
     * Indica se o estilo de contorno (botão bootstrap) deve ser adicionado à variante escolhida.
     */
    contorno: {
      type: Boolean,
      required: false,
    },
    /**
     * Ícone para ser renderizado no link acionável (antes do texto), funciona apenas quando não é usado o Slot conteudo
     */
    icone: {
      type: String,
      default: null
    },
    /**
     * ID base para ser usado como prefixo em todos os elementos
     */
    id: {
      type: String,
      default: null,
    },
    /**
     * Vetor de opções com estrutura padronizada para renderizar no popup, funciona quando não é usado o Slot itens
     */
    opcoes: {
      type: Array,
      default: () => []
    },
    /**
     * Variação de Tamanho de botão no link acionável
     */
    tamanho: {
      type: String,
      default: "",
      validator: (value) => {
        return (
          DROPDOWN_TAMANHOS.map(function(e) {
            return e.pt;
          }).indexOf(value) !== -1
        );
      },
    },
    /**
     * Texto a ser exibido no link acionável, funciona apenas quando não é usado o Slot conteudo
     */
    texto: {
      type: String,
      default: ''
    },
    /**
     * Variante de estilo de botão (bootstrap) para ser usado no elemento
     */
    variante: {
      type: String,
      default: '',
      validator: (value) => {
        return value ? (
          DROPDOWN_VARIANTES.map(function(e) {
            return e.pt;
          }).indexOf(value) !== -1
        ): true;
      },
    },

   
    alinhamento: {
      type: String,
      default: 'direita'
    },
  },
  data() {
    return {
      idInterno: this.id || UspIdUnico.gerar(),
      exibir: false,
    };
  },
  computed: {
    estilo() {
      if(!this.variante) return ''
      let estilo = [
        'btn',
        `btn${this.contorno ? '-outline' : ''}-${this.varianteBS}`,
        this.tamanhoBS ? `btn-${this.tamanhoBS}` : ''
      ]
      return estilo.join(' ')
    },
    estiloContorno() {
      return this.contorno ? "-outline" : "";
    },
    idLink(){
      return `${this.idInterno}-link`
    },
    tamanhoBS() {
      return DROPDOWN_TAMANHOS.find((elemento) => elemento.pt == this.tamanho)
        .en;
    },
    varianteBS() {
      return DROPDOWN_VARIANTES.find((elemento) => elemento.pt == this.variante)
        .en;
    },
    alinharDireita() {
      return this.alinhamento === 'direita';
    },
  },
  methods: {
    clicar(dados) {
      this.$emit('clicar', dados)
    },
    fechar() {
      this.exibir = false;
    },
  },

};
</script>

<style lang="scss">
@import "../../estilos/temas";

.navbar-expand .navbar-nav .dropdown-menu {
  box-shadow: 0 0px 20px 0 rgba(69, 67, 96, 0.15);
  border-radius: 1rem !important;
  border: none;
  border-color: #f0f0f0 !important;
  color: #fff;
  .dropdown-header {
    font-weight: 600;
    font-size: 0.95rem;
  }
}

.dropdown {
  .dropdown-toggle {
    white-space: unset;
    &:hover {
      text-decoration: none;
    }
  }
  .dropdown-menu {
    z-index: 1200;
    overflow-x: hidden;
    
    .dropdown-header {
      display: block;
      padding: 0.5rem 1.5rem;
      margin-bottom: 0;
      font-size: 0.875rem;
      white-space: nowrap;
    }
  }
  .dropdown-item {
    cursor: pointer;
  }
}

/* Temas */

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .dropdown {
      .dropdown-menu{
      background: map-get($tema, "usp-elemento-cor-de-fundo");
      box-shadow: map-get($tema, "usp-elemento-sombra");
      color: map-get($tema, "usp-elemento-cor-do-texto");
      border-radius: map-get($tema, "usp-elemento-borda-arredondada");
      .dropdown-header{
        color: map-get($tema, "usp-elemento-cor-do-texto");
      }
      .dropdown-item {
        color: map-get($tema, "usp-elemento-cor-do-texto");
        &:hover, &:focus{
          background: map-get($tema, "usp-elemento-cor-de-fundo");
          opacity: .7;
        }
      }
    }
  }
}
}
</style>
