<template>
  <ul :class="estilo">
    <slot name="default" />

    <li
      v-for="item in lista"
      :key="item.id"
      :class="estiloItem"
      @click="clicar(item)"
    >
      <slot
        name="conteudo"
        :item="item"
      >
        {{ item.conteudo }}
      </slot>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'UspLista',

  props: {
    lista: {
      type: Array,
      default: () => []
    },
    classes: {
      type: String,
      default: ''
    },
    espacamento: {
      type: Boolean,
      required: false,
      default: false,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    clicavel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    estilo() {
      let esp = this.espacamento === true ? "espacamento" : "";
      let hor = this.horizontal ? "list-group-horizontal" : "";
      return `list-group ` + (esp || "") + ` ${hor} ` + (this.classes || "");
    },
    estiloItem() {
      let action = this.clicavel === true ? "list-group-item-action" : "";
      return `list-group-item ` + (action || "");
    },
  },

  methods: {
    clicar(dados) {
      if (this.clicavel) {
        this.$emit('clicar', dados);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../estilos/temas";

ul.list-group {
  &.espacamento {
    .list-group-item {
      border-radius: 1rem;
    }
    .list-group-item + .list-group-item {
      margin-top: 1rem;
      border-top-width: 2px;
    }
  }
  &:not(.list-group-horizontal) {
    .list-group-item {
      border-width: 1px;
      &:first-child {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
      &:last-child {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
      & + .list-group-item {
        border-top-width: 0px;
      }
    }
    &.list-group-horizontal {
    .list-group-item {
      border-width: 1px;
      &:first-child {
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
      }
      &:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
      & + .list-group-item {
        border-top-width: 0px;
      }
    }
  }
}
}



@media screen and (max-width: 968px) {

ul.list-group-horizontal {
  flex-wrap: wrap;
  .list-group-item{
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

}

/* Aplicação das variáveis por tema */
@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    ul.list-group {

      li{
        background: map-get($tema, "usp-subelemento-cor-de-fundo");
        color: map-get($tema, "usp-elemento-cor-do-texto");
        &.active{
          background: map-get($tema, "usp-subelemento-cor-de-fundo-ativo");
        }
        &.list-group-item-action:hover{
          background: map-get($tema, "usp-subelemento-cor-de-fundo-desabilitado");
        }
      }

      &.espacamento {
        .list-group-item {
          border-color: map-get($tema, "usp-subelemento-cor-de-fundo-desabilitado");
        }
      }      
    }
  }
}
</style>
