import { VARIANTES } from '../constantes';

export const LINK_VARIANTES = [
    ...VARIANTES,
    {'pt': 'link', 'en': 'link'}, 
];

export const LINK_TAMANHOS = [
    {'pt': 'pq', 'en': 'sm'}, 
    {'pt': '', 'en': ''}, 
    {'pt': 'gd', 'en': 'lg'}, 
    {'pt': 'bc', 'en': 'block'}, 
];