import CriterioProServ from "./CriterioProServ"
export default class AvaliacaoProServ {

  constructor(tipcrtprs, vlrnotcrt, jusnotcrt, dtacad, dtaultalt, stadcacalmed, leitura){
    this.tipcrtprs = tipcrtprs
    this.vlrnotcrt = vlrnotcrt
    this.jusnotcrt = jusnotcrt
    this.dtaultalt = dtaultalt
    this.dtacad = dtacad
    this.stadcacalmed = stadcacalmed
    this.leitura = leitura
    this.filhos = []
  }

  static fromJSON(json={}){
    return new AvaliacaoProServ(
      CriterioProServ.fromJSON(json.tipcrtprs),
      json.vlrnotcrt,
      json.jusnotcrt,
      json.dtacad,
      json.dtaultalt,
      json.stadcacalmed,
      json.leitura
    )
  }

}