
export default class DetalheProServ {

  constructor(jussol, etrsol, pcenaoaprsol, jusrcs, pcercs) {
      this.jussol = jussol
      this.etrsol = etrsol
      this.pcenaoaprsol = pcenaoaprsol
      this.jusrcs = jusrcs
      this.pcercs = pcercs
  }

  isVazio(){
    return !this.jussol && !this.etrsol
  }

  static fromJSON(json={}){
    return new DetalheProServ(
      json.jussol,
      json.etrsol,
      json.pcenaoaprsol,
      json.jusrcs,
      json.pcercs
    )
  }
}