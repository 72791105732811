import PessoaProServ from "./PessoaProServ"
import SituacaoProServ from "./SituacaoProServ"

export default class AndamentoProServ {

  constructor(numseqandprs, pesgrcand, sitprsand, dtaandsolprs, txtand) {
      this.numseqandprs = numseqandprs
      this.pesgrcand = pesgrcand
      this.sitprsand = sitprsand
      this.dtaandsolprs = dtaandsolprs
      this.txtand = txtand
  }

  static fromJSON(json={}){
    return new AndamentoProServ(
      json.numseqandprs,
      PessoaProServ.fromJSON(json.pesgrcand),
      SituacaoProServ.fromJSON(json.sitprsand),
      json.dtaandsolprs,
      json.txtand
    )
  }
}