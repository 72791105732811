import Estacao from "./Estacao"

export default class Entrada {

    constructor(dtaent, estacao) {
        this.dtaent = dtaent
        this.estacao = estacao
    }

    static buildFromJson(json={}){
        return new Entrada(
            json.dtaent,
            new Estacao(json.sgletaent)
        )
    }
}