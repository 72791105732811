<template>
  <header class="usp-header">
    <nav class="navbar navbar-expand fixed-top justify-content-between">
      <div class="usp-logo">
        <span 
          class="usp-sidebar-collapse" 
          role="switch" 
          @click="alternarBarraLateral()"
        >
          <em class="fas fa-bars fa-md" />
          <span class="sr-only">Alternar formato de exibição do menu</span>
        </span>
        <router-link 
          class="navbar-brand"
          :to="{ name: 'home' }"
        >
          <svg
            id="logo-usp"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 984.76 399.31"
            alt="Logo da Universidade de São Paulo"
            role="img"
          >
            <title>Logo da Universidade de São Paulo</title>
            <g id="g10">
              <g id="g12">
                <path
                  id="path14"
                  class="cls-1"
                  d="M319.37,306.16a66.66,66.66,0,0,1-66.53,66.53v26.62A93.24,93.24,0,0,0,332.68,354V306.16Zm26.62,0a66.67,66.67,0,0,0,66.54,66.53v26.62A93.25,93.25,0,0,1,332.68,354V306.16Zm319.38-213A66.65,66.65,0,0,1,731.9,26.62V0a93.23,93.23,0,0,0-79.84,45.27V93.15Zm-26.62,0h13.31V45.31A93.26,93.26,0,0,0,572.21.05V26.66A66.66,66.66,0,0,1,638.75,93.19Zm0,212.94a66.73,66.73,0,0,1-66.54,66.6v26.54a93.34,93.34,0,0,0,93.16-93.14ZM958.13,93.2h26.63A93.34,93.34,0,0,0,891.59.05V26.66A66.67,66.67,0,0,1,958.13,93.2ZM26.61,306.13H0a93.33,93.33,0,0,0,93.15,93.14V372.65A66.66,66.66,0,0,1,26.61,306.13ZM412.55.05c-48.76,0-93.26,42.06-93.18,93.12H346c0-37.83,30.91-66.55,66.54-66.57-.09-12.66-.08-16.87,0-26.55ZM665.37,372.66v26.63H825.06V266.19h159.7V93.2H958.13V239.57H825.06V93.2H798.44V372.65ZM731.9,0,891.59.05V26.69L731.9,26.62ZM665.43,247.35l-.06,58.78H638.75V258.39L483.07,102.47l18.66-18.86ZM319.37,152l163.7,163.74,18.66-18.86L346,140.93V93.17H319.37V152ZM0,.05H319.37V26.66H186.3v278.9H159.68V26.66H26.61V306.13H0Zm93.15,372.6H252.87v26.64H93.15ZM412.53.06h159.7V26.69l-159.7-.09V.06Zm0,372.63,159.7.1v26.49l-159.7,0V372.69h0"
                  transform="translate(0 0)"
                />
              </g>
            </g>
          </svg>
        </router-link>
        <h5 class="titulo d-none d-sm-inline">
          {{ autenticado ? "Portal de Serviços" : "Portal de Serviços" }}
        </h5>
      </div>

      <div class="menu">
        <ul class="navbar-nav">
          <PortalDropNovidades 
            v-if="autenticado" 
            class="mr-3" 
            aria-label="Novidades" 
          />

          <uspDropdown
            v-if="autenticado"
            :botao="false"
            class="dadosUsuario ml-1"
            alinhamento="direita"
            aria-label="Configurações da conta"
          >
            <template #conteudo>
              <div class="fotoUsuario">
                <uspAvatar 
                  :src="fotoUrl" 
                  :legenda="'Foto de ' + usuarioLogado" 
                />
              </div>
              <span class="d-none d-lg-block">{{ usuarioLogado }}</span>
            </template>

            <template #itens>
              <uspDropdownItemTitulo>Sua conta</uspDropdownItemTitulo>
              <router-link
                :to="{ name: 'configuracoes' }"
                class="dropdown-item"
              >
                <em class="fas fa-cog" />
                Configurações
              </router-link>

              <uspDropdownSeparador />

              <a
                class="dropdown-item"
                href="/sair"
                @click.prevent="fazerLogout()"
              >
                <em class="fas fa-sign-out-alt" />
                Sair
              </a>
            </template>
          </uspDropdown>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import PortalDropNovidades from "../header/PortalDropNovidades";
import eventBus from "@/eventBus";
import { mapGetters } from "vuex";
import BarraLateral from "@/utils/BarraLateral";

export default {
  name: "PortalHeader",

  components: {
    PortalDropNovidades
  },

  data() {
    return {
      rand: Math.random(),
    };
  },

  computed: {
    ...mapGetters({
      'autenticado': 'auth/autenticado',
      'usuarioLogado': 'auth/usuarioLogado'
    }),
    fotoUrl() {
      return this.autenticado ? `/proxy/cartao/foto?rand=${this.rand}` : "";
    },
  },

  watch: {
    autenticado(auth) {
      if (auth) {
        this.rand = Math.random();
      }
    },
  },

  mounted() {
    if (this.autenticado) {
      this.rand = Math.random();
    }
  },

  created() {
    eventBus.onMostrarMensagemToast(($event) => {
      this.$bvToast.toast($event.mensagem, {
        title: $event.titulo,
        variant: $event.variante || "primary",
        autoHideDelay: $event.duracao * 1000 || 30 * 1000,
        appendToast: $event.posicao != "topo",
        noCloseButton: $event.semBotaoFechar,
        href: $event.href,
        toaster: $event.toaster,
      });
    });
  },

  methods: {
    fazerLogout() {
      const self = this;
      self.$store.dispatch("auth/deslogar").then(() => {
        if(self.$router.currentRoute !== "/") {
          self.$router.push({ name: "home" });
        }
      });
    },

    alternarBarraLateral: () => {
      BarraLateral.alternarBarraLateral();
    },

    
  },

};
</script>

<style lang="scss">
@import "./src/componentes/estilos/temas.scss";

/* Geral */
body:not(.usp-menu-expandido) {
  .usp-logo img,
  #logo-usp {
    margin-left: 3.2rem;
    transition: ease-in-out 0.5s;
  }
  h5.titulo {
    transition: ease-in-out 0.5s;
  }
}

body.usp-menu-expandido {
  .usp-logo a {
    transition: ease-in-out 0.5s;
  }
  .usp-sidebar-collapse {
    border-right: 0rem;
  }
  h5.titulo {
    transition: ease-in-out 0.5s;
  }
}

header.usp-header {
  .menu {
    margin-right: 2rem;

    i, em {
      position: relative;
      margin-right: 0.3rem;
    }
  }

  nav.navbar {
    margin-bottom: 1rem;
    padding-left: 0px;
    box-shadow: 0 5px 20px 0 rgba(69, 67, 96, 0.05) !important;

    .usp-sidebar-collapse {
      width: 4.375rem;
      text-align: center;
    }
    .usp-sidebar-collapse:hover {
      opacity: 0.8;
    }

    .dadosUsuario a {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .fotoUsuario {
      height: 2rem;
      width: 2rem;
      min-height: 1.5rem;
      min-width: 1.5rem;
      margin-right: 0.5rem;
      overflow: hidden;
      border-radius: 1rem;
      img {
        width: 100%;
      }
    }
    .usp-logo {
      display: flex;
      align-items: center;
      img,
      svg {
        padding: 0rem 1.25rem 0rem 0rem;
        text-align: left;
        max-height: 1.875rem;
        width: 5.625rem;
      }
    }
    .navbar-nav {
      align-items: center;
    }
    .usp-logged-user {
      i, em {
        position: relative;
        margin-right: 1rem;
      }
      .badge {
        position: absolute;
        right: 1.6rem;
        bottom: 0rem;
      }
    }
    h5.titulo {
      margin-bottom: 0;
      font-weight: bold;
      display: inline-block;
      padding-left: 0rem;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      margin-right: 1rem;
      font-size: 1.25rem;
      line-height: inherit;
      white-space: nowrap;
    }
  }
}

@media (max-width: 768px) {
  .usp-sidebar-collapse {
    border-right: 0px !important;
  }
  .usp-logo {
    svg {
      margin: 0rem !important;
    }
    a {
      border-right: 0px !important;
    }
    img {
      margin-left: 0rem !important;
      margin-right: 0.5rem !important;
    }
  }

  .menu {
    margin-right: 0rem !important;
  }
}

@media (min-width: 769px) {
  .usp-menu-expandido {
    .usp-logo {
      a {
        margin-right: 4.5rem;
      }
      img,
      #logo-usp {
        margin-left: 2rem !important;
        margin-right: 3rem;
        transition: ease-in-out 0.5s;
      }
    }
  }
}

/* Temas */

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    #logo-usp {
      fill: map-get($tema, "usp-barra-superior-cor-do-texto");
    }
    &:not(.usp-menu-expandido) {
      .usp-sidebar-collapse {
        border-right: 1px
          solid
          map-get($tema, "usp-barra-superior-cor-do-texto");
      }
    }
    header.usp-header {
      nav.navbar {
        background: map-get($tema, "usp-barra-superior-cor-de-fundo");
        color: map-get($tema, "usp-barra-superior-cor-do-texto");
        height: map-get($tema, "usp-barra-superior-altura");
        border-top: map-get($tema, "usp-barra-superior-borda-superior");
        .dropdown > a{
          color: map-get($tema, "usp-barra-superior-cor-do-texto");
        }
        ul.navbar-nav {
          & > li a.nav-link {
            color: map-get($tema, "usp-barra-superior-cor-do-texto");
          }
        }
      }
    }
  }
}
</style>
