<template>
  <div class="progress">
    <div
      :class="estilo"
      role="progressbar"
      :style="preenchimento"
      :aria-valuenow="valorAtual"
      :aria-valuemin="valorMinimo"
      :aria-valuemax="valorMaximo"
    />
  </div>
</template>

<script>
import { PROGRESSO_VARIANTES } from "./constantes";

export default {
  name: "UspProgresso",

  props: {
    valorAtual: {
      type: Number,
      required: true,
      default: 0,
      validator: (value) => {
        return Number.isInteger(value);
      },
    },

    valorMinimo: {
      type: Number,
      default: 0,
      required: false,
      validator: (value) => {
        return Number.isInteger(value);
      },
    },

    valorMaximo: {
      type: Number,
      default: 100,
      required: false,
      validator: (value) => {
        return Number.isInteger(value);
      },
    },
    
    variante: {
      type: String,
      default: "success",
      validator: (value) => {
        return PROGRESSO_VARIANTES.map(function(e) { return e.pt; }).indexOf(value) !== -1;
      },
    },
  },

  computed:{
     classeBS(){
      return PROGRESSO_VARIANTES.find(elemento => elemento.pt == this.variante).en;
    },
      preenchimento(){
          return `width:${this.valorAtual}%`;
      },
      estilo(){
          return `bg-${this.classeBS} progress-bar`;
      }
  }
};
</script>
