<template>
  <uspDropdown id="header-alerts-dropdown" :botao="false" alinhamento="direita">
    <template slot="conteudo">
      <i class="fas fa-bell fa-lg"></i>
      <uspBadge :texto="novos" variante="perigo" v-show="novos > 0" />
    </template>
    <template slot="itens">
      <div class="usp-tarefas-mini">
        <h6 class="titulo">Minhas Notificações</h6>
        <div class="carregando" v-show="!carregado">
          <uspSpinner />
          Carregando notificações...
        </div>
        <ul class="list-group" v-show="carregado">
          <li
            v-for="nov in novidades"
            :key="nov.getKey()"
            :class="{ lido: nov.isVisualizado() }"
            @click.prevent="ativarNovidade(nov)"
            class="list-group-item list-group-item-action"
          >
            <div class="conteudo">
              <h6 class="area">
                <i :class="getIcone(nov)"></i>
                {{ nov.nomeArea }}
              </h6>
              <h6 class="titulo">
                <span v-html="nov.titulo"></span>
              </h6>
              <span class="data">
                <i class="far fa-clock"></i>
                {{ nov.data }}
              </span>
            </div>
          </li>
        </ul>
        <div
          class="d-flex justify-content-center flex-column"
          v-if="carregado && novidades.length == 0"
        >
          <div class="d-flex justify-content-center">
            <img
              src="/imgs/usp-inbox.svg"
              class="p-5 w-80 text-center"
              alt="Ilustração de uma caixa vazia"
            />
          </div>
          <p class="text-center">Não há notificações.</p>
        </div>
      </div>
    </template>
  </uspDropdown>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters } from "../../store/types";

export default {
  name: "PortalDropNovidades",

  computed: {
    ...mapGetters({
      carregado: Getters.NOTIFICACOES.STATUS_CARREGADO,
      novidades: Getters.NOTIFICACOES.NOVIDADES,
    }),
    novos() {
      return this.novidades.filter((n) => !n.isVisualizado()).length+"";
    },
  },

  methods: {
    ...mapActions({
      obterAviso: Actions.NOTIFICACOES.OBTER_AVISO,
      visualizarTarefa: Actions.NOTIFICACOES.VISUALIZAR_TAREFA,
    }),
    ativarNovidade(nov) {
      if (nov.tipo === "aviso") {
        this.obterAviso(nov);
      } else {
        this.visualizarTarefa(nov);
        if (nov.url) {
          // É alerta
          let url = nov.url;
          if(url.indexOf('http') !== -1){
                window.open(url, '_blank');
          } else {
            if (url[0] === "/") {
              url = url.substring(1);
            }
            this.$router.push(`/servicos/${nov.codigoArea}/${url}`);
          }
        } else {
          if (this.$router.currentRoute.fullPath !== "/painel") {
            this.$router.push(`/painel`);
          }
        }
      }
    },
    getIcone(icone) {
      return icone.tipo === "alerta" ? "fas fa-tasks" : "fas fa-inbox";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/componentes/estilos/temas.scss";

.carregando {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carregando > * {
  margin: 0 3px;
}

.usp-tarefas-mini {
  min-width: 360px;
}

.usp-tarefas-mini .list-group-item-action {
  cursor: pointer;
}

.usp-tarefas-mini {
  min-width: 20rem;
  font-size: 0.85rem;
  padding: 1rem;
  > h6.titulo {
    display: block;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
    margin-bottom: 1rem;
  }
  .acoes {
    padding: 0.5rem;
    text-align: center;
  }
  i {
    margin-right: 0rem !important;
  }
  .list-group-item {
    display: flex;
    justify-content: space-between;
    //flex-direction: column;

    &:first-child {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }

    &:last-child {
      border-bottom-left-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }

    &.lido {
      background-color: #f9f9f9;
      opacity: 0.8;
    }

    .detalhes {
      flex-basis: 15%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 1rem;
      > i {
        font-size: 1.2rem;
      }
    }
    .conteudo {
      //flex-basis: 60%;
      .area {
        margin-bottom: 0rem;
        color: #1094ab;
      }
      .data {
        font-size: 0.7rem;
        color: #777;
        margin-bottom: 0.2rem;
      }
      .titulo {
        font-weight: 500;
        font-size: 0.9rem;
      }
      .descricao {
        color: #888;
        max-height: 100px;
      }
    }
    .acoes {
      width: 3rem;
      padding: 0rem;
    }
  }
}

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .usp-tarefas-mini {
      color: map-get($tema, "usp-elemento-cor-do-texto") !important;
    }
  }
}
</style>
