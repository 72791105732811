import axios from 'axios';
import { PortalLogger } from '@/utils';

const CACHE_CONTROL = 'Cache-Control',
      CONTENT_TYPE = 'Content-Type',
      CONTENT_JSON = 'application/json',
      CONTENT_MULTIPART = 'multipart/form-data',
      NO_CACHE = 'no-cache, no-store, must-revalidate',
      RESPONSE_TYPE  = 'responseType',
      RESPONSE_TYPE_BLOB = 'blob',
      RESPONSE_TYPE_JSON = 'json';

const GET = 'get',
      DELETE = 'delete',
      PATCH = 'patch',
      POST = 'post',
      PUT = 'put';

const DEFAULT_OPTIONS = {
  autorizacaoHeader: 'Usp-Key',
  autorizacaoStorageKey: 'AUTH_USUARIO_TOKEN',
  baseURL: '/proxy',
  credenciais: true,
  erroPadrao: 'Ocorreu um problema com a sua solicitação, por favor tente novamente mais tarde!',
  formDataArquivos: 'file',
  formDataDados: 'json',
}

const INTERCEPTADORES = {
  RESPOSTA: null,
  RESPOSTA_ERRO: null,
  REQUISICAO: null,
  REQUISICAO_ERRO: null
}

axios.interceptors.request.use(config => {
  PortalLogger.trace('Dentro do interceptador de requisição');
  if(INTERCEPTADORES.REQUISICAO){
    INTERCEPTADORES.REQUISICAO.call(null, config);
  }
  return config;
}, (error) => {
  PortalLogger.trace('Dentro do interceptador de erros na requisição');
  if(INTERCEPTADORES.REQUISICAO_ERRO){
    INTERCEPTADORES.REQUISICAO_ERRO.call(null, error);
  }
  return Promise.reject(error);
});

axios.interceptors.response.use(resp => {
  PortalLogger.trace('Dentro do interceptador de respostas');
  if(INTERCEPTADORES.RESPOSTA){
    INTERCEPTADORES.RESPOSTA.call(null, resp);
  }
  return resp;
},(error) => {
  PortalLogger.trace('Dentro do interceptador de erros na resposta');
  if(INTERCEPTADORES.RESPOSTA_ERRO){
    INTERCEPTADORES.RESPOSTA_ERRO.call(null, error);
  }
  return Promise.reject(error);
});

/**
 * Classe base para chamadas REST no Portal de Serviços e seus módulos.
 * 
 * Classe configura passagem do Token de autorização pelo Header correto, 
 * os cabelahos padrões e interpretação da resposta.
 * 
 * @type {PortalServico}
 */
export default class PortalServico {

    /**
     * @param {*} options
     */
    constructor (options) {
      this.options = options;
    }

    option(key, value){
      if(value !== undefined){
        this.options[key] = value;
      } else {
        return this.options[key] || DEFAULT_OPTIONS[key];
      }
    }

    download(url, headers={}, extra={}){
      extra[RESPONSE_TYPE] = RESPONSE_TYPE_BLOB;
      return this._executar(GET, url, headers, extra);
    }

    get(url, headers={}, extra={}){
      return this._executar(GET, url, headers, extra);
    }

    post(url, dados={}, arquivos=[], headers={}, extra={}){
      return this._executar(POST, url, headers, extra, dados, arquivos);
    }

    delete(url, dados={}, arquivos=[], headers={}, extra={}){
      return this._executar(DELETE, url, headers, extra, dados, arquivos);
    }

    put(url, dados={}, arquivos=[], headers={}, extra={}){
      return this._executar(PUT, url, headers, extra, dados, arquivos);
    }

    patch(url, dados={}, arquivos=[], headers={}, extra={}){
      return this._executar(PATCH, url, headers, extra, dados, arquivos);
    }

    _configurarDados(dados, arquivos){
      if(arquivos && arquivos.length > 0){
        // SE há arquivos, vamos montar o FormData com cada um deles.
        const formData = new FormData();
        arquivos.forEach(file => {
          formData.append(this.option('formDataArquivos'), file);
        });
        if(dados) {
          formData.append(this.option('formDataDados'), JSON.stringify(dados));
        }
        return formData;
      } else {
        return dados;
      }
    }

    _configurarHeaders(headers, multipart=false) {
        const config = {},
              optHeadets = this.option('headers') || {},
              token = localStorage.getItem(this.option('autorizacaoStorageKey'));

        for(let prop in optHeadets){
          config[prop] = optHeadets[prop];
        }

        for(let prop in headers){
          config[prop] = headers[prop];
        }
    
        // Se não tiver nenhum controle de cache, coloca para não haver cache
        if(!config[CACHE_CONTROL]){
          config[CACHE_CONTROL] = NO_CACHE;
        }
        // Coloca o content-type padrão para JSON, se não houver.
        if(!config[CONTENT_TYPE]){
          config[CONTENT_TYPE] = multipart ? CONTENT_MULTIPART : CONTENT_JSON;
        }

        if(token){
          config[this.option('autorizacaoHeader')] = token;
        }
        
        return config;
    }

    /** Executa uma chamada usando o Axios encapsulado em uma promessa */
    _executar(method, url, headers, config={}, data=null, files=[]) {
        const self = this,
              baseURL = config.baseURL || this.option('baseURL'),
              responseType = config.responseType || RESPONSE_TYPE_JSON,
              withCredentials = this.option('credenciais');
        
        // Configura os headers padrão.
        headers = self._configurarHeaders(headers, (files && files.length > 0));
        // Configura dados
        data = self._configurarDados(data, files);
        
        return self._promisedAxios({ 
          method,
            url,
            headers,
            baseURL,
            data,
            responseType,
            withCredentials
        })
    }

    _promisedAxios({ method, url, headers, baseURL, data, responseType, withCredentials }){
      const self = this
      return new Promise((resolve, reject) => {
        return axios({
            method,
            url,
            headers,
            baseURL,
            data,
            responseType,
            withCredentials
        }).then(resposta => {
          self._tratarResposta(resolve, reject, resposta);
        }).catch(erro => {
          self._tratarErro(resolve, reject, erro);
        });
    });
    }

    _tratarErro(resolve, reject, error){
      if (!error) {
        // O objeto erro veio vazio.
        reject(DEFAULT_OPTIONS.ERROR);
      } else if (error.response) {
        // A requesição ocorreu tudo certo e retorno um código não 2xx.
        reject(error.response.data);
      } else if (error.request) {
        // A requisição foi, mas não retornou resposta. Volta os dados da requisição para serem trabalhados.
        reject(error.request);
      } else if (error.message) {
        // Outro erro genérico.
        reject(error.message);
      } else {
        reject(error.mensagem || error || DEFAULT_OPTIONS.ERROR);
      }
    }

    _tratarResposta(resolve, _r, resposta){
      resolve(resposta.data);
    }

    static interceptadorResposta(callback) {
      INTERCEPTADORES.RESPOSTA = callback;
    }

    static interceptadorErroResposta(callback) {
      INTERCEPTADORES.RESPOSTA_ERRO = callback;
    }

    static interceptadorRequisicao(callback) {
      INTERCEPTADORES.REQUISICAO = callback;
    }

    static interceptadorErroRequisicao(callback) {
      INTERCEPTADORES.REQUISICAO_ERRO = callback;
    }

    /**
     * 
     * @param {*} options 
     */
    static default(options){
      if(!options){
        return DEFAULT_OPTIONS;
      } else {
        for(let prop in options){
          DEFAULT_OPTIONS[prop] = options[prop];
        }
      }
    }

    /**
     * Constrói passando opções.
     * @param {*} options
     */
    static build (options = {}) {
      return new this(options);
    }

}