export const Actions = {
    NAVEGACAO: {
        CARREGAR_PRIVADA: 'NAVEGACAO_CARREGAR_PRIVADA',
        CARREGAR_PUBLICA: 'NAVEGACAO_CARREGAR_PUBLICA',
        CARREGAR_OBJETOWEB_AREA: 'NAVEGACAO_CARREGAR_OBJETOWEB_AREA',
        CARREGAR_OBJETOWEB_LEGADO: 'NAVEGACAO_CARREGAR_OBJETOWEB_LEGADO',
        CRIAR_SESSAO_JANUS: 'NAVEGACAO_CRIAR_SESSAO_JANUS'
    },
    NOTIFICACOES: {
        ADIAR_TAREFA: 'NOTIFICACOES_ADIAR_TAREFA',
        BAIXAR_TAREFA: 'NOTIFICACOES_BAIXAR_TAREFA',
        CARREGAR: 'NOTIFICACOES_CARREGAR',
        OBTER_AVISO: 'NOTIFICACOES_OBTER_AVISO',
        VISUALIZAR_TAREFA: 'NOTIFICACOES_VISUALIZAR_TAREFA'
    }
};

export const Getters = {
    NAVEGACAO: {
        ARVORES: 'NAVEGACAO_GET_ARVORES',
        BUSCA: 'NAVEGACAO_GET_BUSCA',
        EXTERNO: 'NAVEGACAO_GET_EXTERNO',
        OBJETOWEB: 'NAVEGACAO_GET_OBJETOWEB',
        STATUS_CARREGADO: 'NAVEGACAO_GET_STATUS_CARREGADO',
        STATUS_CARREGADO_JANUS: 'NAVEGACAO_GET_STATUS_CARREGADO_JANUS'
    },
    NOTIFICACOES: {
        AVISO_PARA_VISUALIZAR: 'NOTIFICACOES_GET_AVISO_PARA_VISUALIZAR',
        AVISOS: 'NOTIFICACOES_GET_AVISOS',
        NOVIDADES: 'NOTIFICACOES_GET_NOVIDADES',
        STATUS_AVISO_CARREGADO: 'NOTIFICACOES_STATUS_AVISO_CARREGADO',
        STATUS_CARREGADO: 'NOTIFICACOES_STATUS_CARREGADO',
        TAREFA_PARA_ADIAR: 'NOTIFICACOES_GET_TAREFA_PARA_ADIAR',
        TAREFAS_ADIADAS: 'NOTIFICACOES_GET_TAREFAS_ADIADAS',
        TAREFAS_PENDENTES: 'NOTIFICACOES_GET_TAREFAS_PENDENTES',
    }
};

export const Mutations = {
    NAVEGACAO: {
        MENU_JANUS: 'NAVEGACAO_SET_MENU_JANUS',
        NAVEGACAO: 'NAVEGACAO_SET_EXTERNO',
        OBJETOWEB: 'NAVEGACAO_SET_OBJETOWEB',
        STATUS_CARREGANDO: 'NAVEGACAO_SET_STATUS_CARREGANDO',
        STATUS_MENU: 'NAVEGACAO_SET_STATUS_MENU',
        STATUS_MENU_JANUS: 'NAVEGACAO_SET_STATUS_MENU_JANUS'
    },
    NOTIFICACOES: {
        AVISO_PARA_VISUALIZAR: 'NOTIFICACOES_SET_AVISO_PARA_VISUALIZAR',
        AVISOS: 'NOTIFICACOES_SET_AVISOS',
        ESTENDIDO: 'NOTIFICACOES_SET_ALERTA_ESTENDIDO',
        NOVIDADES: 'NOTIFICACOES_SET_NOVIDADES',
        STATUS: 'NOTIFICACOES_SET_STATUS',
        STATUS_AVISO: 'NOTIFICACOES_SET_STATUS_AVISO',
        TAREFA_DATA_ADIADA: 'NOTIFICACOES_SET_TAREFA_DATA_ADIADA',
        TAREFA_RETIRAR: 'NOTIFICACOES_SET_TAREFA_DATA_BAIXA',
        TAREFA_PARA_ADIAR: 'NOTIFICACOES_SET_TAREFA_PARA_ADIAR',
        TAREFA_VISUALIZACAO: 'NOTIFICACOES_SET_TAREFA_VISUALIZACAO',
        TAREFAS: 'NOTIFICACOES_SET_TAREFAS'
    }
};