<template>
  <img 
    :src="src" 
    :alt="legenda" 
    class="rounded-circle img-fluid" 
    :style="tamanho" 
  >
</template>

<script>
export default {
    name: 'UspAvatar',
    props:{
        /**
         * Tamanho da largura em pixels (número inteiro).
         */
        largura:{
            type: Number,
            default: 100,
            validator: (value) => {
                return Number.isInteger(value);
            },
        },
        /**
         * Texto alternativo para constar na imagem de avatar.
         */
        legenda:{
            type: String,
            required: true,
        },
        /**
         * URL para aparecer no source da imagem.
         */
        src:{
            type: String,
            required: true,
        },
    },
    computed:{
        tamanho(){
            return `max-width: ${this.largura}px;`
        }
    }
}
</script>