<template>
  <div>
    <aside class="usp-sidebar">
      <ul
        v-if="carregado"
        role="menu"
        class="nav nav-pills nav-sidebar flex-column"
      >
        <li class="nav-item nav-portal">
          <router-link 
            :to="{ name: 'home' }" 
            class="nav-link"
          >
            <em class="fas fa-home nav-icon" />
            <p>Início</p>
          </router-link>
        </li>

        <PortalNavArvoreMenu
          v-for="arvore in arvores"
          :key="arvore.codigo"
          :arvore="arvore"
        />
        
        <PortalNavOutros v-if="autenticado" />

        <PortalNavExternoMenu 
          v-if="externo.length > 0" 
          :externo="externo" 
        />
      </ul>

      <uspSkeleton 
        v-if="!carregado"
        :quantidade="20"
      />
    </aside>
    <div 
      class="overlay" 
      @click="alternarBarraLateral"
    />
  </div>
</template>
<script>
import { Getters } from "@/portal/store/types";
import { mapGetters } from "vuex";
import PortalNavArvoreMenu from "../layout/nav/PortalNavArvoreMenu";
import PortalNavExternoMenu from "../layout/nav/PortalNavExternoMenu";
import PortalNavOutros from '../layout/nav/PortalNavOutros';
import BarraLateral from "@/utils/BarraLateral"; 

export default {
  name: "PortalSidebar",

  components: {
    PortalNavArvoreMenu,
    PortalNavExternoMenu,
    PortalNavOutros
  },

  computed: {
    ...mapGetters({
      autenticado: 'auth/autenticado',
      arvores: Getters.NAVEGACAO.ARVORES,
      carregado: Getters.NAVEGACAO.STATUS_CARREGADO,
      externo: Getters.NAVEGACAO.EXTERNO,
    }),
  },

  methods: {
    alternarBarraLateral: () => {
      BarraLateral.alternarBarraLateral();
    },
  },
};
</script>
<style lang="scss">
@import "./src/componentes/estilos/temas.scss";
$larguraMax: 16.625rem;
$larguraMin: 4.375rem;

/* Configurações gerais */

body.efeito-suavizar .usp-sidebar {
  transition: ease-in-out 0.5s;
}

.usp-sidebar {
  box-shadow: 0 5px 20px 0 rgba(69, 67, 96, 0.05);
  bottom: 0;
  left: 0;
  position: fixed;
  overflow-x: hidden;
  //overflow-y: auto;
  padding: 10px;
  //padding-left: 0px!important;
  //padding-right: 0px;
  /*z-index: 10;*/
  z-index: 1030;
  display: none;
  ul {
    width: 100%;
    &:first-child {
      > li {
        font-weight: 500;
        //margin-top: 5px;
        li {
          font-weight: 400;
        }
      }
    }
    li {
      border-radius: 0rem;
      overflow: hidden;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      max-width: 15.375rem;
      width: 100%;
      background: none;
      .nav-link-treeview {
        font-weight: 500;
      }
      a {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;

        display: flex;
        overflow-wrap: break-word;
        align-items: center;
        position: relative;
      }
      &:hover:not(.caret-down) {
        transition: 0.2s;
      }

      .right,
      .p > .right {
        position: absolute;
        right: 00rem;
        height: 0.8rem;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      .caret {
        display: none;
        cursor: pointer;
        user-select: none;
      }
      .caret-down {
        transition: 0.2s;
        border-bottom-left-radius: 0rem;
        border-bottom-right-radius: 0rem;
        .caret-down {
          border-top-left-radius: 0rem;
          border-top-right-radius: 0rem;
        }
        + ul li {
          margin-top: 0rem;
        }
      }

      i.icone-arvore, em.icone-arvore {
        color: #333;
      }

      i.fa.fa-minus, em.fa.fa-minus {
        font-size: 0.4rem;
      }

      &:first-child {
        margin-top: 0rem;
      }

      &.header {
        padding: 0.625rem 0.3125rem 0.625rem 0.9375rem;
        font-size: 0.9rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.0625rem;
        visibility: visible;
        border-bottom: 0.0625rem dotted #ddd;
        border-radius: 0rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.7rem;
        white-space: nowrap;
        &:hover {
          background: #fff;
        }
      }

      p {
        font-size: 0.85rem;
        max-width: 8rem;
        margin: 0;
        margin-left: 0.625rem;
      }
      i, em {
        vertical-align: middle;
        text-align: center;
        float: left;
        margin-right: 0.5rem !important;
        width: 20px;
      }

      /* Submenus */
      


      ul.nested {
        padding-left: 2rem;
        display: none;
        list-style-type: none;
        transition: all 0.5s;

        &:first-child{
          padding-left: 2rem;
        }


        li:first-child {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
        li {
          &:hover {
            border-left: 0px !important;
          }
        }

        &.active {
          padding-bottom: 0.5rem;
          display: block; //testar aqui o delay
        }
        a i, a em {
          margin-right: 1rem;
        }
        i, em {
          color: #555;
        }
        a > i:first-child, a > em:first-child {
          padding-left: 10px;
        }

        .nested {
          padding-left: 0.625rem;
          //padding-left: 1.925rem;
          padding-bottom: 1rem;

          a{
            padding-right: 4rem;
          }
        }
      }
    }
  }

  /* Scroll personalizado */
  &::-webkit-scrollbar {
    width: 0.325rem;
  }
  &::-webkit-scrollbar-track {
    background: #fcfcfc;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/* Com menu expandido */
body.usp-menu-expandido {
  .usp-sidebar {
    min-width: $larguraMax;
    max-width: $larguraMax;
    display: block;
    overflow-y: auto;
    li > a {
      padding-left: 20px;
    }
  }
}

/* Sem menu expandido */
body:not(.usp-menu-expandido) {
  &.efeito-suavizar {
    .usp-sidebar {
      transition: ease-in-out 0.5s;
    }
  }
  .usp-sidebar {
    min-width: 4.375rem;
    max-width: 4.375rem;
    display: block;
    overflow-y: hidden;
    padding: 10px;
    &:not(:hover) {
      .caret-down {
        background: none;
      }
      li.header {
        visibility: hidden;
      }
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s;
        a {
          padding-left: 0px;

          color: #333;
          background: transparent !important;
          box-shadow: none !important;
          margin-bottom: 0px !important;
          &.caret-down {
            border: 0px !important;
            i, em {
              color: #333;
            }
          }
          i, em {
            margin-right: 0px;
          }
        }
      }
    }
  }
  .usp-sidebar p {
    visibility: hidden;
    margin-left: -18.75rem !important;
  }
}

.overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(160, 155, 155, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .fixed-top {
    z-index: 900;
  }
  .navbar-brand {
    margin-right: 0rem;
  }
  body:not(.usp-menu-expandido) .usp-sidebar {
    display: none;
  }
  .usp-sidebar {
    display: block;
    position: fixed;
    z-index: 999;
    transition: all 0.5s ease-in-out;
    top: 0;
  }
  .usp-menu-expandido {
    .overlay {
      display: block;
      opacity: 1;
      top: 0;
      transition: all 0.5s ease-in-out;
      z-index: 1;
    }
    .usp-sidebar {
      overflow-y: scroll !important;
    }
  }
}

@media (min-width: 769px) {
  .usp-menu-expandido {
    .portal-usp-conteudo {
      max-width: calc(100% - #{$larguraMax});
      margin-left: $larguraMax;
    }
    .usp-sidebar {
      transition: all 0.5s ease-in-out;
      &:hover {
        overflow-y: auto;
      }
      p,
      .caret {
        visibility: visible;
        transition: all 0.5s ease-in-out;
        transition-delay: 0.1s;
        margin-left: 10px !important;
      }
    }
    .usp-logo svg {
      margin-left: 1.5rem !important;
    }
  }

  body:not(.usp-menu-expandido) {
    .portal-usp-conteudo {
      margin-left: $larguraMin;
      max-width: calc(100% - #{$larguraMin});
    }
    .usp-logo svg {
      margin-left: 4rem !important;
    }
    .usp-sidebar {
      &:not(:hover) .nested {
        display: none !important;
        transition: all 1s ease-in-out;
        /* overflow-y: hidden; */
      }
      &:hover {
        min-width: $larguraMax;
        max-width: $larguraMax;
        position: fixed;
        transition: all 0.5s ease-in-out;
        overflow-y: auto;
        p,
        .caret {
          visibility: visible;
          transition: all 0.5s ease-in-out;
          margin-left: 10px !important;
        }
      }
      p {
        white-space: pre-line;
        vertical-align: middle;
      }
    }
  }
}

/* Temas */
@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .usp-sidebar {
      background: map-get($tema, "usp-menu-lateral-cor-de-fundo");
      top: map-get($tema, "usp-barra-superior-altura-total");
      height: calc(100vh - map-get($tema, "usp-barra-superior-altura"));

      /* BLOCO NOVO */
      > ul > li {
        &:not(:first-child) > a:first-child {
          border-top: map-get($tema, "usp-menu-lateral-borda-superior");
        }
        > .caret-down {
          &:first-child {
            background: map-get($tema, "usp-menu-lateral-arvore-fundo");
            border-radius: 0.95rem !important;
            border: map-get($tema, "usp-menu-lateral-arvore-topo");

            box-shadow: map-get($tema, "usp-menu-lateral-arvore-sombra");
            margin-bottom: 0.85rem;
          }

          i,
          em,
          p {
            color: map-get($tema, "usp-menu-lateral-arvore-cor");
          }
        }
      }

      ul {
        li {
          color: map-get($tema, "usp-menu-lateral-cor-do-icone");

          a:hover:not(.caret-down), a:focus:not(.caret-down) {
            i, em {
              color: map-get(
                $tema,
                "usp-menu-lateral-arvore-item-destaque"
              ) !important;
            }

            p {
              opacity: 0.8;
            }
          }
          p {
            color: map-get($tema, "usp-menu-lateral-cor-do-texto");
          }
          i, em {
            color: map-get($tema, "usp-menu-lateral-cor-do-icone");
          }
          /* Submenus */
          ul.nested {
            a > i:first-child,
            a > em:first-child {
              color: map-get($tema, "usp-menu-lateral-arvore-item-destaque");
            }

            a.nav-link-treeview {
              i, em {
                color: map-get($tema, "usp-menu-lateral-arvore-item-destaque");
              }
            }

            a:hover:not(.caret-down), a:focus:not(.caret-down) {
            opacity: .5
            }
          }
        }
      }
      &::-webkit-scrollbar-thumb {
        background: map-get(
          $tema,
          "usp-menu-lateral-barra-de-rolagem-cor-de-fundo"
        );
      }
    }
  }
}

/* Animação */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
