export default class SetorProServ {

  constructor(codset, codund, nomabvset, nomset) {
      this.codset = codset
      this.codund = codund
      this.nomabvset = nomabvset
      this.nomset = nomset
  }

  getCodigo(){
      return this.codset
  }

  getTexto(){
      return `${this.nomabvset} - ${this.nomset}`
  }

  toString(){
      return this.nomset ? this.getTexto() : null
  }

  static fromJSON(json={}){
    return new SetorProServ(
      json.codset,
      json.codund,
      json.nomabvset,
      json.nomset
    )
  }
}