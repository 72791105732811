<!-- eslint-disable vue/no-v-html -->
<template>
  <uspModal
    ref="alterarEmailTelefone"
    titulo="Atualização de dados de contato"
    max-height="95%"
  >
    <template #body>
      <div 
        class="alert alert-warning"
        role="alert"
      >
        Para se manter informado através dos comunicados que são encaminhados à comunidade USP, por favor, mantenha seus dados de contato sempre atualizados. Você pode atualizar algum(ns) de seus dados através do formulário abaixo:
      </div>
      <uspAlerta
        v-show="!!alerta.mensagem"
        class="col-lg-10 offset-lg-1"
        :variante="alerta.variante"
      >
        <span v-html="alerta.mensagem" />
      </uspAlerta>
      <form>
        <div
          v-show="statusPodeAtualizarEmailPrincipal == 'S'"
          class="form-row" 
        >
          <div class="form-group col-md-6">
            <label>E-mail cadastrado</label>
            <input 
              v-model="emailCadastrado"
              type="email"
              class="form-control"
              disabled 
            >
          </div>
          <div class="form-group col-md-6">
            <label>E-mail atualizado</label>
            <input 
              v-model="emailAtualizado"
              type="email"
              class="form-control"
              placeholder=""
            >
          </div>
        </div>
        <div 
          v-show="statusPodeAtualizarEmailPrincipal == 'S'"
          class="form-row text-center"
        >
          <div class="form-group col-md-12">
            <button 
              type="button"
              :disabled="processando"
              class="btn btn-primary"
              @click.prevent="atualizarEmail()"
            >
              <em 
                class="fa fa-envelope mr-2"
                aria-hidden="true"
              />
              Atualizar meu e-mail
            </button>
          </div>
        </div>
      </form>
      <form class="mt-3">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label>Telefone celular cadastrado</label>
            <input
              v-model="celularCadastrado"
              type="email"
              class="form-control"
              disabled
            >
          </div>
        </div>
        <div class="form-row text-center">
          <div class="form-group col-md-12">
            <button 
              type="button"
              :disabled="processando"
              class="btn btn-primary"
              @click.prevent="confirmarCelular()"
            >
              <em 
                class="fa fa-phone mr-2"
                aria-hidden="true"
              />
              Confirmar meu celular
            </button>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="inputPassword4">DDI</label>
            <input
              v-model="celularAtualizado.codddi"
              type="number"
              class="form-control"
              placeholder="DDI"
            >
          </div>
          <div class="form-group col-md-3">
            <label for="inputPassword4">DDD</label>
            <input
              v-model="celularAtualizado.codddd"
              type="number"
              class="form-control"
              placeholder="DDD"
            >
          </div>
          <div class="form-group col-md-6">
            <label for="inputPassword4">Número do celular</label>
            <input 
              v-model="celularAtualizado.numtel"
              type="text"
              class="form-control"
              placeholder="Formato: 999999999"
              maxlength="9"
            >
          </div>
        </div>
        <div class="form-row text-center">
          <div class="form-group col-md-12">
            <button 
              type="button"
              :disabled="processando" 
              class="btn btn-primary"
              @click.prevent="atualizarCelular()" 
            >
              <em
                class="fa fa-phone mr-2" 
                aria-hidden="true"
              />
              Atualizar meu celular
            </button>
          </div>
        </div>
      </form>
    </template>
  </uspModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const validarEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export default {
  name: "AlterarEmailTelefone",
  
  data() {
    return {
        emailCadastrado: "",
        emailAtualizado: "",
        celularCadastrado: "",
        celularCadastradoObjeto: {},
        statusPodeAtualizarEmailPrincipal: "",
        celularAtualizado: {
            'codddi': '55',
            'codddd': '11',
            'numtel': ''
        },
        alerta: {
            'mensagem': '',
            'variante': 'perigo'
        },
        processando: false
    };
  },

  computed: {
      ...mapGetters({
          necessitaAtualizarEmailCelular: "auth/necessitaAtualizarEmailCelular"
      })
  },

  watch: {
    necessitaAtualizarEmailCelular(status){
        const self = this;
        if(status){
            this.obterDados();
            self.podeAtualizarEmailPrincipal().then((resposta) => {
                self.statusPodeAtualizarEmailPrincipal = resposta.podeAtualizarEmailPrincipal;
            });
            this.$refs['alterarEmailTelefone'].openModal();
        }
    }
  },

  mounted() {
  },

  methods: {
    ...mapActions({
      obter: "auth/obterEmails",
      salvar: "auth/alterarEmail",
      obterCelular: "auth/obterCelular",
      salvarCelular: "auth/salvarCelular",
      podeAtualizarEmailPrincipal: "auth/podeAtualizarEmailPrincipal"
    }),
    obterDados() {
      const self = this;
      self.obter().then((resposta) => {
        this.emailCadastrado = resposta.principal;
      });
      self.obterCelular().then((resposta) => {
        self.celularCadastradoObjeto = resposta;
        self.celularCadastrado = resposta.numtelfmt;
      });
    },
    atualizarEmail() {
        const self = this;
        self.alerta = {'mensagem': ''};
        if(self.emailAtualizado.trim() === self.emailCadastrado.trim()) {
            self.alerta = {'mensagem': 'Não há necessidade de atualizar o seu e-mail caso seja o mesmo', 'variante': 'aviso'}
        } else if (validarEmail(self.emailAtualizado)) {
            self.processando = true;
            self.salvar({tipo: "P", email: self.emailAtualizado}).then((resposta) => {
                self.alerta = {'mensagem': resposta.mensagem, 'variante': resposta.retorno === "erro" ? "perigo" : "sucesso"};
            }).catch((erro) => {
                self.alerta = {'mensagem': erro.mensagem, 'variante': 'perigo'};
            }).finally(() => (self.processando = false));
        } else {
            self.alerta = {'mensagem': "E-mail inválido! Por favor verifique se está preenchido corretamente.", 'variante': 'perigo'};
        }
    },
    confirmarCelular() {
        const self = this;
        self.alerta = {'mensagem': ''};
        self.processando = true;
        self.salvarCelular(self.celularCadastradoObjeto).then((resposta) => {
            self.alerta = {'mensagem': resposta.mensagem, 'variante': resposta.retorno === "erro" ? "perigo" : "sucesso"};
            self.obterDados();
        }).catch((erro) => {
            self.alerta = {'mensagem': erro.mensagem, 'variante': 'perigo'};
        }).finally(() => (self.processando = false));
    },
    atualizarCelular() {
        const self = this;
        self.alerta = {'mensagem': ''};
        self.processando = true;
        self.salvarCelular(self.celularAtualizado).then((resposta) => {
            self.alerta = {'mensagem': resposta.mensagem, 'variante': resposta.retorno === "erro" ? "perigo" : "sucesso"};
            self.obterDados();
        }).catch((erro) => {
            self.alerta = {'mensagem': erro.mensagem, 'variante': 'perigo'};
        }).finally(() => (self.processando = false));
    }
  },
};
</script>

<style scoped lang="scss">
.ativo {
  background: orange;
}

.list-group-item {
  flex-grow: 1;
  flex-basis: 100%;
}

legend {
  margin-bottom: 2rem;
  font-size: 1.1rem;
  font-weight: 600;
}
</style>
