export const Actions = {
    ANDAMENTOS: {
        ANEXAR: 'ANEXAR_ANDAMENTOS',
        ASSINAR: 'ASSINAR_ANDAMENTOS',
        BUSCAR: 'BUSCAR_ARQUIVOS_ANDAMENTOS',
        CARREGAR_ARQUIVO: 'CARREGAR_ARQUIVO_ANDAMENTOS',
        CRIAR_DOCUMENTO: 'CRIAR_DOCUMENTO_ANDAMENTOS',
        CRIAR_INFORMACAO: 'CRIAR_INFORMACAO_ANDAMENTOS',
        GERAR_ANEXO: 'GERAR_ANEXO_ANDAMENTOS',
        LISTAR: 'LISTAR_ANDAMENTOS',
        REMOVER_ARQUIVO: 'REMOVER_ARQUIVO_ANDAMENTOS',
        REMOVER_NOVA_OCORRENCIA: 'REMOVER_NOVA_OCORRENCIA_ANDAMENTOS',
        REMOVER_OCORRENCIA: 'REMOVER_OCORRENCIA_ANDAMENTOS',
        SALVAR_OCORRENCIA: 'SALVAR_OCORRENCIA_ANDAMENTOS',
    },
    ESTRUTURA: {
        ASSUNTOS: 'LISTAR_ESTRUTURA_ASSUNTOS',
        ESTACOES_PESSOA: 'LISTAR_ESTRUTURA_ESTACOES_PESSOA',
        ESTACOES_UNIDADE: 'LISTAR_ESTRUTURA_ESTACOES_UNIDADE',
        ESTACOES_USUARIO: 'LISTAR_ESTRUTURA_ESTACOES_USUARIO',
        TIPO_CONFERENCIA: 'LISTAR_ESTRUTURA_TIPOS_CONFERENCIA',
        TIPO_DOCUMENTO: 'LISTAR_ESTRUTURA_TIPOS_DOCUMENTO',
        UNIDADE: 'LISTAR_ESTRUTURA_UNIDADE',
        MEMBROS: 'LISTAR_ESTRUTURA_MEMBROS',
    },
    PROCESSOS: {
        ARQUIVAR: 'DEVOLVER_ARQUIVAR',
        CANCELAR_SAIDA: 'CANCELAR_SAIDA_PROCESSO',
        CARREGAR: 'CARREGAR_PROCESSO',
        CARREGAR_ACOES: 'CARREGAR_ACOES_PROCESSO',
        CARREGAR_DADOS: 'CARREGAR_DADOS_PROCESSO',
        DAR_SAIDA: 'DAR_SAIDA_PROCESSO',
        DESARQUIVAR: 'DEVOLVER_DESARQUIVAR',
        DEVOLVER: 'DEVOLVER_PROCESSO',
        ENTRAR: 'ENTRAR_PROCESSO',
        LISTAR: 'LISTAR_PROCESSOS',
        SALVAR: 'SALVAR_PROCESSO',
    }
};

export const Getters = {
    ANDAMENTOS: {
        ASSINATURA: 'GET_ANDAMENTOS_ASSINATURA',
        CARREGANDO: 'GET_ANDAMENTOS_CARREGANDO',
        DOCUMENTOS: 'GET_ANDAMENTOS_DOCUMENTOS',
        ERROS: 'GET_ANDAMENTOS_ERROS',
        EXTENCOES_ACEITAS: 'GET_ANDAMENTOS_EXTENCOES_ACEITAS',
        LISTA: 'GET_ANDAMENTOS_LISTA',
        NOVAS_OCORRENCIAS: 'GET_ANDAMENTOS_NOVAS_OCORRENCIAS',
        ULTIMO_ANDAMENTO: 'GET_ANDAMENTOS_ULTIMO_ANDAMENTO',
        STATUS_EDICAO: 'GET_ANDAMENTOS_STATUS_EDICAO'
    },
    ESTRUTURA: {
        ASSUNTO_CPLOBG: 'GET_ESTRUTURA_ASSUNTO_CPLOBG',
        ASSUNTOS: 'GET_ESTRUTURA_ASSUNTOS',
        ESTACOES_PESSOA: 'GET_ESTRUTURA_ESTACOES_PESSOA',
        ESTACOES_UNIDADE: 'GET_ESTRUTURA_ESTACOES_UNIDADE',
        ESTACOES_USUARIO: 'GET_ESTRUTURA_ESTACOES_USUARIO',
        TIPO_CONFERENCIA: 'GET_ESTRUTURA_TIPOS_CONFERENCIA',
        TIPO_DOCUMENTO: 'GET_ESTRUTURA_TIPOS_DOCUMENTO',
        UNIDADE: 'GET_ESTRUTURA_TIPOS_UNIDADE'
    },
    PROCESSOS: {
        ACAO: 'GET_PROCESSOS_ACAO',
        ACAO_AVISO: 'GET_PROCESSOS_ACAO_AVISO',
        ACOES: 'GET_PROCESSOS_ACOES',
        ASSUNTO: 'GET_PROCESSOS_ASSUNTO',
        CARREGANDO: 'GET_PROCESSOS_CARREGANDO',
        CONFIRMAR: 'GET_PROCESSOS_CONFIRMAR',
        CONFIRMAR_ERRO: 'GET_PROCESSOS_CONFIRMAR_ERRO',
        CONFIRMAR_SALVANDO: 'GET_PROCESSOS_CONFIRMAR_SALVANDO',
        DADOS: 'GET_PROCESSOS_DADOS',
        DEVOLVER: 'GET_PROCESSOS_DEVOLVER',
        ERRO_DADOS: 'GET_PROCESSOS_ERRO_DADOS',
        LISTA: 'GET_PROCESSOS_LISTA',
        OBTENDO_ACOES: 'GET_PROCESSOS_OBTENDO_ACOES',
        OBTENDO_DADOS: 'GET_PROCESSOS_OBTENDO_DADOS'
    },
};

export const Mutations = {
    ANDAMENTOS: {
        ANEXO: 'SET_ANDAMENTOS_ANEXO',
        ASSINATURA: 'SET_ANDAMENTOS_ASSINATURA',
        CARREGANDO: 'SET_ANDAMENTOS_CARREGANDO',
        DOCUMENTO_GERADO: 'SET_ANDAMENTOS_DOCUMENTO_GERADO',
        DOCUMENTOS: 'SET_ANDAMENTOS_DOCUMENTOS',
        ERRO: 'SET_ANDAMENTOS_ERRO', 
        ERROS: 'SET_ANDAMENTOS_ERROS', 
        LIMPAR_DOCUMENTOS: 'SET_ANDAMENTOS_LIMPAR_DOCUMENTOS',
        LISTA: 'SET_ANDAMENTOS_LISTA',
        NOVA_OCORRENCIA: 'SET_ANDAMENTOS_NOVA_OCORRENCIA',
        OCORRENCIA: 'SET_ANDAMENTOS_OCORRENCIA',
        REMOVER_NOVA_OCORRENCIA: 'SET_ANDAMENTOS_REMOVER_NOVA_OCORRENCIA',
        REMOVER_OCORRENCIA: 'SET_ANDAMENTOS_REMOVER_OCORRENCIA',
        STATUS_ASSINATURA: 'SET_ANDAMENTOS_STATUS_ASSINATURA'
    },
    ESTRUTURA: {
        ASSUNTOS: 'SET_ESTRUTURA_ASSUNTOS',
        ESTACOES_PESSOA: 'SET_ESTRUTURA_ESTACOES_PESSOA',
        ESTACOES_UNIDADE: 'SET_ESTRUTURA_ESTACOES_UNIDADE',
        ESTACOES_USUARIO: 'SET_ESTRUTURA_ESTACOES_USUARIO',
        NOVO_TIPO_DOCUMENTO: 'SET_ESTRUTURA_NOTO_TIPO_DOCUMENTO',
        TIPO_CONFERENCIA: 'SET_ESTRUTURA_TIPOS_CONFERENCIA',
        TIPO_DOCUMENTO: 'SET_ESTRUTURA_TIPOS_DOCUMENTO',
        STATUS_TIPO_CONFERENCIA: 'SET_ESTRUTURA_STATUS_TIPOS_CONFERENCIA',
        STATUS_TIPO_DOCUMENTO: 'SET_ESTRUTURA_STATUS_TIPOS_DOCUMENTO',
        UNIDADE: 'SET_ESTRUTURA_UNIDADE',
    },
    PROCESSOS: {
        ACAO_AVISO: 'SET_PROCESSOS_ACAO_AVISO',
        ACOES: 'SET_PROCESSOS_ACOES',
        CARREGANDO: 'SET_PROCESSOS_CARREGANDO',
        CONFIRMAR: 'SET_PROCESSOS_CONFIRMAR',
        CONFIRMAR_ERRO: 'SET_PROCESSOS_CONFIRMAR_ERRO',
        CONFIRMAR_SALVANDO: 'SET_PROCESSOS_CONFIRMAR_SALVANDO',
        ERRO: 'SET_PROCESSOS_ERRO',
        ERRO_OBTER: 'SET_PROCESSOS_ERRO_OBTER',
        ITEM: 'SET_PROCESSOS_ITEM',
        LISTA: 'SET_PROCESSOS_LISTA',
        OBTENDO: 'SET_PROCESSOS_OBTENDO',
    }
};