<template>
  <button
    :class="estilo" 
    :disabled="desabilitado || carregando" 
    :data-toggle="toggle ? 'button' : false"
    @click="clicar($event)"
  >
    <slot />
    <span
      v-if="carregando"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
    <em 
      v-if="icone && !carregando" 
      :class="icone" 
    /> 
    {{ carregando ? textoCarregando || texto : texto }}
  </button>
</template>

<script>
import { BOTAO_VARIANTES, BOTAO_TAMANHOS } from "./constantes";

export default {
  name: 'UspBotao',
  props: {
    /**
     * Marca se o estado do botão fica como se estivesse carregando, mudando o texto e o ícone mostrado.
     */
    carregando: {
      type: Boolean,
      default: false
    },
    /**
     * Texto a ser renderizado dentro do componente.
     */
    texto: {
      type: String,
      required: true,
    },
    /**
     * O ícone que será mostrado dentro do botão.
     */
    icone: {
      type: String,
      default: '',
    },
    /**
     * Marca se o estilo do botão terá com o contorno e o preenchimento vazio.
     */
    contorno: {
      type: Boolean,
      required: false,
    },
    /**
     * Marca se o botão deve estar desativado, pela propriedade disabled da tag button.
     */
    desabilitado: {
      type: Boolean,
      required: false,
    },
    /**
     * O estilo que o botão irá utilizar.
     */
    variante: {
      type: String,
      default: "primario",
      validator: (value) => {
        return (
          BOTAO_VARIANTES.map(function(e) {
            return e.pt;
          }).indexOf(value) !== -1
        );
      },
    },
    /**
     * A classe que marca o tamanho do botão
     */
    tamanho: {
      type: String,
      default: "",
      validator: (value) => {
        return (
          BOTAO_TAMANHOS.map(function(e) {
            return e.pt;
          }).indexOf(value) !== -1
        );
      },
    },
    /**
     * O texto para ser mostrado dentro do botão quando o estado de carregando for true.
     */
    textoCarregando: {
      type: String,
      default: ''
    },
    /**
     * Marca se o botão tem a funcionalidade de liga/desliga.
     */
    toggle:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    estilo() {
      const tipoBS = BOTAO_VARIANTES.find((elemento) => elemento.pt == this.variante).en
      return `btn btn${this.estiloContorno}-${tipoBS}${this.estiloTamanho ? " "+this.estiloTamanho : ""}`
    },
    estiloTamanho() {
      const tamanhoBS = BOTAO_TAMANHOS.find(
        (elemento) => elemento.pt == this.tamanho
      ).en;
      return this.tamanho && this.tamanho != "normal" ? `btn-${tamanhoBS}` : "";
    },
    estiloContorno() {
      return this.contorno ? "-outline" : "";
    },
  },
  methods: {
    clicar: function(event) {
      this.$emit('clicar', event)
    },
  },
};
</script>

<style lang="scss">
@import "../../estilos/cores";
$btn-border-radius: 2rem;

/* Botões */
.btn {
  vertical-align: middle;
  user-select: none;
  will-change: transform;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: $btn-border-radius!important;
  align-items: center;
  display: inline-flex;
  .fas {
    margin-right: 0.3rem;
  }
  /*&.btn-link {
    color: $primary;
  }*/
  &.btn-warning,
  &.btn-info {
    color: #fff !important;
  }
}
</style>
