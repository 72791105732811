export default class Assunto {

    constructor(codasu, dscasu, cplobg) {
        this.codasu = codasu
        this.dscasu = dscasu
        this.cplobg = cplobg
    }

    getCodigo(){
        return this.codasu
    }

    getTexto(){
        return `${this.codasu} - ${this.dscasu}`
    }

    toString(){
        return this.getTexto()
    }

    static buildFromJson(json={}){
        return new Assunto(
            json.codasu,
            json.dscasu, 
            json.cplobg
        )
    }
}