export default class PerfilMenu {

    constructor(nivel, nome, codigoArea) {
        this.nivel = nivel;
        this.nome = nome;
        this.codigoArea = codigoArea;
    }

    static build(json={}){
        return new PerfilMenu(json.nivel, json.nome, json.codigoArea);
    }

}