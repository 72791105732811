export default class TipoDocumento {

    constructor(codetrvlrpdl, dscetrvlrpdl, codtipetrpdl, ordaptetrvlr) {
        this.codetrvlrpdl = codetrvlrpdl
        this.dscetrvlrpdl = dscetrvlrpdl
        this.codtipetrpdl = codtipetrpdl
        this.ordaptetrvlr = ordaptetrvlr
    }

    getCodigo(){
        return this.codetrvlrpdl
    }

    getTexto(){
        return this.dscetrvlrpdl || ''
    }

    static buildFromJson(json={}){
        return new TipoDocumento(
            json.codetrvlrpdl,
            json.dscetrvlrpdl,
            json.codtipetrpdl,
            json.ordaptetrvlr
        )
    }
}