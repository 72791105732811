<template>
  <div 
    v-clicar-fora="fechar"
    class="usp-autocompletar"
  >
    <input
      :id="id"
      v-model="termo"
      :name="name"
      :placeholder="placeholder"
      type="search"
      class="form-control"
      :class="temResultados"
      :disabled="desabilitado"
      :required="obrigatorio"
      autocomplete="off"
      @focus="foco"
      @keydown.esc.prevent="fechar"
      @keydown.enter.prevent="enter"
      @keydown.down.prevent="baixo"
      @keydown.up.prevent="cima"
      @input="trocar"
    >
    <div 
      v-show="mostrar"
      class="list-group list-group-sm"
    >
      <a
        v-for="(item, index) in itensFiltrados"
        :key="item.getCodigo()"
        class="list-group-item list-group-item-action flex-column align-items-start"
        target="_blank"
        :class="{ active: eAtivo(index) }"
        @click="selecionar(item)"
      >
        <div class="w-100 d-flex justify-content-between">
          {{ item.getTexto() }}
        </div>
      </a>
    </div>
    <span class="invalid-feedback">{{ erroValidacao }}</span>
  </div>
</template>

<script>
export default {
  name: 'UspAutocompletar',

  props: {
    desabilitado: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    itens: {
      type: Array,
      required: true,
    },
    abreNoFoco: {
      type: Boolean,
      default: false
    },
    quantidadeCaracteres: {
      type: Number,
      required: false,
      default: 0,
    },
    inicial: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    obrigatorio:{
      type: Boolean,
      default: false
    },
    erroValidacao: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      atual: 0,
      mostrar: false,
      termo: this.inicial || ''
    };
  },

  computed: {
    itensFiltrados() {
      const self = this
      return self.itens.filter((item) => {
        return item.getTexto().toLowerCase().includes(self.termo.toLowerCase());
      });
    },

    temResultados: function() {
      return this.itensFiltrados.length > 0 && this.mostrar ? "resultados" : "";
    },
  },

  watch: {
    itens(){
      this.termo = this.inicial || ''
    },
    inicial(val){
      if(val) this.termo = val
    }
  },
  methods: {
    baixo() {
      if (this.atual < this.itensFiltrados.length - 1) this.atual++;
    },
    cima() {
      if (this.atual > 0) this.atual--;
    },
    eAtivo(indice) {
      return indice === this.atual;
    },
    enter() {
      const item = this.itensFiltrados[this.atual]
      this.mostrar = false;
      this.termo = item.getTexto();
      this.$emit('input', item.getCodigo());
      this.$emit('change', item.getCodigo());
    },
    fechar() {
      this.mostrar = false;
      this.atual = -1;
    },
    foco(){
      if(this.abreNoFoco){
        this.mostrar = true;
        this.atual = 0;
      }
    },
    trocar() {
      this.$emit('input', this.termo);
      if (!this.mostrar && this.termo.length >= this.quantidadeCaracteres) {
        this.mostrar = true;
        this.atual = 0;
      }
    },
    selecionar(item) {
      this.termo = item.getTexto();
      this.mostrar = false;
      this.$emit('input', item.getCodigo());
      this.$emit('change', item.getCodigo());
    },
  },

};
</script>
<style scoped lang="scss">
  @import "./src/componentes/estilos/temas.scss";

  div.list-group {
    position: absolute;
    z-index: 99999;
  }

  @each $tema in $temas {
    $elemento: map-get($tema, "elemento");
    #{$elemento} {
      .list-group {
        .list-group-item {
          background: map-get($tema, "usp-elemento-cor-de-fundo") !important;
          color: map-get($tema, "usp-elemento-cor-do-texto") !important;
        }
        .list-group-item:hover {
          background: #1094ab !important;
          color: #FFF !important;
          div {
            background: transparent !important;
            font-weight: bold;
          }
        }
      }
    }
  }

</style>