<template>
  <section>
    <div class="container-fluid">
      <uspCartao 
        titulo="Cadastrar Nova Senha"
        :fechavel="false"
      >
        <template #corpo>
          <uspAlerta 
            v-if="aviso"
            class="col-lg-10 offset-lg-1"
            :variante="aviso.tipo"
          >
            <div>{{ aviso.mensagem }}</div>
          </uspAlerta>
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-5 offset-lg-1">
                <form 
                  novalidate
                  @submit.prevent=""
                >
                  <div class="form-group">
                    <label 
                      class="sr-only"
                      for="senhaNova"
                    >
                      Senha
                    </label>
                    <input 
                      id="senhaNova"
                      v-model="senhaNova"
                      type="password"
                      name="senhaNova"
                      class="form-control"
                      placeholder="Senha"
                    >
                  </div>
                  <div class="form-group">
                    <label 
                      class="sr-only"
                      for="senhaNovaConfirmada"
                    >
                      Confirmar Senha
                    </label>
                    <input
                      id="senhaNovaConfirmada"
                      v-model="senhaNovaConfirmada"
                      type="password"
                      name="senhaNovaConfirmada"
                      class="form-control"
                      placeholder="Confirmar Senha"
                    >
                  </div>
                  <uspBotao
                    variante="sucesso"
                    tamanho="bc"
                    class="mb-2"
                    icone="fas fa-check"
                    texto="Cadastrar Senha"
                    :carregando="carregando"
                    :desabilitado="!valido"
                    texto-carregar="Salvando Senha..."
                    @clicar="cadastrarSenha"
                  />
                </form>
              </div>
              <div class="col-lg-5">
                <ul class="regras">
                  <li class="header">
                    A senha deve:
                  </li>
                  <li :class="{'valido':senhaNova && validoTamanhoMinimo, 'invalido':senhaNova && !validoTamanhoMinimo}">
                    Ter no mínimo 8 caracteres
                  </li>
                  <li :class="{'valido':senhaNova && validoNumero, 'invalido':senhaNova && !validoNumero}">
                    Possuir um número
                  </li>
                  <li :class="{'valido':senhaNova && validoLetra, 'invalido':senhaNova && !validoLetra}">
                    Possuir uma letra maiúscula e uma minúscula
                  </li>
                  <li :class="{'valido':senhaNova && validoEspecial, 'invalido':senhaNova && !validoEspecial}">
                    Possuir um caracter especial
                  </li>
                  <li :class="{'valido':senhaNova && validoVerificacao, 'invalido':senhaNova && !validoVerificacao}">
                    Ser confirmada no campo "Confirmar Senha"
                  </li>
                  <li class="header mt-2">Além disso, a senha:</li>
                  <li>
                    Não deve conter 3 ou mais caracteres consecutivos do seu nome ou do login.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </uspCartao>
    </div>
  </section>
</template>

<script>
import UsuarioServico from '../dominio/usuario/UsuarioServico'

export default {
  name: 'CadastrarSenha',

  data(){
    return{
      aviso: null,
      api: UsuarioServico.build({}),
      carregando: false,
      codigoCadastroSenha: this.$route.query.codigoCadastroSenha,
      msgSucessoDefault: "Senha cadastrada com sucesso",
      msgErroDefault: "Erro ao cadastrar senha",
      senhaNova: "",
      senhaNovaConfirmada: ""
    }

  },
  computed:{
    /**
     * Faz todas as validações em relação as senhas
     */
    valido(){
      return this.validoTamanhoMinimo &&
             this.validoNumero &&
             this.validoLetra &&
             this.validoEspecial &&
             this.validoVerificacao
    },
    /**
     * Valida que tenha ao menos 8 caracteres
     */
    validoTamanhoMinimo(){
      return (this.senhaNova || '').length >= 8
    },
    /**
     * Valida se existe ao menos um dígito (0-9).
     */
    validoNumero(){
      return /^(?=.*\d)/.test(this.senhaNova)
    },
    /**
     * Testa se tem letras maiúsculas e minúsculas.
     */
    validoLetra(){
      return /(?=.*[a-z])(?=.*[A-Z])/.test(this.senhaNova)
    },
    /**
     * Valida se existe ao menos um caracter especial.
     */
    validoEspecial(){
      return /^(?=.*[!@#$%^&*_])/.test(this.senhaNova)
    },
    /**
     * Valida se a senha digitada confere com a confirmação de senha.
     */
    validoVerificacao(){
      return this.senhaNovaConfirmada && this.senhaNova == this.senhaNovaConfirmada
    }
  },
  methods:{
    cadastrarSenha(){
      const self = this
      self.carregando = true
      // Execute reCAPTCHA with action "login".
      self.$recaptcha('login')
          .then(token => {
              self.api.cadastrarSenha({
                codigoCadastroSenha: self.codigoCadastroSenha,
                senhaNova: self.senhaNova,
                senhaNovaConfirmada: this.senhaNovaConfirmada,
                token: token
              }).then(resposta => {
                self.aviso = {
                  mensagem: resposta?.mensagem || this.msgSucessoDefault,
                  tipo: 'sucesso'
                }
              }).catch(erro => {
                  self.aviso = {
                    mensagem: erro?.mensagem || this.msgErroDefault,
                    tipo: 'perigo'
                  }
              })
              .finally(() => {
                self.carregando = false
              })
          })
    }
  }
}
</script>


<style>

  .regras{
    list-style: none;
  }

  .regras .header {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  .valido{
    color: green
  }

  .invalido{
    color: red
  }

</style>