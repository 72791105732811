import AvaliadorProServ from "../avaliadores/modelos/AvaliadorProServ"
import CargoProServ from "./CargoProServ"
import DetalheProServ from "./DetalheProServ"
import PessoaProServ from "./PessoaProServ"
import SetorProServ from "./SetorProServ"
import SituacaoProServ from "./SituacaoProServ"
import UnidadeProServ from "./UnidadeProServ"
import VertenteProServ from "./VertenteProServ"

export default class SolicitacaoProServ {

  constructor(codsolprs, anosolprs, undste, setste, dctrsp, vtesolprs, permissoes) {
      this.codsolprs = codsolprs
      this.anosolprs = anosolprs
      this.undste = undste
      this.setste = setste
      this.dctrsp = dctrsp
      this.vtesolprs = vtesolprs
      this.permissoes = permissoes
      this.andamentos = []
  }

  getCodigo(){
      return this.codigo
  }

  static fromJSON(json={}){
    const obj = new SolicitacaoProServ(
      json.codsolprs,
      json.anosolprs,
      UnidadeProServ.fromJSON(json.undste),
      json.setste.codset ? SetorProServ.fromJSON(json.setste) : null,
      PessoaProServ.fromJSON(json.dctrsp),
      VertenteProServ.fromJSON(json.vtesolprs),
      json.permissoes
      )
    if(json.avaliadores){
      obj.avaliadores = json.avaliadores.map(i => AvaliadorProServ.fromJSON(i))
    }
    obj.cgoprs= CargoProServ.fromJSON(json.cgoprs)
    obj.arecnhpsq = json.arecnhpsq
    obj.dtacad = json.dtacad
    obj.dtaultalt = json.dtaultalt
    obj.detalhes = DetalheProServ.fromJSON(json.detalhes)
    obj.sitprsatl = SituacaoProServ.fromJSON(json.sitprsatl)
    obj.media = parseFloat(json.media)
    obj.solori = json.solori ? { codsolprs: json.solori.codsolprs, anosolprs: json.solori.anosolprs } : null
    obj.soltro = json.soltro ? { codsolprs: json.soltro.codsolprs, anosolprs: json.soltro.anosolprs } : null
    return obj
  }
}