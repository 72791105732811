<template>
  <div>
    <PortalCardPaginado titulo="Meus Avisos" icone="fas fa-inbox" class="usp-tarefas portal-avisos-cartao" :lista="lista">
      <template slot="filtros" v-if="carregado && lista.length > 0">
         <form>
          <div class="form-group mb-2">
            <label for="filtro-avisos" class="sr-only">Buscar aviso...</label>
            <input
              type="text"
              id="filtro-avisos"
              class="form-control form-control-sm"
              v-model="filtro"
              placeholder="Buscar aviso..."
            />
          </div>
        </form>
      </template>
      <template slot="corpo">
        <div v-if="carregado && lista.length === 0" class="d-flex justify-content-center align-items-center flex-column">
          <img src="imgs/usp-inbox.svg" alt="Ilustração de uma caixa vazia">
          <p class="font-weight-bold text-center mt-5" v-show="carregado && lista.length === 0"> 
            &nbsp;
            Não há avisos no momento.
          </p>
        </div>
        <uspSkeleton v-if="!carregado">
        <div>
            <div class="w-25"></div>
            <div class="w-75"></div>
          </div>
          <div>
            <div class="w-25"></div>
            <div class="w-75"></div>
          </div>
          <div>
            <div class="w-25"></div>
            <div class="w-75"></div>
          </div>  
          <div>
            <div class="w-25"></div>
            <div class="w-75"></div>
          </div>  
          <div>
            <div class="w-25"></div>
            <div class="w-75"></div>
          </div>          
        </uspSkeleton>


      </template>
      <div class="tarefa info"
        :aria-controls="'avisos-'+conteudo.item.codigo"
        :class="{ lido: conteudo.item.isVisualizado() }"
        role="button"
        slot-scope="conteudo"
        @click="obter(conteudo.item)"
      >
        <div class="detalhes">
          <h6 class="area">{{ conteudo.item.nomeArea }}</h6>
        </div>
        <div class="conteudo">
          <h6 class="titulo">{{ conteudo.item.tipo }}</h6>
          <span class="descricao">{{ conteudo.item.assunto }}</span>
          <div class="datas">
            <span class="data">
              <i class="far fa-clock"></i>
              {{ conteudo.item.dataInicio }}
            </span>
          </div>
        </div>        
      </div>
      <template slot="acoes">
        <router-link
          v-if="historico"
          :to="{ name: 'avisos' }"
          tag="button"
          class="btn btn-sm btn-outline-primary"
        >
          <i class="fas fa-history"></i> Ver histórico
        </router-link>
      </template>
    </PortalCardPaginado>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters } from '../../../store/types';
import PortalCardPaginado from '../PortalCardPaginado.vue';

export default {
  name: 'PortalAvisosCartao',

  components: {
    PortalCardPaginado
  },

  computed: {
    ...mapGetters({
      avisos: Getters.NOTIFICACOES.AVISOS,
      carregado: Getters.NOTIFICACOES.STATUS_CARREGADO
    }),
    lista() {
      const self = this;
      if(!self.filtro) return self.avisos;
      return self.avisos.filter(av => {
        return av.assunto.toLowerCase().indexOf(self.filtro.toLowerCase()) !== -1
          || av.tipo.toLowerCase().indexOf(self.filtro.toLowerCase()) !== -1;
      });
    }
  },

  data() {
    return {
      filtro: '',
      historico: false
    };
  },

  methods: {
    ...mapActions({
      obter: Actions.NOTIFICACOES.OBTER_AVISO
    })
  },

};
</script>
<style scoped>
  .usp-tarefas .info {
    flex-flow: row wrap;
  }
  .usp-tarefas .info .conteudo {
    flex-basis: 75%;
  }
  .usp-tarefas .datas .data {
    font-size: 0.8rem;
    text-align: right;
    font-weight: 400;
    color: #999;
  }
  .usp-tarefas .form-group{
    margin: 0;
  }
</style>