<template>
  <div :class="cardClass">
    <div 
      :class="cardHeaderClass"
      style="flex-wrap: nowrap;"
    >
      <h5>
        <em
          v-if="icone" 
          :class="icone" 
        />
        <slot name="cabecalho">
          {{ titulo }}
        </slot>
      </h5>
      <div class="text-right">
        <a 
          v-if="fechavel"
          class="card-collapser"
          @click.prevent="colapsar"
        >
          <em :class="iconeColapsar" />
        </a>
        <a 
          v-if="removivel"
          class="card-remove ml-3"
          @click.prevent="remover" 
        >
          <em class="fas fa-times" />
        </a>
        <a
          v-if="imprimir"
          class="card-print btn btn-outline-primary ml-3"
          @click.prevent="imprimirCartao" 
          @mouseover="abrirImprimir = true"
          @mouseleave="abrirImprimir = false"
        >
          <em class="fas fa-print" />
          <span 
            class="texto"
            :class="{'mostrar-texto': abrirImprimir}"
          >
            Imprimir Cartão
          </span>
        </a>
      </div>
    </div>
    <div 
      v-show="exibir"
      :class="cardBodyClass"
    >
      <slot name="corpo" />
    </div>
    <div
      v-if="$slots.rodape || $slots.acoes"
      v-show="exibir"
      :class="cardFooterClass"
    >
      <slot name="rodape" />
      <div 
        v-if="$slots.acoes"
        class="acoes"
      >
        <slot name="acoes" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UspCartao',
  props: {
    titulo: {
      type: String,
      required: true,
    },
    icone: {
      type: String,
      default: ''
    },
    imprimir: {
      type: Boolean,
      default: false
    },
    fechavel: {
      type: Boolean,
      default: true,
    },
    fechado: {
      type: Boolean,
      default: false,
    },
    removivel: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Object,
      default: () => new Object()
    }
  },

  data() {
    const self = this;
    return {
      abrirImprimir: false,
      exibir: self.fechavel ? !self.fechado : true,
    };
  },

  computed: {
    iconeColapsar() {
      return this.exibir ? "fas fa-chevron-up" : "fas fa-chevron-down";
    },
    cardClass(){
      return 'card ' + (this.classes?.card || '')
    },
    cardHeaderClass(){
      return 'card-header ' + (this.classes?.cardHeader || '')
    },
    cardBodyClass(){
      return 'card-body ' + (this.classes?.cardBody || '')
    },
    cardFooterClass(){
      return 'card-footer text-muted ' + (this.classes?.cardFooter || '')
    }
  },

  methods: {
    colapsar() {
      this.exibir = !this.exibir;
      this.$emit('fechar', { exibir: this.exibir })
    },
    imprimirCartao(){
      this.$impressora.imprimirDiv(
        this.$el.querySelector('.card-header h5').textContent.trim(),
        this.$el.querySelector('.card-body').outerHTML
      )
    },
    remover() {
      this.$emit('remover');
    }
  },
};
</script>

<style lang="scss">
@import "../../estilos/temas";

/* Propriedades gerais não afetadas pelos temas */
$usp-cartao-espacamento: 1.3rem 1rem;
$cartao-borda-interna: 0.0625rem dotted $usp-cores-cinza-claro;

.card {
  margin-bottom: 2rem;
  border: 0px !important;
  .card-header {
    background: transparent;
    font-weight: 600;
    border-bottom: none;
    padding: $usp-cartao-espacamento;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0rem;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    > *:first-child {
      padding-left: 1rem;
      font-weight: bold;
      margin: 0;
      font-size: 1.25rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    p {
      margin-bottom: 0rem;
      font-weight: bold;
    }

    a.card-print {
      width: 3em;
    }

    a.card-print:hover {
      transition: all 400ms cubic-bezier(.62, .1, .5, 1);
      width: 12em;
    }

    a.card-print span.texto {
      transition: opacity 1000ms;
      opacity: 0;
      color: white;
      white-space: nowrap;
      font-size: 0;
      width: 0;
      margin: 0;
    }

    a.card-print span.texto.mostrar-texto {
      transition: opacity 700ms, width 1ms linear 270ms, font-size 1ms linear 270ms;
      opacity: 1;
      font-size: 1em;
      /* margin-left: 45px;
      font-size: 20px; */
      width: auto;
    }

  }

  .card-body {
    padding: 1rem;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &.subtitulo {
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
      }
    }

    .filtros {
      form {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 2;
        //margin-bottom: 2rem;
        div {
          flex-grow: 1;
          margin: 0.45rem;
          .form-control {
            width: 100%;
            border-radius: 1.5rem;
          }
        }
      }
    }
  }

  .card-footer {
    background: transparent;
    padding: $usp-cartao-espacamento;
    .acoes {
      text-align: right;
      .btn:not(:first-child) {
        margin-left: 0.2rem;
      }
    }
  }
}

/* Aplicação das variáveis por tema */
@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .card {
      background: map-get($tema, "usp-elemento-cor-de-fundo");
      box-shadow: map-get($tema, "usp-elemento-sombra");
      color: map-get($tema, "usp-elemento-cor-do-texto");
      border-radius: map-get($tema, "usp-elemento-borda-arredondada");
      .card-header {
        color: map-get($tema, "usp-elemento-cor-do-texto");
        > a {
          color: map-get($tema, "usp-elemento-cor-do-texto");
          &:hover {
            color: map-get($tema, "usp-elemento-cor-do-texto");
          }
        }
        > *:first-child {
          border-left: map-get($tema, "usp-elemento-titulo-borda");
          color: map-get($tema, "usp-elemento-cor-do-texto");
        }
      }
      .card-body {
        border-top: map-get($tema, "usp-elemento-borda-interna");
        //border-bottom: map-get($tema, "usp-elemento-borda-interna");
      }
      .card-footer {
        border-top: map-get($tema, "usp-elemento-borda-interna");
      }
    }
  }
}
</style>
