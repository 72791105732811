import { PortalServico } from "@/utils"
import { PROCESSOS_BASE_URL } from "./contantes"
import Andamento from "./modelos/Andamento"
import Arquivo from "./modelos/Arquivo"
import Ocorrencia from "./modelos/Ocorrencia"

/**
 * @type {AndamentoServico}
 */
 export default class AndamentoServico extends PortalServico {

    static build (options = {}) {
        return super.build({
            ...options,
            headers: {
                'X-Username': 'Portal'
            }
        })
    }

    alterarArquivo(numpro, { codarqpdl, codetrdoc, txtdch, codetrcfc }){
        const self = this
        return self.put(`${PROCESSOS_BASE_URL}/processo/${numpro}/anexo/${codarqpdl}`, { codetrdoc, txtdch, codetrcfc })
    }

    alterarInformacao(numpro, dados, numand, numseqocoand){
        const self = this
        return self.put(`${PROCESSOS_BASE_URL}/processo/${numpro}/andamento/${numand}/${numseqocoand}`, { txtdch: dados.txtdch })
    }

    assinar(numpro,  codarqpdl, codpesasi, tipasi){
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/processo/${numpro}/uspassina`, { codarqpdl, codpesasi, tipasi })
    }

    buscaArquivos(numpro, busca, codetrcfc, codetrdoc) {
        const self = this
        return self.get(`${PROCESSOS_BASE_URL}/processo/${numpro}/anexo/busca?codetrdoc=${codetrdoc}&codetrcfc=${codetrcfc}&busca=${busca}`)
            .then(retorno => retorno.map(r => Arquivo.buildFromJson(r)))
    }

    carregarArquivo(arquivo){
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/arquivo/temp`, {}, [arquivo], { 'Content-Type': 'multipart/form-data'})
    }

    inserirArquivo(numpro, dados){
        const self = this;
        const formData = new FormData()
        formData.append('txtdch', dados.txtdch)
        formData.append('codarqtmp', dados.codarqtmp)
        formData.append('codetrcfc', dados.codetrcfc)
        if(dados.dsctipdoc){
            formData.append('dsctipdoc', dados.dsctipdoc)
        }
        if(dados.codetrdoc){
            formData.append('codetrdoc', dados.codetrdoc)
        }
        return self.post(`${PROCESSOS_BASE_URL}/processo/${numpro}/anexo`, formData, [], { 'Content-Type': 'multipart/form-data' })
                    .then(function (retorno) {
                            retorno.dados = Ocorrencia.buildFromJson(retorno.dados, numpro)
                            return retorno;
                    });
    }

    inserirInformacao(numpro, dados){
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/processo/${numpro}/despacho`, { txtdch: dados.txtdch })
            .then(retorno => {
                retorno.dados = Ocorrencia.buildFromJson(retorno.dados, numpro)
                return retorno
            })
    }

    listar(numpro){
        const self = this
        return self.get(`${PROCESSOS_BASE_URL}/processo/${numpro}/andamento`)
            .then(lista => lista.map(a => Andamento.buildFromJson(a, numpro)))
    }

    gerarDocumento(documento){
        const self = this
        const responseType = documento.acao === 'salvar' ? 'json' : 'blob'
        return self.post(`${PROCESSOS_BASE_URL}/documento`, documento, [], {}, { responseType })
    }

    removerArquivo(codarqtmp){
        const self = this
        return self.delete(`${PROCESSOS_BASE_URL}/arquivo/temp/${codarqtmp}`, new FormData())
    }

    removerOcorrencia(numpro, numand, numseqocoand){
        const self = this
        return self.delete(`${PROCESSOS_BASE_URL}/processo/${numpro}/andamento/${numand}/${numseqocoand}`)
    }

    

 }