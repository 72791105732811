const ProcessosInicio = () => import(/* webpackChunkName: "chunk-processos" */ '@/processos/views/ProcessosInicio.vue')
const ProcessosForm = () => import(/* webpackChunkName: "chunk-processos" */ '@/processos/views/ProcessosForm.vue')
const ProcessosAcompanhamento = () => import(/* webpackChunkName: "chunk-processos" */ '@/processos/views/ProcessosAcompanhamento.vue')

export const routes = [
  {
    path: '/processos-digitais',
    name: 'Digitais',
    component: ProcessosInicio,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'Processos Digitais' }
      ]
    }
  },
  {
    path: '/processos-digitais/novo',
    name: 'Novo',
    component: ProcessosForm,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'Processos Digitais', link: '/processos/processos-digitais/' },
        { nome: 'Autuar Processo' }
      ]
    }
  },
  {
    path: '/processos-digitais/alterar/:numpro',
    name: 'Alterar',
    component: ProcessosForm,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'Processos Digitais', link: '/processos/processos-digitais/' },
        { nome: 'Processo ${numpro}', link: '/processos/processos-digitais/${numpro}' },
        { nome: 'Alteração de Dados'}
      ]
    }
  },
  {
    path: '/processos-digitais/:numpro',
    name: 'Acompanhamento',
    component: ProcessosAcompanhamento,
    props: true,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'Processos Digitais', link: '/processos/processos-digitais/' },
        { nome: 'Processo ${numpro}' }
      ]
    }
  },
]