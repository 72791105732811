export default class Aviso {

    constructor(codigo, codigoArea, nomeArea, iconeArea, tipo, assunto, mensagem, dataInicio, dataFim, visualizacao) {
        this.codigo = codigo;
        this.codigoArea = codigoArea;
        this.nomeArea = nomeArea;
        this.iconeArea = iconeArea;
        this.tipo = tipo;
        this.assunto = assunto;
        this.mensagem = mensagem;
        this.dataInicio = dataInicio;
        this.dataFim = dataFim;
        this.visualizacao = visualizacao;
    }

    isVisualizado(){
        return !!this.visualizacao;
    }

    getKey() {
        return `${this.codigo}:${this.codigoArea}`;
    }

    static build(json={}){
        return new Aviso(
            json.codigo,
            json.codigoArea || '',
            json.nomeArea || '',
            json.iconeArea || '',
            json.tipo || '',
            json.assunto || '',
            json.mensagem || '',
            json.dataInicio || '',
            json.dataFim || '',
            json.visualizacao || ''
        );
    }

}