import { DOMINIO_PORTAL } from "../constantes";

export default class BuscaMenu {

    constructor(codigo, codigoArea, nomeArea, titulo, navPortal, objetoweb='', parametros='', tipo='', nomeSistema=''){
        this.codigo = codigo;
        this.codigoArea = codigoArea;
        this.nomeArea = nomeArea;
        this.ePortal = (objetoweb || '').indexOf(DOMINIO_PORTAL) !== -1;
        this.objetoweb = objetoweb;
        this.nomeSistema = nomeSistema;
        this.parametros = parametros;
        this.tipo = tipo;
        this.navPortal = navPortal;
        this._formatarTitulo(titulo);
    }

    _formatarTitulo(titulo){
        const partes = titulo.split('>>');

        this.titulo = partes.pop();

        this.tituloPai = partes.join('>>');
        if(this.tituloPai){
            this.tituloPai += ' >> '
        }

    }

    eExterno(){
        return this.objetoweb.indexOf('://') !== -1 && !this.ePortal;
    }

    getUrl(){
        if(this.ePortal){
            let index = this.objetoweb.indexOf(DOMINIO_PORTAL),
                path = this.objetoweb.substring(index+1+DOMINIO_PORTAL.length);
            return /*path.indexOf(`${this.codigoArea}/`) === -1 ? `/${this.codigoArea}/${path}` : */`/${path}/`
        } else if(this.eExterno() || this.navPortal){
            return this.objetoweb;
        } else {
            return `/servicos/${this.codigoArea}/${this.objetoweb}?codmnu=${this.codigo}${this.parametros ? '&'+this.parametros : ''}`;
        }
    }

    static buildFromItem(item){
        return new BuscaMenu(
            item.codigo,
            item.arvore.codigo,
            item.arvore.nome,
            item.titulo,
            false,
            item.objetoweb,
            item.parametros,
            item.tipo,
            item.nomeSistema
        );
    }

    static build(json={}){
        return new BuscaMenu(
            json.codigo,
            json.codigoArea,
            json.nomeArea,
            json.titulo,
            json.navPortal,
            json.objetoweb,
            json.parametros,
            json.tipo,
            json.nomeSistema
        )
    }

}