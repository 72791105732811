import Vue from 'vue';
export default new Vue({
    methods: {
        mostrarMensagemToast(mensagem){
            this.$emit('mostrarMensagemToast', mensagem)
        },
        mostrarMensagemErro(mensagem='', titulo=''){
            const dados = { titulo, mensagem, variante: 'danger' };
            this.$emit('mostrarMensagemToast', dados)
        },
        mostrarMensagemSucesso(mensagem='', titulo=''){
            const dados = { titulo, mensagem, variante: 'success' };
            this.$emit('mostrarMensagemToast', dados)
        },
        onMostrarMensagemToast(callback){
            this.$on('mostrarMensagemToast', callback);
        }
    }
});