import { VARIANTES } from '../constantes';

export const SPINNER_FORMATOS = [
    'border','grow'
];

export const SPINNER_VARIANTES = [
    ...VARIANTES 
];

