import {
  USP_FONTE_CHAVE_STORAGE,
  USP_MENU_CLASSE_CLARO,
  USP_MENU_CHAVE_STORAGE,
  USP_TEMA_CLASSE_CLARO,
  USP_TEMA_CLASSE_CONTRASTE,
  USP_TEMA_CHAVE_CLARO,
  USP_TEMA_CHAVE_CONTRASTE,
  USP_TEMA_CHAVE_PADRAO,
  USP_TEMA_CHAVE_STORAGE
} from './constantes.js';

let exports = {};

exports.alternarTema = (tema) => {
    const el = document.body;
    if(tema === USP_TEMA_CHAVE_PADRAO){
      el.classList.remove(USP_TEMA_CLASSE_CONTRASTE);
      el.classList.remove(USP_TEMA_CLASSE_CLARO);
      localStorage.setItem(
        USP_TEMA_CHAVE_STORAGE, 
        USP_TEMA_CHAVE_PADRAO
      );
    } else if (tema === USP_TEMA_CHAVE_CONTRASTE){
      el.classList.remove(USP_TEMA_CLASSE_CLARO);
      el.classList.toggle(USP_TEMA_CLASSE_CONTRASTE);
      localStorage.setItem(
        USP_TEMA_CHAVE_STORAGE,
        USP_TEMA_CHAVE_CONTRASTE
      );
    } else {
      el.classList.remove(USP_TEMA_CLASSE_CONTRASTE);
      el.classList.toggle(USP_TEMA_CLASSE_CLARO);
      localStorage.setItem(
        USP_TEMA_CHAVE_STORAGE, 
        USP_TEMA_CHAVE_CLARO
      );
    }
};

exports.alterarTamanhoFonte = (tamanho) => {
  const el = document.getElementsByTagName('html')[0];
  localStorage.setItem(USP_FONTE_CHAVE_STORAGE, tamanho);
  el.style.fontSize = tamanho;
};

exports.aplicarAjustes = () => {
  const el = document.body;
  const preferencia = localStorage.getItem(USP_TEMA_CHAVE_STORAGE);
  if(preferencia){
    if(preferencia === USP_TEMA_CHAVE_CONTRASTE && !el.classList.contains(USP_TEMA_CLASSE_CONTRASTE)){
      el.classList.add(USP_TEMA_CLASSE_CONTRASTE);
    }
    else if(preferencia === USP_TEMA_CHAVE_CLARO && !el.classList.contains(USP_TEMA_CLASSE_CLARO)){
      el.classList.add(USP_TEMA_CLASSE_CLARO);
    }
  }
  if(localStorage.getItem(USP_FONTE_CHAVE_STORAGE)){
    exports.alterarTamanhoFonte(localStorage.getItem(USP_FONTE_CHAVE_STORAGE));
  }
  if(JSON.parse(localStorage.getItem(USP_MENU_CHAVE_STORAGE)) && !el.classList.contains(USP_MENU_CLASSE_CLARO)){
    el.classList.add(USP_MENU_CLASSE_CLARO);
  }
};

export default exports;