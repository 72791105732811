const ProServDetalhes = () => import(/* webpackChunkName: "chunk-proserv" */ '@/proserv/views/ProServDetalhes.vue')
const ProServForm = () => import(/* webpackChunkName: "chunk-proserv" */ '@/proserv/views/ProServForm.vue')
const ProServInicio = () => import(/* webpackChunkName: "chunk-proserv" */ '@/proserv/views/ProServInicio.vue')
const ProServResultados = () => import(/* webpackChunkName: "chunk-proserv" */ '@/proserv/views/ProServResultados.vue')

export const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: ProServInicio,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'ProServ' }
      ]
    }
  },
  {
    path: '/novo',
    name: 'Novo',
    component: ProServForm,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'ProServ', link: '/proserv/' },
        { nome: 'Nova Solicitação' }
      ]
    }
  },
  {
    path: '/detalhes/:anosolprs/:codsolprs',
    name: 'Detalhes',
    component: ProServDetalhes,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'ProServ', link: '/proserv/' },
        { nome: 'Solicitação #${codsolprs}-${anosolprs}' }
      ]
    }
  },
  {
    path: '/alterar/:anosolprs/:codsolprs',
    name: 'Alterar',
    component: ProServForm,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'ProServ', link: '/proserv/' },
        { nome: 'Solicitação #${codsolprs}-${anosolprs}', link: '/proserv/detalhes/${anosolprs}/${codsolprs}' },
        { nome: 'Alterando #${codsolprs}-${anosolprs}'}
      ]
    }
  },
  {
    path: '/acompanhar/',
    name: 'Acompanhar',
    component: ProServResultados,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'Acompanhamento ProServ' }
      ]
    }
  },
]