export default class TipoOcorrencia {

    constructor(codetroco, dscetroco) {
        this.codetroco = codetroco
        this.dscetroco = dscetroco
    }

    getPlaceholder(){
        switch (this.codetroco) {
            case 'ocoInformacao': return 'Digite aqui sua informação';
            case 'ocoAnexacao': return 'Digite aqui seu complemento'
            default: return 'Digite aqui seu despacho';
        }
    }

    getTipdch(){
        switch (this.codetroco) {
            case 'ocoInformacao': return 'Informação';
            case 'ocoAnexacao': return 'Complemento'
            case 'ocoAlteracao': return 'Alteração'
            default: return 'Despacho';
        }
    }

    getTiprem(){
        switch (this.codetroco) {
            case 'ocoInformacao': return 'Remover Informação';
            case 'ocoAnexacao': return 'Remover Arquivo'
            case 'ocoGeracao': return 'Remover Documento'
            default: return null;
        }
    }

    podeEditar(){
        switch (this.codetroco) {
            case 'ocoInformacao':
            case 'ocoGeracao':
            case 'ocoAnexacao': return true
            default: return false;
        }
    }

    toString(){
        return `${this.dscetroco || ''}`
    }

    static buildFromJson(json={}){
        return new TipoOcorrencia(
            json.codetroco,
            json.dscetroco
        )
    }
}