<!-- eslint-disable vue/no-v-html -->
<template>
  <PortalCardPaginado
    titulo="Minhas Tarefas"
    icone="fas fa-tasks"
    :lista="lista"
    class="usp-tarefas portal-tarefas-cartao"
  >
    <template 
      v-if="carregado && (pendentes.length + adiadas.length) > 0"
      #filtros
    >
      <div
        class="btn-group btn-group-sm btn-group-toggle mr-2 btn-pendente-adiado"
        data-toggle="buttons"
      >
        <label
          class="btn btn-sm btn-primary"
          :class="{ active: apenasPendentes }"
          @click="apenasPendentes = true"
        >
          <input 
            type="radio"
            name="options"
          > Pendentes
        </label>
        <label
          class="btn btn-sm btn-primary"
          :class="{ active: !apenasPendentes }"
          @click="apenasPendentes = false"
        >
          <input
            type="radio"
            name="options"
          > Adiadas
        </label>
      </div>
      <button 
        style="display: none"
        class="btn btn-sm btn-primary"
      >
        <em class="fas fa-stopwatch" />
        <span class="hidden-xs">Adiar</span>
      </button>
      <form>
        <div class="form-group">
          <label 
            for="filtro"
            class="sr-only"
          >
            Buscar tarefa...
          </label>
          <input
            id="filtro"
            v-model="filtro"
            name="filtro"
            type="text"
            class="form-control form-control-sm"
            placeholder="Buscar tarefa..."
          >
        </div>
      </form>
    </template>
    <template #corpo>
      <div
        v-if="carregado && lista.length === 0"
        class="d-flex justify-content-center align-items-center flex-column"
      >
        <img 
          src="imgs/usp-tarefas.svg"
          alt="Ilustração de uma pilha de tarefas concluídas"
        >
        <p
          v-show="carregado && lista.length === 0"
          class="font-weight-bold text-center  mt-5"
        >
          &nbsp; Não há tarefas
          <span v-if="apenasPendentes">pendentes</span>
          <span v-if="!apenasPendentes">adiadas</span>.
        </p>
      </div>
      <uspSkeleton v-if="!carregado">
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
        <div>
          <div class="w-50" />
          <div class="w-50" />
        </div>
      </uspSkeleton>
    </template>
    <template #default="conteudo">
      <div
        class="tarefa"
        :class="{
          lido: conteudo.item.isVisualizado(),
          estendido: conteudo.item.estendido,
        }"
      >
        <div
          class="info"
          :aria-controls="'tarefa-' + conteudo.item.codigo"
          role="button"
          @click="visualizar(conteudo.item)"
        >
          <div class="detalhes">
            <h6 class="area">
              {{ conteudo.item.nomeArea }}
            </h6>
          </div>
          <div class="conteudo">
            <h6 
              class="titulo" 
              v-html="conteudo.item.titulo"
            />
            <span 
              class="descricao" 
              v-html="conteudo.item.mensagem"
            />
            <div class="datas">
              <span class="data">
                <em class="far fa-clock" />
                {{ conteudo.item.cadastradoEm.split(" ")[0] }}
                {{ conteudo.item.cadastradoEm.split(" ")[1] }}
              </span>
              <span 
                v-show="conteudo.item.isAdiado()"
                class="data"
              >
                Adiado para:
                <em class="far fa-clock" />
                {{ conteudo.item.adiadoPara }}
              </span>
            </div>
          </div>
        </div>
        <PortalTarefasAcoes :tarefa="conteudo.item" />
      </div>
    </template>
    <template #acoes>
      <router-link
        v-if="historico"
        :to="{ name: 'tarefas' }"
        tag="button"
        class="btn btn-sm btn-outline-primary"
      >
        <em class="fas fa-history" /> Ver histórico
      </router-link>
    </template>
  </PortalCardPaginado>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters } from "../../../store/types";
import PortalCardPaginado from "@/portal/components/destaque/PortalCardPaginado.vue";
import PortalTarefasAcoes from "./PortalTarefasAcoes.vue";

export default {
  name: "PortalTarefasCartao",

  components: {
    PortalCardPaginado,
    PortalTarefasAcoes,
  },

  data() {
    return {
      apenasPendentes: true,
      filtro: "",
      historico: false,
    };
  },

  computed: {
    ...mapGetters({
      adiadas: Getters.NOTIFICACOES.TAREFAS_ADIADAS,
      carregado: Getters.NOTIFICACOES.STATUS_CARREGADO,
      pendentes: Getters.NOTIFICACOES.TAREFAS_PENDENTES,
    }),
    alertas() {
      return this.apenasPendentes ? this.pendentes : this.adiadas;
    },
    lista() {
      const self = this;
      return self.alertas.filter((item) => {
        return (
          (item.titulo.toLowerCase().includes(self.filtro.toLowerCase()) ||
            item.nomeArea.toLowerCase().includes(self.filtro.toLowerCase()) ||
            item.mensagem.toLowerCase().includes(self.filtro.toLowerCase())) &&
          (self.apenasPendentes ? !item.isAdiado() : item.isAdiado())
        );
      });
    },
  },

  methods: {
    ...mapActions({
      visualizar: Actions.NOTIFICACOES.VISUALIZAR_TAREFA,
    }),
  },
};
</script>
<style lang="scss">
@import "./src/componentes/estilos/temas.scss";

/* Geral */

.usp-tarefas {
  .filtros {
    padding: 0rem;
    border-bottom: none;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    /* Botões */
    .btn-group > .btn:not(:first-child),
    .btn-group > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
    .btn-group > .btn-group:not(:last-child) > .btn {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    form {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-grow: 2;
      div {
        flex-grow: 1;
        margin-left: 0.9rem;
        &:last-child {
          flex-grow: 0.5;
        }
      }

      .form-group {
        margin-bottom: 0rem;
        flex-basis: 100%;
        .form-control {
          /*border: 0rem; */
          margin-bottom: 0rem;
        }
      }

      //box-shadow: 0 5px 20px 0 rgba(69,67,96,0.05);
      .row {
        //margin: 0px;
        .offset-md-5 {
          padding-left: 1rem;
        }
      }

      .form-inline {
        flex-grow: 1;
        margin-left: 0.9rem;
      }
      input {
        border-radius: 30px;
        height: 2rem;
        width: 100%;
        border-width: 2px;
        /* border: 0px; */
      }
    }
  }

  .list-group {
    margin-top: 1rem;
    margin-bottom: 0rem;
    border-radius: 0.8rem;
    overflow: hidden;
    box-shadow: 0 2px 10px 0 rgba(69, 67, 96, 0.03);
    transition: max-height 1s;

    .list-group-item {
      border: 0px;
      padding: 0rem;

      &:hover {
        transition: 0.5s;
        .tarefa .acoes {
          visibility: visible;
        }
      }

      .tarefa {
        display: flex;
        align-items: baseline;
        align-content: stretch;
        justify-content: space-between;
        padding: 0.4rem;

        &:not(.estendido) .descricao {
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .acoes {
          width: 9rem;
          flex-direction: column;
          text-align: right;
          flex-basis: 10%;
        }
        .info {
          display: flex;
          align-items: stretch;
          flex-grow: 12;
          flex-basis: 6%;
        }
        &.lido {
          opacity: 0.8;
          .conteudo {
            .btn {
              pointer-events: none;
              cursor: not-allowed;
            }
          }
        }
        h6 {
          margin-bottom: 0rem;
        }
      }
      .detalhes {
        padding-right: 2rem;
        flex-basis: 15%;

        .data {
          font-size: 0.8rem;
          text-align: right;
          font-weight: 400;
        }
        .area {
          font-weight: 500;
          overflow-wrap: break-word;
        }
      }

      .conteudo {
        flex-basis: 85%;

        .titulo {
          font-weight: 500;
          border-bottom: none;
          padding-bottom: 0rem;
          font-size: 0.9rem !important;
        }
        .descricao {
          font-size: 0.9rem;
          transition: 0.3s;
          display: -webkit-box;
        }
        .btn {
          display: block;
          margin-top: 0.8rem;
          margin-bottom: 0.8rem;
        }
      }
    }
  }
}

.usp-tarefas .datas .data {
  font-size: 0.8rem;
  text-align: right;
  font-weight: 400;
  color: #999;
}

/* Temas */

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .usp-tarefas {
      .filtros {
        form {
          .form-group {
            margin-bottom: 0rem;
          }

          //box-shadow: 0 5px 20px 0 rgba(69,67,96,0.05);
          .row {
            //margin: 0px;
            .offset-md-5 {
              border-left: 1px dotted #ccc;
              padding-left: 1rem;
            }
          }

/*
          input {
            background: #f5f5f5;
          }
*/
          .btn-primary:not(.active) {
            background: #fff;
            color: map-get($tema, "usp-elemento-cor-do-texto");
            border-color: #f0f0f0;
          }
        }
      }

      .list-group {
        .list-group-item {
          background: map-get($tema, "usp-subelemento-cor-de-fundo");

          .acoes .btn i {
            color: map-get($tema, "usp-elemento-cor-do-texto");
          }

          &:hover {
            //background: #f5fafb;
            opacity: 0.55;
          }

          &:not(:first-child) {
            border-top: map-get($tema, "usp-subelemento-borda-interna");
          }

          .lido {
            background-color: map-get(
              $tema,
              "usp-tela-cor-de-fundo"
            );
          }
        }
        .detalhes {
          .data {
            color: map-get($tema, "usp-elemento-cor-do-texto");
          }
          .area {
            color: map-get($tema, "usp-subelemento-cor-do-link");
          }
        }

        .conteudo {
          .descricao {
            color: map-get($tema, "usp-elemento-cor-do-texto");
          }
        }
      }
    }
  }
}

</style>
