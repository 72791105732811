<template>
  <uspCartao titulo="Superintendência de Tecnologia da Informação">
    <template slot="corpo">
      <div class="usp-capa">
        <a href="https://www.sti.usp.br">
          <img
            src="https://jornal.usp.br/wp-content/uploads/20180221_00_aplicativo_guia_usp2.jpg"
            class="img-fluid"
          />
          <span class="usp-tag">TI NA USP</span>
          <h4>
            Conheça a Superintendência de
            Tecnologia da Informação
          </h4>
        </a>
      </div>
      <ul class="usp-links">
        <li class="header">SAIBA MAIS</li>
        <li>
          <a href="https://atendimentosti.usp.br/" target="blank">
            <i class="fas fa-at"></i>
            Como abrir um chamado para a STI?
          </a>
        </li>
        <li>
          <a href="https://atendimentosti.usp.br/otrs/public.pl?Action=PublicFAQZoom;ItemID=272" target="blank">
            <i class="fas fa-at"></i>
            Como enviar e-mail USP criptografado?
          </a>
        </li>
        <li>
          <a href="https://atendimentosti.usp.br/otrs/public.pl?Action=PublicFAQZoom;ItemID=315" target="blank">
            <i class="fas fa-at"></i>
            Roaming internacional - Linhas USP
          </a>
        </li>
        <li>
          <a href="https://atendimentosti.usp.br/otrs/public.pl?Action=PublicFAQZoom;ItemID=14" target="blank">
            <i class="fas fa-at"></i>
            Como conectar na VPN USP?
          </a>
        </li>
      </ul>
    </template>
  </uspCartao>
</template>

<script>
export default {
  name: 'PortalHomeSTI',
};
</script>

<style>
.usp-capa .img-fluid{
  border-radius: 1rem;
}
</style>