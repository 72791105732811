const LOG_LEVELS = ['TRACE', 'DEBUG','INFO','WARN','ERROR']

class PortalLogger {

    constructor(){
        this.setOptions();
    }

    trace(mensagem) { this._log('TRACE', mensagem) }
    debug(mensagem) { this._log('DEBUG', mensagem) }
    info(mensagem) { this._log('INFO', mensagem) }
    warn(mensagem) { this._log('WARN', mensagem) }
    error(mensagem) { this._log('ERROR', mensagem) }

    _log(level, mensagem) {
        const metodoConsole = this._getMetodoConsole(level);
        if(mensagem && LOG_LEVELS.indexOf(level) >= LOG_LEVELS.indexOf(this.level)) {
            console[metodoConsole](this._formatarMensagem(level, mensagem)); // eslint-disable-line no-console
        }
    }

    _getMetodoConsole(level){
        if(level === 'DEBUG') {
            return 'log';
        } else {
            return level.toLowerCase();
        }  
    }

    // Baseado em https://github.com/justinkames/vuejs-logger/blob/master/src/vue-logger.ts
    _getOrigem() {
        let erro = null;
        // Gerar um objeto do tipo erro e capturá-lo.
        try {
            throw new Error('');
        } catch (error) {
            erro = error;
        }

        if(!erro.stack) {
            return '';
        }
        
        let stack = erro.stack.split('\n')[5];
        let origem = '';
        
        if (/ /.test(stack)) {
            let partes = stack.trim().split(' ');
            stack = partes[1];
            origem = partes[2];
        }
        
        if (stack && stack.indexOf('.') > -1) {
            stack = stack.split('.')[1];
        }
        // Testa se está em uma função anônima, se estiver pega o arquivo.
        if(stack && origem && stack.trim() === '<anonymous>') {
            let grupos = origem.match(/\(.*\/(.*):(\d+:\d+)\)/)
            if(grupos && grupos.length > 1){
                stack = grupos[1];
            }
        }
        return stack;
    }

    _formatarMensagem(nivel, mensagem){
        return [nivel, this._getOrigem(), mensagem].join(this.separador);
    }

    setOptions(opt={}){
        this.level = (opt.level || 'DEBUG');
        this.separador = (opt.separador || ' | ');
    }

    install(Vue, options={}){
        this.setOptions(options);
        Vue.prototype.$logger = this;
    }

}

export default new PortalLogger();