import { PortalServico } from '@/utils/';

/**
 * @type {UsuarioServico}
 */
export default class UsuarioServico extends PortalServico {

    autenticar(usuario, token) {
        const self = this
        return self.post('/wsusuario/login',{
            loginUsuario: usuario.codpes,
            senhaUsuario: usuario.senusu,
            token: token
        });
    }

    deslogar() {
        return this.post('/wsusuario/logout');
    }

    validar() {
        return this.get('/wsusuario/validar');
    }

    cadastrarSenha(dados) {
        const self = this 
        return self.post('/wsusuario/cadastrarSenha', dados);
    }

    primeiroAcesso(usuario, token) {
        const self = this
        return self.post('/wsusuario/primeiroAcesso',{
            loginUsuario: usuario.codpes,
            emailUsuario: usuario.senusu,
            token: token
        });
    }

    esqueciSenha(usuario, token) {
        const self = this
        return self.post('/wsusuario/esqueciSenha',{
            loginUsuario: usuario.codpes,
            emailUsuario: usuario.senusu,
            token: token
        });
    }

    alterarSenha(dados) {
        const self = this
        return self.post('/wsusuario/alterarSenha', dados);
    }

    obterEmails() {
        const self = this
        return self.get('/wsusuario/api/email/obter?rand=' + Math.random());
    }

    obterCelular() {
        const self = this;
        return self.get('/wsusuario/api/contato/obterCelular?rand=' + Math.random());
    }

    necessitaAtualizarEmailCelular() {
        const self = this;
        return self.get('/wsusuario/api/contato/necessitaAtualizar?rand=' + Math.random());
    }

    podeAtualizarEmailPrincipal() {
        const self = this;
        return self.get('/wsusuario/api/contato/podeAtualizarEmailPrincipal?rand=' + Math.random());
    }

    salvarCelular(dados) {
        const self = this;
        return self.post('/wsusuario/api/contato/alterarCelular', dados);
    }

    alterarEmail(dados) {
        const self = this
        return self.post('/wsusuario/api/email/solicitar', dados);
    }

    confirmarAlteracaoEmail(dados) {
        const self = this
        return self.post('/wsusuario/api/email/confirmar', dados);
    }
}