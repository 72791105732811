
<template>  
  <transition
    v-if="showModal || mostrar"
    name="modal"
  >
    <div
      class="modal modal-mask"
      style="display: block"
    >
      <div
        class="modal-dialog"
        role="document"
        :style="cssVars"
        tabindex="0"
        @keydown.esc="closeModal()"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ titulo }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <slot name="body" />
          </div>

          <div class="modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
/* Baseado em http://junerockwell.com/how-to-make-simple-basic-modal-using-bootstrap-css-vuejs-2/*/
import { PortalLogger as Log } from '@/utils/';
export default {
  name: 'UspModal',
  props:{
    titulo: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: String,
      default: ''
    },
    mostrar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    cssVars() {
      return {
        '--max-width': this.maxWidth || "500px",
        '--max-height': this.maxHeight || "auto"
      }
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.$emit('fechar');
    },
    submitAndClose() {
      Log.info("");
    },
  }
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
</style>

<style lang="scss" scoped>
@import "../../estilos/temas";

.modal { overflow-y: auto }

.modal-dialog {
  max-width: var(--max-width);
  max-height: var(--max-height);
}


@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .modal-content {      
      color: map-get($tema, "usp-elemento-cor-do-texto");
      background: map-get($tema, "usp-elemento-cor-de-fundo");
      border-radius: map-get($tema, "usp-elemento-borda-arredondada");
      .close{
         color: map-get($tema, "usp-elemento-cor-do-texto");
      }
    }
  }
}
</style>

