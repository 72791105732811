<template>
  <button 
    class="dropdown-item"
    :disabled="desabilitado"
    @click="clicou"
  >
    <slot />
  </button>
</template>

<script>
export default{
    name: 'UspDropdownItem',
    props: {
        desabilitado: {
            type: Boolean,
            default: false,
            required: false
        },
        click: {
            type: Function,
            default: () => {},
        }
    },
    methods:{
        clicou(){
            this.$emit('click');
        }
    }
}
</script>
