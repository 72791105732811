export default class Assinatura {

    constructor(sitasi, sitlotdocasi, codlotdocasi, codarqasi_fs, codaucdoc) {
        this.sitasi = sitasi
        this.sitlotdocasi = sitlotdocasi
        this.codlotdocasi = codlotdocasi
        this.codarqasi_fs = codarqasi_fs
        this.codaucdoc = codaucdoc
    }

    toString(){
        return `${this.sitasi}`
    }

    getIDDigital(){
        return this.codaucdoc ?
        `${this.codaucdoc.substring(0,4)}-${this.codaucdoc.substring(4,8)}-${this.codaucdoc.substring(8,12)}-${this.codaucdoc.substring(12,16)}` :
        '';
    }

    getUrlIDDigital(){
        const url = new URL(window.location.origin)
        url.pathname = `iddigital/${this.codaucdoc}`
        return url;
    }

    static buildFromJson(json={}){
        return new Assinatura(
            json.sitasi,
            json.sitlotdocasi,
            json.codlotdocasi,
            json.codarqasi_fs,
            json.codaucdoc
        )
    }
}