export const Actions = {
  PROSERV: {
    CARREGAR_AREAS: 'PROSERV_CARREGAR_AREAS',
    CARREGAR_ARQUIVOS: 'PROSERV_CARREGAR_ARQUIVOS',
    CARREGAR_ANDAMENTOS: 'PROSERV_CARREGAR_ANDAMENTOS',
    CARREGAR_AVALIADORES: 'PROSERV_CARREGAR_AVALIADORES',
    CARREGAR_AVALIACAO: 'PROSERV_CARREGAR_AVALIACAO',
    CARREGAR_DEPARTAMENTOS: 'PROSERV_CARREGAR_DEPARTAMENTOS',
    CARREGAR_FUNCOES: 'PROSERV_CARREGAR_FUNCOES',
    CARREGAR_SOLICITACAO: 'PROSERV_CARREGAR_SOLICITACAO',
    CARREGAR_UNIDADES: 'PROSERV_CARREGAR_UNIDADES',
    CARREGAR_VERTENTES: 'PROSERV_CARREGAR_VERTENTES',
    ENVIAR_SOLICITACAO: 'PROSERV_ENVIAR_SOLICITACAO',
    LISTAR_SOLICITACOES: 'PROSERV_LISTAR_SOLICITACOES',
    OBTER_ARQUIVO: 'PROSERV_OBTER_DADOS_ARQUIVO',
    OBTER_PESSOA: 'PROSERV_OBTER_DADOS_PESSOA',
    REMOVER_ARQUIVO: 'PROSERV_REMOVER_ARQUIVO',
    REMOVER_AVALIADOR: 'PROSERV_REMOVER_AVALIADOR',
    REMOVER_SOLICITACAO: 'PROSERV_REMOVER_SOLICITACAO',
    SALVAR_SOLICITACAO: 'PROSERV_SALVAR_SOLICITACAO',
    SALVAR_DETALHES: 'PROSERV_SALVAR_DETALHES',
    SALVAR_ARQUIVO: 'PROSERV_SALVAR_ARQUIVO',
    SALVAR_AVALIADOR: 'PROSERV_SALVAR_AVALIADOR',
    SALVAR_AVALIACAO: 'PROSERV_SALVAR_AVALIACAO',
  },
}

export const Getters = {
  PROSERV: {
    AREAS: 'PROSERV_GET_AREAS',
    ARQUIVOS: 'PROSERV_GET_ARQUIVOS',
    ANDAMENTOS: 'PROSERV_GET_ANDAMENTOS',
    AVALIADORES: 'PROSERV_GET_AVALIADORES',
    AVALIACAO: 'PROSERV_GET_AVALIACAO',
    CARGOS: 'PROSERV_GET_CARGOS',
    CARREGANDO_SOLICITACOES: 'PROSERV_GET_CARREGANDO_SOLICITACOES',
    DEPARTAMENTOS: 'PROSERV_GET_DEPARTAMENTOS',
    FUNCOES: 'PROSERV_GET_FUNCOES',
    SOLICITACAO: 'PROSERV_GET_SOLICITACAO',
    SOLICITACOES: 'PROSERV_GET_SOLICITACOES',
    UNIDADES: 'PROSERV_GET_UNIDADES',
    VERTENTES: 'PROSERV_GET_VERTENTES',
  },
}

export const Mutations = {
  PROSERV: {
    AREAS: 'PROSERV_SET_AREAS',
    ARQUIVOS: 'PROSERV_SET_ARQUIVOS',
    ANDAMENTOS: 'PROSERV_SET_ANDAMENTOS',
    AVALIADOR: 'PROSERV_SET_AVALIADOR',
    AVALIACAO: 'PROSERV_SET_AVALIACAO',
    AVALIADORES: 'PROSERV_SET_AVALIADORES',
    ARQUIVO: 'PROSERV_SET_ARQUIVO',
    REMOVER_ARQUIVO: 'PROSERV_DEL_ARQUIVO',
    REMOVER_AVALIADOR: 'PROSERV_DEL_AVALIADOR',
    ADICIONAR_SOLICITACAO: 'PROSERV_ADICIONAR_SOLICITACAO',
    ATUALIZAR_SOLICITACAO: 'PROSERV_ATUALIZAR_SOLICITACAO',
    CARGOS: 'PROSERV_SET_CARGOS',
    CARREGAMENTO_SOLICITACOES: 'PROSERV_SET_CARREGAMENTO_SOLICITACOES',
    DELETE_CARGO: 'PROSERVE_DELETE_CARGO',
    DEPARTAMENTOS: 'PROSERV_SET_DEPARTAMENTOS',
    FUNCOES: 'PROSERV_SET_FUNCOES',
    SOLICITACAO: 'PROSERV_SET_SOLICITACAO',
    SOLICITACOES: 'PROSERV_SET_SOLICITACOES',
    UNIDADES: 'PROSERV_SET_UNIDADES',
    UPDATE_CARGO: 'PROSERV_UPDATE_CARGO',
    VERTENTES: 'PROSERV_SET_VERTENTES'
  }
}