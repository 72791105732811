<template>
  <a
    :href="destino"
    :class="estilo"
    :disabled="desabilitado"
  ><slot /> {{ texto }}</a>
</template>


<script>
import { LINK_VARIANTES, LINK_TAMANHOS } from "./constantes";

export default {
  name: "UspLink",
  props: {
    texto: {
      type: String,
      required: true,
    },
    destino: {
      type: String,
      required: true,
    },
    icone: {
      type: String,
      default: ''
    },
    contorno: {
      type: Boolean,
      required: false,
    },
    desabilitado: {
      type: Boolean,
      required: false,
    },
    variante: {
      type: String,
      default: "primario",
      validator: (value) => {
        return (
          LINK_VARIANTES.map(function(e) {
            return e.pt;
          }).indexOf(value) !== -1
        );
      },
    },
    tamanho: {
      type: String,
      default: "",
      validator: (value) => {
        return (
          LINK_TAMANHOS.map(function(e) {
            return e.pt;
          }).indexOf(value) !== -1
        );
      },
    },
  },
  computed: {
    estilo() {
      const tipoBS = LINK_VARIANTES.find((elemento) => elemento.pt == this.variante).en;
      return (
        `btn btn${this.estiloContorno}-${tipoBS} ` +
        (this.estiloTamanho || "")
      );
    },
    estiloTamanho() {
      const tamanhoBS = LINK_TAMANHOS.find(
        (elemento) => elemento.pt == this.tamanho
      ).en;
      return this.tamanho != "normal" ? `btn-${tamanhoBS}` : "";
    },
    estiloContorno() {
      return this.contorno ? "-outline" : "";
    },
  },

};
</script>
