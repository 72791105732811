<template>
  <div class="info">
    <h1>Seja bem-vindo(a) ao Portal de Serviços Computacionais da USP!</h1>
    <h5>
      Com o Portal de Serviços Computacionais da USP, você pode acessar todos os
      serviços acadêmicos e administrativos oferecidos pela universidade de um
      jeito mais simples, rápido e intuitivo.
    </h5>

    <div class="acoes">
      <uspLink
        texto="Faça um tour pelo novo portal"
        variante="aviso"
        destino="https://www.youtube.com/watch?v=nMLsFwSd9R8"
        target="_blank"
        role="button"
        class="mr-2"
      ></uspLink>
      <uspLink
        texto="Tire suas dúvidas"
        variante="claro"
        contorno
        destino="https://atendimentosti.usp.br/"
        target="_blank"
        role="button"
      ></uspLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortalHomeInfo",
};
</script>

<style>
.acoes .btn {
  margin: 3px;
}

@media screen and (max-width: 430px) {
  .acoes a.btn {
    width: 100%;
  }
}
</style>
