<!-- eslint-disable vue/no-v-html -->
<template>
  <uspModal 
    ref="avisoModal" 
    :titulo="titulo" 
    max-width="75%" 
    max-height="95%" 
    @fechar="limparAviso"
  >
    <template #body>
      <p v-show="carregado">
        <strong>Tipo:</strong> {{ aviso.tipo }}
      </p>
      <p v-show="carregado">
        <strong>Assunto:</strong> {{ aviso.assunto }}
      </p>
      <p v-show="carregado">
        <strong>Publicação:</strong> {{ aviso.dataInicio }}
      </p>
      <div
        v-show="!carregado"
        class="usp-loading"
      >
        <div class="usp-loading-item" />
        <div class="usp-loading-item" />
        <div class="usp-loading-item" />
        <div class="usp-loading-item" />
      </div>
      <div
        v-show="carregado" 
        v-html="aviso.mensagem" 
      />
    </template>
  </uspModal>
</template>

<script>
import { Getters, Mutations } from '@/portal/store/types';
import { mapGetters, mapMutations } from 'vuex';
export default {

    name: 'PortalAvisosModal',

    computed: {
        ...mapGetters({
            aviso: Getters.NOTIFICACOES.AVISO_PARA_VISUALIZAR,
            carregado: Getters.NOTIFICACOES.STATUS_AVISO_CARREGADO
        }),
        titulo() {
            return this.aviso && this.aviso.assunto ? this.aviso.assunto : '';
        }
    },

    watch: {
        aviso(aw){
            if(aw && aw.codigo){
                this.$refs['avisoModal'].openModal();
            }
        }
    },

    methods:{
        ...mapMutations({
            limparAviso: Mutations.NOTIFICACOES.AVISO_PARA_VISUALIZAR
        })
    },

}
</script>
<style scoped>
    .usp-loading .usp-loading-item {
        height: 1.2rem;
        margin: 1.2rem;
        border-radius: 0.7rem;
        background-color: linear-gradient(90deg, #f4f4f4, #ddd, #f4f4f4);
        background-size: 200%;
        animation: loading 1.5s infinite;
    }
</style>