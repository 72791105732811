import PerfilMenu from "./PerfilMenu";

export default class ObjetoMenu {

    constructor(codigoArea, nomeArea, contexto, objetoweb, perfilPadrao, perfis, sistema, nomeSistema) {
        this.codigoArea = codigoArea;
        this.nomeArea = nomeArea;
        this.objetoweb = objetoweb;
        this.contexto = contexto;
        this.perfilPadrao = perfilPadrao;
        this.sistema = sistema;
        this.nomeSistema = nomeSistema;
        this.perfis = perfis || [];
    }

    ePublico(){
        return !this.perfis || this.perfis.length === 0;
    }

    static build(json={}){
        return new ObjetoMenu(
            json.codigoArea,
            json.nomeArea,
            json.contexto,
            json.objetoweb,
            json.perfilPadrao ? PerfilMenu.build(json.perfilPadrao) : undefined,
            json.perfis.map(p => PerfilMenu.build(p)),
            json.sistema,
            json.nomeSistema
        );
    }


}