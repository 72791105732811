<template>
  <nav
    style="
      --bs-breadcrumb-divider: url(
        &#34;data:image/svg + xml,
        %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
      );
    "
    class="container-fluid"
    aria-label="breadcrumb"
  >
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link 
          :to="{ name: 'servicos' }"
        >
          <em class="fas fa-home" />
          Home
        </router-link>
      </li>
      <li 
        v-for="item in lista"
        :key="`breadcumb-${item.nome}`"
        :class="{
          active: item.link
        }"
        class="breadcrumb-item"
        :aria-current="item.link ? '' : 'page'"
      >
        <router-link 
          v-if="item.link"
          :to="formatarParametros(item.link)"
        >
          {{ formatarParametros(item.nome) }}
        </router-link>
        <span v-else>
          {{ formatarParametros(item.nome) }}
        </span>
      </li>
      <li 
        v-if="ePaginaLegado"
        class="breadcrumb-item"
      >
        <em
          v-if="pagina.iconeArea"
          :class="pagina.iconeArea" 
        />
        <span>
          {{ pagina.nomeSistema }}
          <small>({{ pagina.nomeArea }})</small>
        </span>
      </li>
      <li 
        v-if="ePaginaLegado"
        class="breadcrumb-item active"
        aria-current="page"
      >
        {{ pagina.objetoweb }}
      </li>
    </ol>
  </nav>
</template>

<script>
import { Getters } from '@/portal/store/types'
import { mapGetters } from 'vuex'


  export default {
    name: 'PortalRastreio',

    props: {
      rastreio: {
        type: Array,
        default: []
      }
    },

    data() {
      return {
        lista: []
      }
    },

    computed: {
      ...mapGetters({
        pagina: Getters.NAVEGACAO.OBJETOWEB
      }),
      ePaginaLegado(){
        return this.$route.fullPath.indexOf('/servicos/') !== -1
      }
    },

    watch: {
      $route: {
        handler(){
          this.atualizarLista()
        },
        deep: true,
        immediate: true
      },
      rastreio(atual) {
        if(atual) {
          if(Array.isArray(atual) && atual.length > 0) {
            this.lista = atual
          }
        }
      }
    },

    mounted(){
      this.atualizarLista()
    },

    methods: {
      atualizarLista(){
        this.lista = this.$route.meta.breadcrumb || []
      },
      formatarParametros(texto){
        let notoTexto = texto;
        (texto.match(/\${[a-z]*}/g) || [])
          .forEach(group => {
            notoTexto = notoTexto.replaceAll(group, this.$route.params[group.match(/[a-z]+/g)[0]])
          });
        return notoTexto;
      }
    }

  }
</script>
<style lang="scss" scoped>
@import "./src/componentes/estilos/temas.scss";

  nav {
    margin-top: 1rem;
  }

  .breadcrumb {
    background-color: transparent;
  }

  @each $tema in $temas {
    $elemento: map-get($tema, "elemento");
    #{$elemento} {
      
      a {
        color: map-get($tema, 'usp-breadcrumb-cor-de-texto')
      }

    }
  }

</style>