import ItemMenu from "./ItemMenu";

export default class ArvoreMenu {

    constructor(codigo, nome, contexto, icone, sistema, nomeSistema, navPortal) {
        this.codigo = codigo;
        this.nome = nome;
        this.contexto = contexto;
        this.icone = icone;
        this.sistema = sistema;
        this.nomeSistema = nomeSistema;
        this.navPortal = navPortal;

        this.subarvores = [];
        this.publico = [];
        this.privado = [];
    }

    addMenuprivado(menu=[]){
        this.privado.push(...menu);
    }

    getUrl(){
        return `/servicos/${this.codigo}/`;
    }

    temFilho(){
        return this.subarvores.length > 0 ||
               this.publico.length > 0 ||
               this.privado.length > 0;
    }

    static build(json={}){
        const arvore = new ArvoreMenu(
            json.codigo,
            json.nome,
            json.contexto,
            json.icone,
            json.sistema,
            json.nomeSistema,
            json.navPortal
        );

        arvore.subarvores = json.subarvores.map(j => ArvoreMenu.build(j));
        arvore.privado = json.privado.map(j => ItemMenu.build(j, arvore));
        arvore.publico = json.publico.map(j => ItemMenu.build(j, arvore));
            
        return arvore;
    }

}