const PrefixoIdUnico = 'USP-ID'

let IdUnicoContador = 1;

export default class UspIdUnico {

  static gerar(){
    IdUnicoContador++;
    return `${PrefixoIdUnico}-${IdUnicoContador}`
  }

}
