const UspDrivePastas = () => import(/* webpackChunkName: "chunk-usp-drive" */ '../views/UspDrivePastas')
const UspDriveArquivos = () => import(/* webpackChunkName: "chunk-usp-drive" */ '../views/UspDriveArquivos')
const UspDriveUsuarios = () => import(/* webpackChunkName: "chunk-usp-drive" */ '../views/UspDriveUsuarios')
const UspDriveRedirecionamento = () => import(/* webpackChunkName: "chunk-usp-drive" */ '../views/UspDriveRedirecionamento')
export const routes = [
  {
    path: '/',
    name: 'Pastas',
    component: UspDrivePastas,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'USP Drive' }
      ]
    }
  },
  {
    path: '/pasta/:codptaarqudr',
    name: 'Arquivos',
    component: UspDriveArquivos,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'USP Drive', link: '/usp-drive/' },
        { nome: 'Pasta' }
      ]
    }
  },
  {
    path: '/processo/:numprosaj',
    name: 'Processo',
    component: UspDriveRedirecionamento,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'USP Drive', link: '/usp-drive/' },
        { nome: 'Processo' }
      ]
    }
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: UspDriveUsuarios,
    meta: {
      logado: true,
      breadcrumb: [
        { nome: 'USP Drive', link: '/usp-drive/' },
        { nome: 'Usuários' }
      ]
    }
  }
]
