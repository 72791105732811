import Entrada from "./Entrada"
import Ocorrencia from "./Ocorrencia"
import Saida from "./Saida"

export default class Andamento {

    constructor(numand, dscdch, tmpeta, entrada, saida, ocorrencias, numpro) {
        this.numand = numand
        this.dscdch = dscdch
        this.tmpeta = tmpeta
        this.entrada = entrada
        this.saida = saida
        this.ocorrencias = ocorrencias || []
        this.novasOcorrencias = []
        this.numpro = numpro
    }

    static buildFromJson(json, numpro){
        return new Andamento(
            json.numand,
            json.dscdch, 
            json.tmpeta,
            Entrada.buildFromJson(json),
            Saida.buildFromJson(json),
            json.ocorrencias.map(o => Ocorrencia.buildFromJson(o, numpro)),
            numpro
        )
    }
}