const MatrVirtualIngress = () => import(/* webpackChunkName: "chunk-dashboard" */ '../views/MatrVirtualIngress')

export const routes = [
  {
    path: '/matrVirtualIngress',
    name: 'MatrVirtualIngress',
    component: MatrVirtualIngress,
    meta: {
      logado: true,
      breadcrumb:[
        { nome: 'Dash' },
        { nome: 'Matrícula Virtual' }
      ]
    }
  }
]