export default class Arquivo {

    constructor(ident, dtacad, nomarq, codetrdoc, dscetrdoc, codetrcfc, dscetrcfc, codarqpdl, qtdpgn, tamarq) {
        this.ident = ident
        this.dtacad = dtacad
        this.nomarq = nomarq
        this.codetrdoc = codetrdoc
        this.dscetrdoc = dscetrdoc
        this.codetrcfc = codetrcfc
        this.dscetrcfc = dscetrcfc
        this.codarqpdl = codarqpdl
        this.qtdpgn = qtdpgn
        this.tamarq = tamarq
        
    }

    getTamanhoFmt(){
        const unidades = ['B', 'KB', 'MB']
        let tamanho = this.tamarq
        let count = 0
        while((tamanho / 1024) > 1) {
            tamanho = (tamanho / 1024).toFixed(2)
            count++
        }
        return `${tamanho} ${unidades[count]}`
    }

    getDescricao(){
        return `${this.nomarq} - ${this.qtdpgn} página(s) - ${this.getTamanhoFmt()}`
    }

    static buildFromJson(json={}){
        return new Arquivo (
            `${json.numand}.${json.numseqocoand}`,
            json.dtacad,
            json.nomarq || json.nomarqpdl,
            json.codetrdoc,
            json.dscetrdoc,
            json.codetrcfc,
            json.dscetrcfc,
            json.codarqpdl,
            json.qtdpgn,
            json.tamarq
        )
    }
}