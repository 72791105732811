import * as components from './components';
import './estilos/style.scss';

const UspVue = {
  install(Vue) {
    for (const componentName in components) {
      const component = components[componentName];
      Vue.component(component.name, component);
    }
  }
}

export { UspPrinter } from './plugins'

export default UspVue;

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(UspVue);
}