<template>
  <span :class="estilo">{{ texto }}</span>
</template>

<script>
import { BADGE_VARIANTES } from "./constantes";

export default {
  name: 'UspBadge',

  props: {
    /**
     * Texto para aparecer dentro da badge.
     */
    texto: {
      type: String,
      required: true,
    },
    /**
     * Tipo de variante de estilo do Portal para a badge.
     */
    variante: {
      type: String,
      default: "primario",
      validator: (value) => {
        return BADGE_VARIANTES.map(function(e) { return e.pt; }).indexOf(value) !== -1;
      },
    },
  },

  computed: {
    classeBS(){
      return BADGE_VARIANTES.find(elemento => elemento.pt == this.variante).en;
    },
    estilo() {
      return `badge badge-${this.classeBS} `;
    },
  },
};
</script>
