import Abertura from "./Abertura"
import Assunto from "./Assunto"
import Estacao from "./Estacao"
import { formataProcesso } from '@/utils'
import Acao from "./Acao"

export default class Processo {

    constructor(numpro, nominr, titpro, cplasu, stapro) {
        this.numpro = numpro
        this.numprofmt = formataProcesso(numpro)
        this.nominr = nominr
        this.situacao = this.formatarSituacao(stapro)
        this.titpro = titpro
        this.cplasu = cplasu
    }

    formatarSituacao(situacao){
        if(situacao === 'A') return 'Arquivado'
        if(situacao === 'J') return 'Juntado'
        else return 'Normal'
    }

    static buildFromJson(json={}){
        const processo = new Processo(
            json.numpro,
            json.nominr,
            json.titpro,
            json.cplasu,
            json.stapro
        )
        processo.acao = Acao.buildFromJson(json)
        processo.assunto = Assunto.buildFromJson(json)
        processo.abertura = Abertura.buildFromJson(json)
        processo.estacaoAtual = Estacao.buildFromJson({ sgleta: json.sgletault, nometa: json.nometault, nomund: json.nomundult, sglund: json.sglundult })
        return processo;
    }
}