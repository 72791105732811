<template>
    <li class="nav-item">
        <a :href="link" 
           :class="classeLink" 
           v-on:click.prevent="ativarLink">
			<i :class="icone"></i>
            <p>{{ nome }} <i :class="iconeArvore"></i></p>
        </a>
        <ul class="nested" v-bind:class="{ active: ativo }">
            <li class="nav-item"
                v-for="item in externo"
                :key="item.codigo">
                <a :href="item.url" 
                   class="nav-link nav-link-treeview" 
                   target="_blank">
                    <i :class="getIcone(item.codigo)"></i>
                    <p>{{ item.nome }}</p>
                </a>
            </li>
        </ul>
    </li>
</template>
<script>

export default {

    name: 'PortalNavExternoMenu',

    props: {
        externo: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            ativo: false,
            icone: 'fas fa-layer-group',
            link: '/outros-servicos/',
            nome: 'Outros Serviços'
        }
    },

    computed: {
        classeLink() {
            return 'nav-link nav-link-treeview' + (this.ativo ? ' caret-down' : '');
        },
        iconeArvore() {
            return this.ativo ? 'right fas fa-angle-down' : 'right fas fa-angle-right';
        },
    },

    methods: {
        ativarLink(){
            this.ativo = !this.ativo
        },
        getIcone(id){
            switch (id) {
                case 'ifractal': return 'fas fa-stopwatch';
                case 'edisciplinas': return 'fas fa-book-open';
                case 'gedweb': return 'fas fa-book-reader';
                default: return 'fas fa-angle-right';
            }
        }
    }

}
</script>
<style scoped>

</style>