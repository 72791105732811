// Classes de temas disponíveis
export const USP_MENU_CLASSE_CLARO = 'usp-menu-expandido';
export const USP_TEMA_CLASSE_CLARO = 'usp-tema-claro';
export const USP_TEMA_CLASSE_CONTRASTE = 'usp-tema-contraste';
export const USP_TEMA_CLASSE_PADRAO = 'usp-tema-padrao';
// Chaves de temas disponíveis
export const USP_TEMA_CHAVE_CLARO = 'claro';
export const USP_TEMA_CHAVE_CONTRASTE = 'contraste';
export const USP_TEMA_CHAVE_PADRAO = 'padrao';
// Chaves de localStorage
export const USP_FONTE_CHAVE_STORAGE = 'USP-COMPONENTES-FONTE';
export const USP_MENU_CHAVE_STORAGE = 'USP-COMPONENTES-MENU';
export const USP_TEMA_CHAVE_STORAGE = 'USP-COMPONENTES-TEMA';
