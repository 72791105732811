<template>
  <section>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <h5 class="float-left">Conheça o novo portal de serviços computacionais da USP</h5>
        </div>

        <div class="card-body">
          <article>
            <h4 id="onde-sistemas">Onde estão os sistemas Júpiter, Janus, Mercúrio e Marte?</h4>
            <p>
              Os sistemas corporativos representados por seu conjunto de deuses,
               agora estão se transformando em serviços computacionais. Todos esses
                serviços estão separados por duas grandes áreas de atuação acadêmica 
                e administrativa da USP, conforme o seguinte quadro.
            </p>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th scope="col">
                    <em>Sistemas Corporativos</em>
                  </th>
                  <th scope="col">
                    <em>Área Acadêmica</em>
                  </th>
                </tr>
                <tr>
                  <td>
                    <strong>Jupiterweb</strong>
                  </td>
                  <td>
                    Graduação
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Janus, Tede, Tede Vídeos</strong>
                  </td>
                  <td>
                    Pós Graduação
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Atena, SIICUSP, Especialistas</strong>
                  </td>
                  <td>
                    Pesquisa
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Apolo</strong>
                  </td>
                  <td>
                    Cultura e Extensão
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Mundus</strong>
                  </td>
                  <td>
                    Relações Internacionais
                  </td>
                </tr>
                <tr>
                  <td>
                    <em>Sistemas Corporativos</em>
                  </td>
                  <td>
                    <em>Área Administrativa</em>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Rucard</strong>
                  </td>
                  <td>
                    Restaurante Universitário
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>DataUSP, Alumni, Anuário, Tycho, WerUSP PG, WerUSP</strong>
                  </td>
                  <td>
                    Indicadores USP
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Painel do Gestor</strong>
                  </td>
                  <td>
                    Controle Orçamentário
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Administrativo, Proteos, Convênios (Novo), Contratos, Urania</strong>
                  </td>
                  <td>
                    Administrativo
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Marteweb, USP Oportunidades</strong>
                  </td>
                  <td>
                    Recursos Humanos
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Mercurioweb, Patrimônio, Licitações, Juno</strong>
                  </td>
                  <td>
                    Financeiro
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Adm. Docente, Aval. Docente, Telefonia, Diplomas, Prefeitura</strong>
                  </td>
                  <td>
                    Órgão Central
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Autentusp</strong>
                  </td>
                  <td>
                    Autenticação Digital
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Nereu</strong>
                  </td>
                  <td>
                    Pauta Eletrônica
                  </td>
                </tr>
              </tbody>
            </table>
          </article>
          <article id="o-que-sao">
            <h4>O que são os serviços computacionais da USP?</h4>
            <p>
              Serviços computacionais da USP são funcionalidades 
              operacionais disponibilizada em formato digital para
              apoio às atividades acadêmicas ou administrativas dos alunos,
              servidores e professores. 
            </p>
          </article>
          <article id="mobile">
            <h4>O portal de serviços funciona no meu dispositivo móvel?</h4>
            <p>
              Sim, o portal de serviços computacionais da USP funciona em qualquer
              dispositivo móvel, ajustando as funcionalidades ao tamanho da tela 
              que está utilizando no momento. É oportuno ressaltar que, na fase de 
              implantação inicial do portal, para algumas versões dos dispositivos móveis, 
              algumas telas poderão apresentar restrições de visualização. Entretanto, 
              isso não implicará na perda da funcionalidade.
            </p>
          </article>
          <article id="quem-pode">
            <h4>Quem pode ter acesso ao portal de serviços?</h4>
            <p>
              O portal de serviços está disponível para a comunidade USP: 
              alunos de graduação e pós-graduação, servidores técnicos e administrativos,
               docentes e alunos pertencentes a programas de cultura e extensão.
            </p>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  
}
</script>

<style>

  article {
    margin: 1em 0; 
  }

</style>