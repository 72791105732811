<template>
  <div 
    v-clicar-fora="fechar"
    class="container-fluid" 
  >
    <div class="busca-servicos">
      <div 
        id="busca" 
        class="row"
      >
        <div class="col">
          <label 
            for="search" 
            class="sr-only"
          >
            Pesquise um serviço da USP
          </label>
          <input
            id="search"
            v-model="termo"
            type="search"
            class="form-control"
            :class="temResultados"
            placeholder="Qual serviço você deseja utilizar?"
            @keydown.esc="fechar"
            @keydown.enter="enter"
            @keydown.down="baixo"
            @keydown.up="cima"
            @input="trocar"
          >
        </div>
      </div>

      <div 
        v-show="mostrar"
        class="list-group" 
      >
        <a
          v-for="(item, index) in listaAtiva"
          :key="item.codigo"
          class="list-group-item list-group-item-action flex-column align-items-start"
          target="_blank"
          :href="item.getUrl()"
          :class="{ active: eAtivo(index) }"
          @click.prevent="seguirLink(item)"
        >
          <div class="w-100 d-flex justify-content-between flex-row">
            <div class="d-flex flex-column">
              <span style="font-size: .8rem">
                {{ item.nomeArea }} 
                <small>({{ item.nomeSistema }})</small>
              </span>
              <span class="mb-1">
                <strong v-if="item.tituloPai">
                  {{ item.tituloPai }}
                </strong>
                {{ item.titulo }}
              </span>  
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Getters } from '@/portal/store/types';
export default {
  name: 'PortalPainelBusca',
  data() {
    return {
      atual: 0,
      caracteres: 4,
      mostrar: false,
      termo: "",
    };
  },
  computed: {
      ...mapGetters({
          lista: Getters.NAVEGACAO.BUSCA
      }),
      listaAtiva(){
        return this.termo.length >= this.caracteres ? this.lista.filter((item) => {
            let texto = ((item.tituloPai || '')+item.titulo).toLowerCase();
            return texto.indexOf(this.termo.toLowerCase()) >= 0;
        }) : []
      },
      temResultados() {
          return this.lista.length > 0 && this.mostrar ? 'resultados' : '';
      }
  },

  methods: {
    baixo() {
      if (this.atual < this.listaAtiva.length - 1) this.atual++;
    },

    cima() {
      if (this.atual > 0) this.atual--;
    },

    eAtivo(indice) {
      return indice === this.atual;
    },

    enter() {
      this.seguirLink(this.listaAtiva[this.atual]);
      this.mostrar = false;
      this.termo = "";
    },

    fechar() {
      this.mostrar = false;
      this.termo = "";
    },

    seguirLink(item) {
      if (item.eExterno()) {
        window.open(item.getUrl(), "blank");
      } else {
        this.$router.push(item.getUrl());
      }
      this.fechar();
    },

    trocar() {
      if (!this.mostrar) {
        this.mostrar = true;
        this.atual = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/componentes/estilos/temas.scss";

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {

.portal-usp-conteudo header {
  .busca-servicos {
    #busca {
      input.form-control {
        background-position: 20px 10px !important;
        height: 2.5rem;
        background-color: transparent;
        background-image: url(map-get($tema,"usp-cabecalho-busca-imagem")) !important;
        background-repeat: no-repeat !important;
        padding-left: 50px;
        box-shadow: 0 2px 10px 0 rgba(69, 67, 96, 0.03);
        opacity: 0.75;
        border: 2px solid map-get($map: $tema, $key: "usp-cabecalho-cor-do-texto");
        border-radius: 1.875rem;
        color: map-get($map: $tema, $key: "usp-cabecalho-cor-do-texto");
        transition: 0.5s;


        &.resultados {
          border-top-left-radius: 0.8rem !important;
          border-top-right-radius: 0.8rem !important;
          border-bottom-left-radius: 0rem !important;
          border-bottom-right-radius: 0rem !important;
          transition: 0.1s !important;
        }
        &::placeholder {
          color: map-get($map: $tema, $key: "usp-cabecalho-cor-do-texto");
          transition: all 0.3s;
        }
        &:focus {
          opacity: 1;
          transition: 0.5s;
        }
        &:focus::placeholder {
          color: map-get($map: $tema, $key: "usp-cabecalho-cor-do-texto");
          opacity: 0.7;
        }
      }
    }

    /* resultados da busca */
    .list-group {
      border-top-left-radius: 0rem !important;
      border-top-right-radius: 0rem !important;
      border-bottom-right-radius: 0.8rem !important;
      border-bottom-left-radius: 0.8rem !important;
      overflow: hidden;
      box-shadow: 0 0px 20px 0 rgba(69, 67, 96, 0.15);
      font-size: 0.9rem;
      transition: all 500ms ease;
      position: relative;
      z-index: 3;
      width: 100%;

      .list-group-item {
        border: none;
        &:first-child {
          border-top-left-radius: 0rem !important;
          border-top-right-radius: 0rem !important;
        }
        &:not(:first-child) {
          border-top: 1px solid #f0f0f0;
        }
        &.active {
          background-color: $cinza-claro-2;
          border-color: $cinza-claro-2;
          color: $cinza-escuro;
        }
      }
    }
  }
}
  }
}

</style>
