<template>
  <div
    class="accordion"
    role="tablist"
  >
    <div 
      v-for="item in itens" 
      :key="item.item"
      class="card mb-2"
    >
      <div 
        class="card-header"
        @click="trocarItem(item.item)"
      >
        <a 
          class="card-collapser"
          @click.prevent="tabAtiva = item.item" 
        >
          <em 
            :class="icone(item.item)"
            class="mr-2" 
          />
        </a>
        <h6>
          <em 
            v-if="item.icone"
            :class="icone"
          />
          {{ item.titulo }}
        </h6>
      </div>

      <div 
        :class="{ show: tabAtiva == item.item }"
        class="collapse"
      >
        <div class="card-body">
          <component :is="item.componente" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UspAcordeao',

  props: {
    itens: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tabAtiva: null,
    };
  },
  created() {
    /* Criar identificador para cada aba */
    this.itens.forEach((item, pos) => {
      item.item = pos
    })

    this.itens.forEach((item) => {
      let c = item.nome
      this.$options.components[c] = () => item.componente
    })
  },

  methods: {
    icone(id) {
      return this.tabAtiva == id ? "fas fa-chevron-up" : "fas fa-chevron-down";
    },
    trocarItem(id) {
      if (this.tabAtiva != id) {
        this.tabAtiva = id;
      } else {
        this.tabAtiva = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../estilos/temas";

h5.titulo {
  border-left: 0px !important;
  margin-bottom: 0rem;
}

.accordion {
  transition: 0.4s;

  .card {
    box-shadow: none;
    margin-bottom: 2rem;
    border-radius: 0.8rem !important;
    .card-header {
      border-bottom: 0px;
      padding: 0.5rem 0rem;
      display: flex;
      justify-content: initial;
      *:first-child {
        border-left: 0px!important;
      }
      &:hover {
        cursor: pointer;
      }

      
    }

    .card-body{
        border-radius: 1rem;
      }

    .collapse:not(.show) {
      display: block;
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity 1s ease-out, height 0ms 400ms, opacity 400ms 0ms;
    }

    .collapse.show {
      opacity: 1;
      transition: height 0ms 0ms, opacity 600ms 0ms;
      padding: 1rem;
    }
  }
}

/* Aplicação das variáveis por tema */
@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .accordion {
      .card {
        background: map-get($tema, "usp-tela-cor-de-fundo");
        color: map-get($tema, "usp-elemento-cor-do-texto");
        border: 1px solid map-get($tema, "usp-subelemento-cor-de-fundo-desabilitado");
        .card-body {
          border-top: map-get($tema, "usp-elemento-borda-interna");
          background: map-get($tema, "usp-elemento-cor-de-fundo");
        }
      }
    }
  }
}
</style>
