import FuncaoProServ from "./FuncaoProServ"

export default class CargoProServ {

  constructor(fnccgoprs, areatcespcgo) {
      this.fnccgoprs = fnccgoprs
      this.areatcespcgo = areatcespcgo
  }

  static fromJSON(json={}){
    return new CargoProServ(
      FuncaoProServ.fromJSON(json.fnccgoprs),
      json.areatcespcgo
    )
  }
}