const LicitacoesDetalhes = () => import(/* webpackChunkName: "chunk-contratacoes" */ '../views/LicitacoesDetalhes')
const LicitacoesBusca = () => import(/* webpackChunkName: "chunk-contratacoes" */ '../views/LicitacoesBusca')

export const routes = [
  {
    path: '/',
    name: 'Busca',
    component: LicitacoesBusca,
    meta: {
      breadcrumb:[
        { nome: 'Licitações/Contratações' }
      ]
    }
  },
  {
    path: '/:codpcddsp',
    name: 'Detalhamento',
    component: LicitacoesDetalhes,
    meta: {
      breadcrumb:[
        { nome: 'Licitações/Contratações', rawlink: '/contratacoes/' },
        { nome: '${codpcddsp}', link: '/contratacoes/${codpcddsp}' },
      ]
    },
    beforeEnter(to, from, next){
      let { query } = from;
      let item = to.meta.breadcrumb[0];

      item.link = `${item.rawlink}?${Object.entries(query).map(([k,v]) => `${k}=${v}`).join('&')}`;

      next();
    }
  }
]