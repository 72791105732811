<template>
    <uspModal titulo="Adiar tarefa" maxWidth="280px" ref="adiar-modal" @fechar="limparTarefa()" >
        <template slot="body">
            <vc-date-picker v-model="novaData" mode="dateTime" is24hr />
        </template>
        <template slot="footer">
            <button type="button"
                    @click.prevent="fecharModal()"
                    class="btn btn-secundary">
                    Fechar
            </button>
            <button type="button"
                    @click.prevent="adiarTarefa()"
                    class="btn btn-primary">
                    Salvar
            </button>
        </template>
    </uspModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Actions, Getters, Mutations } from '@/portal/store/types';
import eventBus from '@/eventBus';
export default {

    name: 'PortalTarefaAdiarModal',

    data() {
        return {
            msg: {
                sucesso: {
                    titulo: 'Tarefa adiada com sucesso'
                }
            },
            novaData: new Date()
        }
    },

    computed: {
        ...mapGetters({
            tarefa: Getters.NOTIFICACOES.TAREFA_PARA_ADIAR
        }),
        data(){
            const dia = ("00" + this.novaData.getDate()).slice(-2),
                  mes = ("00" + (this.novaData.getMonth()+1)).slice(-2),
                  ano = this.novaData.getFullYear(),
                  horas = ("00" + this.novaData.getHours()).slice(-2),
                  minutos = ("00" + this.novaData.getMinutes()).slice(-2);

            return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
        }
    },

    methods: {
        ...mapActions({
            adiar: Actions.NOTIFICACOES.ADIAR_TAREFA
        }),
        ...mapMutations({
            limparTarefa: Mutations.NOTIFICACOES.TAREFA_PARA_ADIAR
        }),
        adiarTarefa(){
            const self = this;
            self.adiar({ tarefa: this.tarefa, novaData: this.data })
                .then(() => {
                    self.$refs['adiar-modal'].closeModal();
                    eventBus.mostrarMensagemSucesso(`Tarefa adiada para ${this.tarefa.adiadaPara}`, this.msg.sucesso.titulo);
                }).catch(erro => eventBus.mostrarMensagemErro(erro.mensagem, 'Erro ao adiar tarefa!'));
        },
        getAmanhaCedo(){
            const hoje = new Date(),
                  amanha = new Date(hoje);
            amanha.setDate(amanha.getDate()+1)
            amanha.setHours(8, 0, 0, 0);
            return amanha;
        },
        fecharModal(){
            this.$refs['adiar-modal'].closeModal();
        }
    },

    watch:{
        tarefa(t){
            if(t){
                this.novaData = this.getAmanhaCedo();
                this.$refs['adiar-modal'].openModal();
            }
        }
    }

}
</script>

<style>

</style>