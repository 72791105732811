export default class Tarefa {

    constructor(codigo, codigoArea, nomeArea, iconeArea, titulo, mensagem, tipoBaixa, url, cadastradoEm, visualizadoEm, adiadoPara, adiado) {
        this.codigo = codigo;
        this.codigoArea = codigoArea;
        this.nomeArea = nomeArea;
        this.iconeArea = iconeArea;
        this.titulo = titulo;
        this.mensagem = mensagem;
        this.tipoBaixa = tipoBaixa;
        this.url = url;
        this.cadastradoEm = cadastradoEm;
        this.visualizadoEm = visualizadoEm;
        this.adiadoPara = adiadoPara;
        this.adiado = adiado;
        this.estendido = false;
    }

    getKey() {
        return `${this.codigo}:${this.codigoArea}`;
    }

    isAdiado(){
        return this.adiado;
    }

    isVisualizado() {
        return !!this.visualizadoEm;
    }

    podeAdiar(){
        return !this.adiado && this.codigoArea !== 'votacao-eletronica';
    }

    podeResolver(){
        return !this.url;
    }

    podeSeguir(){
        return !!this.url;
    }

    static build(json={}){
        return new Tarefa(
            json.codigo,
            json.codigoArea || '',
            json.nomeArea || '',
            json.iconeArea || '',
            json.titulo || '',
            json.mensagem || '',
            json.tipoBaixa,
            json.url,
            json.cadastradoEm,
            json.visualizadoEm,
            json.adiadoPara,
            json.adiado || false
        );
    }

}