export default class UnidadeProServ {

  constructor(codund, sglund, nomund) {
      this.sglund = sglund
      this.nomund = nomund
      this.codund = codund
  }

  getCodigo(){
      return this.codund
  }

  getTexto(){
      return `${this.sglund} - ${this.nomund}`
  }

  toString(){
      return this.getTexto()
  }

  static fromJSON(json={}){
    return new UnidadeProServ(
      json.codund,
      json.sglund,
      json.nomund
    )
  }
}