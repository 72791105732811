import { PortalServico } from '@/utils'
import { PROCESSOS_BASE_URL } from './contantes';
import Processo from './modelos/Processo';

/**
 * @type {ProcessosServico}
 */
export default class ProcessosServico extends PortalServico {

    static build (options = {}) {
        return super.build({
            ...options,
            headers: {
                'X-Username': 'Portal'
            }
        })
    }

    arquivar(numpro, dados) {
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/processo/${numpro}/arquivar`, dados)
    }

    buscar(dados) {
        const self = this
        const params = Object.entries(dados).map(e => `${e[0]}=${e[1]}`).join('&')
        const url = `${PROCESSOS_BASE_URL}/processo/busca?${params}`
             
        return self.get(url)
                   .then(resultado => resultado.map(item => Processo.buildFromJson(item)));
    }

    darSaida(numpro, dados){
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/processo/${numpro}/saida`, dados)
    }

    desarquivar(numpro, dados) {
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/processo/${numpro}/desarquivar`, dados)
    }

    devolver(numpro, dados){
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/processo/${numpro}/devolver`, dados)
    }

    entrar(numpro, dados){
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/processo/${numpro}/entrada`, dados)
    }

    inserir(processo) {
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/processo`, processo)
    }

    listarJuntados(numpro){
        const self = this
        return self.get(`${PROCESSOS_BASE_URL}/processo/${numpro}/juntada`)
    }

    listarPermissoes(numpro){
        const self = this
        return self.get(`${PROCESSOS_BASE_URL}/processo/${numpro}/permissao`)
    }

    obter(numpro){
        const self = this
        return self.get(`${PROCESSOS_BASE_URL}/processo/${numpro}`)
                    .then(resultado => Processo.buildFromJson(resultado));
    }

    alterar(processo) {
        const self = this
        return self.put(`${PROCESSOS_BASE_URL}/processo/${processo.numpro}`, processo)
    }

    juntarProcesso(numpro, numproJ, txtdch) {
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/processo/${numpro}/juntada`, { numproJ, txtdch })
    }

    desjuntarProcesso(numpro, numproJ, txtdch) {
        const self = this
        return self.post(`${PROCESSOS_BASE_URL}/processo/${numpro}/desjuntada`, { numproJ, txtdch })
    }

}