<template>
  <h6 class="dropdown-header">
    <slot />
  </h6>
</template>

<script>
export default {
    name: 'UspDropdownItemTitulo',
}
</script>
