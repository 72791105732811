<template>
  <section>
    <div class="container-fluid">
      <uspCartao 
        titulo="Configurações" 
        :fechavel="false"
      >
        <template #corpo>
          <a 
            href="https://id.usp.br/restrito/novasenha.xhtml" 
            class="trocar-senha" 
            target="_blank"
          >
            <em class="fas fa-external-link-alt" />
            Alterar Senha
          </a>

          <uspAcordeao :itens="itens" />
        </template>
      </uspCartao>
    </div>
  </section>
</template>

<script>
import AlterarEmail from '../components/configuracoes/AlterarEmail.vue';

export default {
  name: 'Configuracoes',

  data(){
    return{

    itens: [
      {
        titulo: "Alterar E-mail",
        nome: "AlterarEmail",
        componente: AlterarEmail,
      }
    ],
  }
  },
}
</script>

<style>

a.trocar-senha {
  align-items: center;
  background: #f8f9fa;
  border-radius: 0.8rem !important;
  color: #333;
  display: flex;
  flex-flow: row nowrap;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 0rem;
  margin-bottom: 0.5rem !important;

  .fas {
    padding-left: 1rem;
    font-weight: bold;
    margin: 0;
    margin-right: 0.5rem !important;
  }

}

.regras {
  list-style: none;
}

.regras .header {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.valido {
  color: green;
}

.invalido {
  color: red;
}
</style>
