import Vue from 'vue'
import VueRouter from 'vue-router'

import { routes as portalRoutes } from '@/portal/router/rotas.js';
import { routes as dashRoutes } from '@/dash/router/rotas.js';
import { routes as assinaRoutes } from '@/usp-assina/router/rotas.js';
import { routes as driveRoutes } from '@/usp-drive/router/rotas.js';
import { routes as novaFolhaRoutes } from '@/nova-folha/router/rotas.js';
import { routes as processosRoutes } from '@/processos/router/rotas.js';
import { routes as proServRoutes } from '@/proserv/router/rotas.js';
import { routes as nereuRoutes } from '@/nereu/router/rotas.js'
import { routes as webdocRoutes } from '@/webdoc/router/rotas.js'
import { routes as contratacoesRoutes } from '@/licitacoes/router/rotas.js'
import { routes as pedrowebRoutes, BASE_NAME } from '@/pedroweb/router/rotas.js'
import { StorageKeys } from '@/portal/constantes';

Vue.use(VueRouter)

const routes = [];

const registrarRota = (rota={}, prefixo='') => {
  if(rota.path[0] !== '/') rota.path = '/'+rota.path;
  if(prefixo){
    rota.path = `/${prefixo.toLowerCase()}${rota.path}`;
    rota.name = `${prefixo[0].toUpperCase()}${prefixo.substring(1)}:${rota.name}`;
  }
  routes.push(rota);
};

portalRoutes.forEach(rota => registrarRota(rota));
assinaRoutes.forEach(rota => registrarRota(rota, 'assina'));
driveRoutes.forEach(rota => registrarRota(rota, 'usp-drive'));
processosRoutes.forEach(rota => registrarRota(rota, 'processos'));
proServRoutes.forEach(rota => registrarRota(rota, 'proserv'));
novaFolhaRoutes.forEach(rota => registrarRota(rota, 'novaFolha'));
nereuRoutes.forEach(rota => registrarRota(rota, 'mandato'))
webdocRoutes.forEach(rota => registrarRota(rota, 'iddigital'));
dashRoutes.forEach(rota => registrarRota(rota, 'dash'));
contratacoesRoutes.forEach(rota => registrarRota(rota, 'contratacoes'));
pedrowebRoutes.forEach(rota => registrarRota(rota, BASE_NAME));

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const autenticado = localStorage.getItem(StorageKeys.AUTH.USUARIO_DISPLAY);
  if(!autenticado && to.fullPath !== '/' && to.meta.logado) {
    localStorage.setItem(StorageKeys.AUTH.REDIRECT, to.path);
    next('/');
  } else {
    next();
  }
})

export default router
