import { DOMINIO_PORTAL } from "../constantes";
export default class ItemMenu {

    constructor(codigo, sistema, tipo, titulo, objetoweb, parametros, arvore) {
        this.codigo = codigo;
        this.sistema = sistema;
        this.tipo = tipo;
        this.titulo = titulo;
        this.arvore = arvore;
        this.ePortal = (objetoweb || '').indexOf(DOMINIO_PORTAL) !== -1;
        this.objetoweb = objetoweb;
        this.parametros = parametros;
        this.filhos = [];
    }

    eExterno(){
        return (this.objetoweb || '').indexOf('://') !== -1 && !this.ePortal;
    }

    ePai(){
        return this.filhos.length > 0;
    }

    getUrl(){
        if(this.ePortal){
            let index = this.objetoweb.indexOf(DOMINIO_PORTAL),
                path = this.objetoweb.substring(index+1+DOMINIO_PORTAL.length);
                return /*path.indexOf(`${this.arvore.codigo}/`) === -1 ? `/${this.arvore.codigo}/${path}` :*/ `/${path}/`
        } else if(this.eExterno() || this.arvore.navPortal){
            return this.objetoweb;
        } else if (this.ePai()){
            return `/servicos/${this.arvore.codigo}/#`;
        } else {
            return `/servicos/${this.arvore.codigo}/${this.objetoweb}?codmnu=${this.codigo}${this.parametros ? '&'+this.parametros : ''}`;
        }
    }

    setObjetoweb(objetoweb){
        this.objetoweb = this.ePortal && objetoweb ?
            `/${this.arvore.codigo}/${objetoweb.substring(objetoweb.indexOf('portalservicos.usp.br')+'portalservicos.usp.br'.length+1, '')}` :
                objetoweb;
    }

    static build(json={}, arvore={}){
        const item = new ItemMenu(
            json.codigo,
            json.sistema,
            json.tipo,
            json.titulo,
            json.objetoweb,
            json.parametros,
            arvore
        );

        item.filhos = json.filhos.map(j => ItemMenu.build(j, arvore));

        return item;
    }

    static buildLista(lista=[], arvore={}){
        const menu = [],
              pais = {};

        lista.forEach(item => {
            const im = new ItemMenu(
                `pvt-${item.codmnu}`,
                item.nomsis,
                'N',
                item.elmmnu,
                item.nomobtweb,
                item.pmeobtweb,
                arvore
            );
            if(item.stapai === 'S'){
                pais[item.codmnu] = im;
            }
            if(item.codmnupai){
                pais[item.codmnupai].filhos.push(im);
            } else {
                menu.push(im);
            }
        });

        return menu;
    }


}