<template>
  <div id="app">
    <PortalHeader v-if="notEmbedded" />
    <PortalSidebar v-if="notEmbedded" />
    <section :class="{'portal-usp-conteudo' : notEmbedded }">
      <header v-if="notEmbedded">
        <PortalPainelBusca />
        <PortalRastreio 
          v-if="naoEHome"
          :rastreio="rastreio"
        />
        <div id="portal-cabecalho" />
      </header>
      <router-view 
        @trocaMenuJanus="trocarMenuJanus"
        @atualizar-rastreio="atualizarRastreio"
      />
    </section>
    <PortalTarefaAdiarModal v-if="notEmbedded" />
    <PortalAvisosModal v-if="notEmbedded" />
    <AlterarEmailTelefone v-if="notEmbedded" />
  </div>
</template>

<script>
import { Actions } from './portal/store/types';
import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/eventBus'
import PortalAvisosModal from '@/portal/components/destaque/avisos/PortalAvisosModal.vue';
import PortalHeader from '@/portal/components/layout/PortalHeader.vue';
import PortalPainelBusca from '@/portal/components/layout/PortalPainelBusca.vue';
import PortalSidebar from '@/portal/components/layout/PortalSidebar.vue';
import PortalTarefaAdiarModal from '@/portal/components/destaque/tarefas/PortalTarefaAdiarModal.vue';
import AlterarEmailTelefone from '@/portal/components/configuracoes/AlterarEmailTelefone.vue';
import PortalRastreio from './portal/components/layout/PortalRastreio.vue';

export default {
  
  name: 'PortalUSPApp',

  components: {
    PortalTarefaAdiarModal,
    PortalAvisosModal,
    PortalHeader,
    PortalSidebar,
    PortalRastreio,
    PortalPainelBusca,
    AlterarEmailTelefone
  },
  
  data() {
    return {
      codigoConfirmacaoEmail: this.$route.query.codigo,
      producao: process.env.NODE_ENV === 'production',
      rastreio: null
    }
  },

  computed: {
    ...mapGetters({
      autenticado: 'auth/autenticado'
    }),
    naoEHome(){
      return this.$route.path !== '/'
    },
    notEmbedded(){
      return !(this.$route.fullPath.includes('embedded'))
    }
  },

  watch: {
    autenticado(auth) {
      const self = this;
      if (auth) {
        this.carregarNavPrivada();
        this.carregarNotificacoes();
        self.necessitaAtualizarEmailCelular();
      } else {
        this.carregarNavPublica();
      }
    },
  },

  created() {
    const self = this;
    self.criarSessaoJanus();
    if (this.codigoConfirmacaoEmail) {
      eventBus.mostrarMensagemToast({
        mensagem: "Salvando a alteração de e-mail...",
        variante: "info",
      });
      self.confirmarAlteracaoEmail({ codigo: self.codigoConfirmacaoEmail })
        .then((response) => {
          if (response.retorno == "erro") {
            eventBus.mostrarMensagemToast({
              titulo: "Erro ao validar troca de e-mail!",
              mensagem: response.mensagem,
              variante: "danger",
            });
          } else {
            eventBus.mostrarMensagemToast({
              titulo: "Alteração de email:",
              mensagem: response.mensagem,
              variante: "success",
            });
          }
        })
        .finally(this.verificarSessao);
    } else {
      self.verificarSessao();
    }
  },

  methods: {
    ...mapActions({
      carregarSessao: 'auth/carregarSessao',
      carregarNavPrivada: Actions.NAVEGACAO.CARREGAR_PRIVADA,
      carregarNavPublica: Actions.NAVEGACAO.CARREGAR_PUBLICA,
      carregarNotificacoes: Actions.NOTIFICACOES.CARREGAR,
      criarSessaoJanus: Actions.NAVEGACAO.CRIAR_SESSAO_JANUS,
      confirmarAlteracaoEmail: 'auth/confirmarAlteracaoEmail',
      necessitaAtualizarEmailCelular: 'auth/necessitaAtualizarEmailCelular'
    }),
    atualizarRastreio(rastreio){
      this.rastreio = rastreio;
    },
    criarScriptAcessibilidade(){
       let externalScript = document.createElement('script')
       externalScript.setAttribute('src', 'https://perto-digital.nyc3.digitaloceanspaces.com/clients-versions/usp/plugin/js/main.js?v=2.0.0')
       externalScript.dataset.xys = '78a2fc1949';
       document.head.appendChild(externalScript)
    },
    trocarMenuJanus(){
      const self = this;
      self.carregando = true;
      const pos = self.navegacao.arvores.find(
        (a) => a.codigo === "pos-graduacao"
      );
      self.$logger.info("Recebi o evento de troca de menu no App");
      self.api.obterNavegacaoJanus(pos).then((menu) => {
        pos.privado = pos.privado.slice(0, 1).concat(menu);
      });
    },
    verificarSessao() {
      const self = this;
      this.carregarSessao()
        .then((auth) => {
          if (!auth) {
            self.carregarNavPublica();
          } else {
            self.carregarNotificacoes();
            self.carregarNavPrivada();
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "./src/componentes/estilos/temas.scss";

/* Gerais */
body.efeito-suavizar .portal-usp-conteudo {
  transition: ease-in-out 0.5s;
}
.portal-usp-conteudo {
  width: 100%;
  min-height: 100vh;
  header {
    height: auto;
    padding: 1.875rem 2.5rem 7rem 2.5rem;
    transition: height 0.5s;
    /* área pública */
    .boas-vindas {
      margin-top: 3rem;
      margin-bottom: 1rem;
      .info {
        h1 {
          font-weight: 600;
        }
        h5 {
          font-weight: normal;
          margin-top: 2rem;
        }
        .acoes {
          margin-top: 2.5rem;
          margin-bottom: 2.5rem;
          .btn-tour {
            margin-right: 0.5rem !important;
          }
        }
      }
    }
  }

  header + section {
    margin-top: -6.8rem;
    padding: 0rem 2.5rem 0rem 2.5rem;
    margin-bottom: 5rem;
  }

  @media all and (max-width: 480px) {
    header + section {
      padding: 0;
    }
  }
  
}

@media (max-width: 768px) {
  .portal-usp-conteudo {
    margin-left: 0rem;
    header {
      padding: 0.5rem 0 7rem 0;
    }
  }
}

/* Por tema */

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    background: map-get($tema, "usp-tela-cor-de-fundo");

    .portal-usp-conteudo {
      color: $usp-cores-branco;
      margin-top: map-get($tema, "usp-barra-superior-altura-total");

      header {
        background: map-get($tema, "usp-cabecalho-cor-de-fundo");
        color: map-get($tema, "usp-cabecalho-cor-do-texto");
        .navbar-nav .nav-link {
          color: $usp-cores-branco;
        }
        /* área pública */
        .boas-vindas {
          .info {
            h1 {
              color: map-get($tema, "usp-cabecalho-cor-do-texto");
            }
            h5 {
              color: map-get($tema, "usp-cabecalho-cor-do-texto");
            }
            .acoes {
              .btn-tour {
                background: map-get($tema, "usp-cores-amarelo");
                color: $usp-cores-branco;
              }
              .btn-outline-light {
                color: map-get($tema, "usp-cabecalho-cor-do-texto");
                border-color: map-get($tema, "usp-cabecalho-cor-do-texto");
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 769px) {
    .portal-usp-conteudo {
      max-width: calc(100% - map-get($tema, "usp-menu-lateral-largura-maxima"));
      margin-left: map-get($tema, "usp-menu-lateral-largura-maxima");
    }
  }
}
</style>
