<template>
  <section>
    <portal selector="#portal-cabecalho">
      <div class="container-fluid" v-if="!autenticado">
        <div class="boas-vindas row">
          <div class="col-lg-6">
            <PortalHomeInfo />
          </div>
          <div class="col-lg-4 offset-lg-2">
            <PortalHomeLogin />
          </div>
        </div>
      </div>
    </Portal>
    <section class="portal-usp-cards-publicos">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-6">
            <PortalHomeSobre />
          </div>
          <div class="col-12 col-sm-6 col-lg-6">
            <PortalHomeSTI />
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>

import PortalHomeInfo from '../components/home/PortalHomeInfo.vue'
import PortalHomeLogin from '../components/home/PortalHomeLogin.vue'
import PortalHomeSobre from '../components/home/PortalHomeSobre.vue'
import PortalHomeSTI from '../components/home/PortalHomeSTI.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    PortalHomeInfo,
    PortalHomeLogin,
    PortalHomeSobre,
    PortalHomeSTI
  },

  computed: {
    ...mapGetters({
      autenticado: "auth/autenticado",
    }),
  },

  mounted() {
    if (this.autenticado) {
      this.$router.push({ name: "servicos" });
    }
  },
};
</script>
<style lang="scss">
@import "./src/componentes/estilos/temas.scss";

.covid {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

/* Propriedades gerais */
.portal-usp-cards-publicos {
  margin-top: -7rem;
  .card {
    a {
      text-decoration: none;
    }

    .usp-capa {
      img {
        margin-bottom: 1rem;
      }
      a:hover {
        text-decoration: none;
        opacity: 0.9;
        transition: 0.5s;
      }
      .usp-tag {
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: bold;
      }
    }
    ul.usp-links {
      list-style: none;
      border-top: 1px dotted #fefefe;
      padding-left: 0;
      padding-top: 1rem;
      font-size: 0.9rem;
      li {
        a {
          &:hover {
            text-decoration: none;
          }
        }
        i {
          margin-right: 0.5rem;
          color: orange;
        }
        &:hover {
          opacity: 0.8;
        }
        &.header {
          text-transform: uppercase;
          margin-bottom: 0.5rem;
          font-weight: bold;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

/* Por temas */
@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {

    .boas-vindas{
      color: orange;
    }

    .portal-usp-cards-publicos {
      .card {
        a {
          color: map-get($tema, "usp-elemento-cor-do-link");
        }
        .usp-capa {
          .usp-tag {
            color: map-get($tema, "usp-elemento-cor-do-texto");
          }
        }
        ul.usp-links {
          li {
            a {
              color: map-get($tema, "usp-elemento-cor-do-texto");
            }
          }
        }
      }
    }
  }
}
</style>
