import { VARIANTES } from '../constantes';

export const BOTAO_VARIANTES = [
    ...VARIANTES,
    {'pt': 'link', 'en': 'link', 'nome': 'Link'}, 
];

export const BOTAO_TAMANHOS = [
    {'pt': 'pq', 'en': 'sm', 'nome': 'Pequeno' }, 
    {'pt': '', 'en': '', 'nome': 'Padrão' }, 
    {'pt': 'gd', 'en': 'lg', 'nome': 'Grande' }, 
    {'pt': 'bc', 'en': 'block', 'nome': 'Em Bloco' }, 
];