<template>
  <div
    :class="estilo"
    role="status"
  >
    <span class="sr-only">{{ texto }}</span>
  </div>
</template>

<script>
import {  SPINNER_FORMATOS, SPINNER_VARIANTES } from "./constantes";

export default {
  name: "UspSpinner",

  props: {
    texto: {
      type: String,
      default: "Carregando...",
      required: false,
    },

    formato: {
      type: String,
      default: "border",
      validator: (value) => {
        return SPINNER_FORMATOS.indexOf(value) !== -1;
      },
    },

    variante: {
      type: String,
      default: "primario",
      validator: (value) => {
        return SPINNER_VARIANTES.map(function(e) { return e.pt; }).indexOf(value) !== -1;
      },
    },
  },

  computed: {
    classeBS(){
      return SPINNER_VARIANTES.find(elemento => elemento.pt == this.variante).en;
    },
    estilo() {
      return `spinner-${this.formato} text-${this.classeBS}`;
    },    
  },
};
</script>
