<template>
  <section>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <div class="sistemas">
            <h5>{{ titulo }}</h5>
            <span class="sistema">{{ sistema }}</span>
          </div>
          <div 
            v-show="!publico"
            class="perfis"
          >
            <uspDropdown 
              id="legado-perfis-dropdown" 
              variante="primario" 
              :contorno="true"
              :alinhamento="alinhamentoDrop"
            >
              <template #conteudo>
                <span class="texto">
                  <em class="fas fa-sm fa-user-tag" />
                  Perfil: {{ perfilAtual.nivel }} - {{ perfilAtual.nome }}
                </span>
              </template>
              <template #itens>
                <h6 
                  v-if="outrosPerfis.length == 0"
                  class="dropdown-header"
                >
                  Não há outros perfis
                </h6>
                <h6 
                  v-if="outrosPerfis.length > 0"
                  class="dropdown-header"
                >
                  Outros Perfis
                </h6>
                <button 
                  v-for="perfil in outrosPerfis"
                  :key="perfil.numero"
                  class="dropdown-item"
                  type="button"
                  @click="ativarPerfil(perfil)"
                >
                  {{ perfil.nivel }} - {{ perfil.nome }}
                </button>
              </template> 
            </uspDropdown>
          </div>
        </div>
        <div class="card-body">
          <iframe 
            :src="url"
            title="Iframe de páginas legadas"
            class="iframe-legado body-filho"
            @load="validarIframeCarregado"
          />
          <div 
            v-show="carregando"
            class="legado-loader body-filho"
          >
            <h5>Otimizando visualização para o portal...</h5>
            <div class="spinner" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { NAVEGACAO_LEGADO, NAVEGACAO_JANUS, NAVEGACAO_PORTAL, NAVEGACAO_PERFIL } from '../constantes';
import { mapActions, mapGetters } from 'vuex';
import WindowMixin, { TAMANHOS } from '@/portal/mixins/WindowMixin.js';
import GerenciadorPerfil from '@/portal/dominio/navegacao/GerenciadorPerfil';
import NavegacaoServico from '../dominio/navegacao/NavegacaoServico';
import { Actions } from '../store/types';
export default {
  name: 'PortalLegado',
  mixins: [ WindowMixin ],
  data() {
    return {
      api: NavegacaoServico.build({}),
      carregando: true,
      gerenciador: new GerenciadorPerfil(),
      objetoweb: {},
      perfilAtual: {},
      refPortal: window.location.origin,
      timeout: 20000,
      timeoutCarregar: null,
      tipoNavegacao: null,
      url: ''
    };
  },

  computed:{
    ...mapGetters({
      autenticado: 'auth/autenticado'
    }),
    chaveCache(){
      return `${this.objetoweb.codigoArea}:${this.objetoweb.objetoweb}`;
    },
    outrosPerfis(){
      const self = this;
      if(self.objetoweb?.perfis && self.objetoweb.perfis.length > 0){
        return self.objetoweb.perfis.filter(p => p.nivel !== self.perfilAtual.nivel);
      } else {
        return [];
      }
    },
    parametros(){
      const query = this.$route.query;
      let parametros = '';
      for(let prop in query){
        parametros += `${prop}=${query[prop]}&`;
      }
      if(parametros.endsWith('&')) parametros = parametros.substring(0, parametros.length - 1);
      return parametros;
    },
    publico() {
      return !this.objetoweb?.perfis || this.objetoweb.perfis.length === 0;
    },
    titulo() {
       return this.objetoweb.nomeArea ? `${this.objetoweb.nomeArea}` : 'Carregando...';
    },
    sistema(){
       return this.objetoweb.nomeSistema ? `${this.objetoweb.nomeSistema}` : ''; 
    },
    alinhamentoDrop(){
      return [TAMANHOS.EXTRA_SMALL, TAMANHOS.SMALL].includes(this.window.tamanho) ?  'centro' : 'direita';
    }
  },



  watch: {
    $route() {
      if(this.tipoNavegacao !== NAVEGACAO_LEGADO){
        this.$logger.info(`Tratando a troca de rota ${this.tipoNavegacao || NAVEGACAO_PORTAL}`);
        this.tratarNavegacaoPortal();
      } else {
        this.$logger.info('Descarregando após troca de rota!');
        this.descarregar();
      }
    }
  },

  created() {
    window.addEventListener('message', this.processarMensagemIframe)
  },

  unmounted() {
    window.removeEventListener('message', this.processarMensagemIframe)
  },

  mounted() {
    this.tratarNavegacaoPortal();
  },

  methods: {
    ...mapActions({
      carregarObjetowebLegado: Actions.NAVEGACAO.CARREGAR_OBJETOWEB_LEGADO,
      carregarObjetowebArea: Actions.NAVEGACAO.CARREGAR_OBJETOWEB_AREA
    }),
    ativarPerfil(perfil){
      this.$logger.info(`Trocando perfil para ${perfil.nivel} - ${perfil.nome}`);
      this.perfilAtual = perfil;
      this.gerenciador.trocar(perfil);
      this.tipoNavegacao = NAVEGACAO_PERFIL;
      this.url = this.construirUrl();
    },
    cancelarTimeout() {
      if(this.timeoutCarregar) {
        clearTimeout(this.timeoutCarregar);
      }
    },
    carregar() {
      this.carregando = true;
      this.cancelarTimeout();
      this.iniciarTimeout();
    },
    construirRota(objetoweb, parametros){
      return `/servicos/${objetoweb.codigoArea}/${objetoweb.objetoweb}${parametros ? '?'+parametros : ''}`;
    },
    construirUrl() {
      const url = new URL(`${window.location.origin}/${this.objetoweb.contexto}/`);
      let params = this.parametros;
      if(params.indexOf('ref-portal') === -1){
          params += `&ref-portal=${this.refPortal}`;
      }
      if(this.publico){
          url.pathname += this.objetoweb.objetoweb;
          url.search = params;
      } else {
          url.pathname += 'autenticar';
          if(this.perfilAtual) {
            url.searchParams.append('numnivseg', this.perfilAtual.nivel);
          }
          url.searchParams.append('url_redirect', `${escape(this.objetoweb.objetoweb)}?${params}`);
      }
      if(url.toString() === this.url){
        url.searchParams.append('v', Date.now());
      }
      return url.toString();
    },
    descarregar(){
      this.cancelarTimeout();
      this.carregando = false;
      this.tipoNavegacao = null;
    },
    iniciarTimeout() {
      const self = this;
      self.timeoutCarregar = setTimeout(() => {
        if(self.carregando){
          self.descarregar();
          self.$logger.error(`Timeout ao tentar otimizar o caminho ${self.$route.fullPath}`);
        }
      }, self.timeout)
    },
    processarMensagemIframe(event) {
      const self = this;
      if (typeof event.data === "object") {
        if (event.data.id === "PAGINA_LEGADA_CARREGADA") {
          if(self.tipoNavegacao === NAVEGACAO_LEGADO) {
            self.$logger.info('Página carregada por navegação legada e otimizada com sucesso!');
            self.tratarNavegacaoLegado(event.data.url, event.data.contexto);
          } else if(self.tipoNavegacao === NAVEGACAO_JANUS) {
            self.$logger.info('Página recarregada na troca do perfil do Janus, recarregando iFrame');
            self.url = self.construirUrl();
            self.tipoNavegacao = null;
          } else {
            self.$logger.info('Página carregada por navegação do portal e otimizada com sucesso!');
            self.descarregar();
          }
        } else if (event.data.id === "PAGINA_LEGADA_NAVEGACAO") {
          if(!self.tipoNavegacao){
            self.$logger.info('Evento de navegação legada capturado com sucesso!');
            self.tipoNavegacao = NAVEGACAO_LEGADO;
            self.carregar();
          } else if(self.tipoNavegacao === NAVEGACAO_PERFIL){
            self.$logger.info('Evento de navegação por troca de perfil');
            self.carregar();
          }
        } else if (event.data.id === "PORTAL_PERFIL_TROCADO") {
          self.$logger.info('Troca de menu do janus');
          self.tipoNavegacao = NAVEGACAO_JANUS;
          self.$emit('trocaMenuJanus');
        }
      }
    },
    testarArquivo(path){
      let possivelArquivo = path.split('/').pop(),
          indice = possivelArquivo.indexOf('.');
       return indice > -1 && possivelArquivo.substring(indice) !== '.jsp' && possivelArquivo.substring(indice) !== '.jsf';
    },
    tratarNavegacaoLegado(urlLegado, contexto) {
      const self = this;
      self.$logger.info(`Tratando navegação interna do legado na url ${urlLegado} e no contexto ${contexto}`);
      
      //self.api.obterNavegacaoObjetoLegado(
      self.carregarObjetowebLegado({
        objeto: urlLegado.split('?')[0],
        contexto,
        auth: self.autenticado
      }).then(objetoweb => {
        let rotaAtual = unescape(self.$route.fullPath),
            novaRota = self.construirRota(objetoweb, urlLegado.split('?')[1]);
        if(novaRota !== rotaAtual) {
            self.$logger.info(`Atualizando rota ${rotaAtual} para ${novaRota} e ${this.tipoNavegacao}`);
            self.$router.push(novaRota);
        } else {
          self.descarregar();
        }
        self.objetoweb = objetoweb;
        self.perfilAtual = self.gerenciador.escolher(objetoweb);
      });
    },
    tratarNavegacaoPortal() {
      const self = this,
            path = this.$route.fullPath.replace('/servicos/',''),
            idx = path.indexOf('/'),
            area = path.substring(0, idx),
            objeto = path.substring(idx+1).split('?')[0];

      self.$logger.info(`Processando navegação do Portal na area ${area} e objeto ${objeto}`);
      self.tipoNavegacao = NAVEGACAO_PORTAL;

      //self.api.obterNavegacaoObjetoArea(
      self.carregarObjetowebArea({
        objeto,
        area,
        auth: self.autenticado
      }).then(objetoweb => {
          self.$logger.info(`Retornado objetoweb, construindo a URL do Legado`);
          self.objetoweb = objetoweb;
          self.perfilAtual = self.gerenciador.escolher(objetoweb);
          self.url = self.construirUrl();
        });
      this.carregar();
    },
    validarIframeCarregado(){
      if(this.testarArquivo(window.location.pathname)){
        this.descarregar();
      }
    }
  },

};
</script>

<style lang="scss" scoped>
.portal-usp-conteudo {
  background-color: #d6d6ff;
}

iframe {
  background: white;
  overflow: scroll;
  border: 0;
}

.body-filho {
  width: 100%;
  height: 100%;
  min-height: 800px;
  top: 0;
  left: 0;
  position: absolute;
}

.card-body {
  position: relative
}

.legado-loader {
  z-index: 8;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #22a6b3;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1.2s linear infinite;
  margin-top: 8em;
}

.legado-loader h5{
  color: #ccc;
  text-align: center;
}

.card-header .float-left{
  display: flex;
    flex-direction: column;
}

.card-header h5{
  line-height: 1;
}

span.sistema{
  font-size: .9rem; font-weight: 400;
}

@keyframes spin {
  to { transform: rotate(360deg);}
}

@media screen and (max-width: 768px) {
  .card-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    .perfis{
      padding: .4rem;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      ::v-deep .dropdown-menu {
        width: 100% !important;
      }
    }
  }
}

</style>