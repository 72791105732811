import { Actions, Getters, Mutations } from '@/processos/store/types.js';
import { PortalLogger as log } from '@/utils';
import EstruturaServico from '@/processos/dominio/EstruturaServico.js'
import TipoDocumento from '../dominio/modelos/TipoDocumento';

const api = EstruturaServico.build({})

// Estado inicial
const state = {
    assuntos: [],
    estacoesUnidade: [],
    estacoesUsuario: [],
    estacoesPessoa: [],
    tiposConferencia: [],
    tiposDocumento: [],
    statusTiposConferencia: false,
    statusTiposDocumento: false,
    unidades: []
}

// getters
const getters = {
    [Getters.ESTRUTURA.ASSUNTO_CPLOBG]: (st) => (codasu) => st.assuntos.find(a => a.codasu === codasu)?.cplobg,
    [Getters.ESTRUTURA.ASSUNTOS]: (st) => st.assuntos,
    [Getters.ESTRUTURA.ESTACOES_UNIDADE]: (st) => st.estacoesUnidade,
    [Getters.ESTRUTURA.ESTACOES_USUARIO]: (st) => st.estacoesUsuario,
    [Getters.ESTRUTURA.ESTACOES_PESSOA]: (st) => st.estacoesPessoa,
    [Getters.ESTRUTURA.TIPO_CONFERENCIA]: (st) => st.tiposConferencia,
    [Getters.ESTRUTURA.TIPO_DOCUMENTO]: (st) => st.tiposDocumento,
    [Getters.ESTRUTURA.UNIDADE]: (st) => st.unidades,
}

// actions
const actions = {
    [Actions.ESTRUTURA.ASSUNTOS]: ({ commit }) => {
        return api.listarAssunto()
                 .then((retorno) => commit(Mutations.ESTRUTURA.ASSUNTOS, retorno))
                 .catch(error => log.error('Erro ao listar assunto: '+error.erro))
    },
    [Actions.ESTRUTURA.ESTACOES_UNIDADE]: ({ commit }, { codund }) => {
        return api.listarEstacaoUnidade(codund)
                 .then((retorno) => commit(Mutations.ESTRUTURA.ESTACOES_UNIDADE, retorno))
                 .catch(error => log.error('Erro ao listar estações da unidade: '+error.erro))
    },
    [Actions.ESTRUTURA.ESTACOES_PESSOA]: ({ commit }, { codpes }) => {
        return api.listarEstacaoPessoa(codpes)
                 .then((retorno) => {
                    commit(Mutations.ESTRUTURA.ESTACOES_PESSOA, retorno)
                    return retorno;
                 })
                 .catch(error => log.error('Erro ao listar estações da pessoa: '+error.erro))
    },
    [Actions.ESTRUTURA.ESTACOES_USUARIO]: ({ commit }) => {
        return api.listarEstacaoUsuario()
                 .then((retorno) => commit(Mutations.ESTRUTURA.ESTACOES_USUARIO, retorno))
                 .catch(error => log.error('Erro ao listar estações do usuário: '+error.erro))
    },
    [Actions.ESTRUTURA.TIPO_CONFERENCIA]: ({ commit, state }) => {
        if(!state.statusTiposConferencia && state.tiposConferencia.length === 0){
            commit(Mutations.ESTRUTURA.STATUS_TIPO_CONFERENCIA, true)
            return api.listarTiposConf()
                    .then((retorno) => commit(Mutations.ESTRUTURA.TIPO_CONFERENCIA, retorno))
                    .catch(error => log.error('Erro ao listar tipos de conferência de documento: '+error.erro))
                    .finally(() => commit(Mutations.ESTRUTURA.STATUS_TIPO_CONFERENCIA, false))
        }
    },
    [Actions.ESTRUTURA.TIPO_DOCUMENTO]: ({ commit }, { codasu }) => {
        commit(Mutations.ESTRUTURA.STATUS_TIPO_DOCUMENTO, true)
        return api.listarTiposDoc(codasu)
                .then((retorno) => commit(Mutations.ESTRUTURA.TIPO_DOCUMENTO, retorno))
                .catch(error => log.error('Erro ao listar tipos de documento: '+error.erro))
                .finally(() => commit(Mutations.ESTRUTURA.STATUS_TIPO_DOCUMENTO, false))
    },
    [Actions.ESTRUTURA.UNIDADE]: ({ commit }) => {
        return api.listarUnidades()
                 .then((retorno) => commit(Mutations.ESTRUTURA.UNIDADE, retorno))
                 .catch(error => log.error('Erro ao listar unidades: '+error.erro))
    },
    [Actions.ESTRUTURA.MEMBROS]: (_c, { sgleta }) => {
        return api.listarMembros(sgleta)
                 .catch(error => log.error('Erro ao listar membros: '+error.erro))
    },
}

// mutations
const mutations = {
    [Mutations.ESTRUTURA.ASSUNTOS]: (st, assuntos) => st.assuntos = assuntos,
    [Mutations.ESTRUTURA.ESTACOES_PESSOA]: (st, estacoesPessoa) => st.estacoesPessoa = estacoesPessoa,
    [Mutations.ESTRUTURA.ESTACOES_UNIDADE]: (st, estacoesUnidade) => st.estacoesUnidade = estacoesUnidade,
    [Mutations.ESTRUTURA.ESTACOES_USUARIO]: (st, estacoesUsuario) => st.estacoesUsuario = estacoesUsuario,
    [Mutations.ESTRUTURA.NOVO_TIPO_DOCUMENTO]: (st, tipoDocumento) => {
        if(!st.tiposDocumento.find(td => td.codetrvlrpdl === tipoDocumento.codetrvlrpdl)){
            st.tiposDocumento.push(TipoDocumento.buildFromJson(tipoDocumento))
        }
    },
    [Mutations.ESTRUTURA.TIPO_CONFERENCIA]: (st, tipos) => st.tiposConferencia = tipos,
    [Mutations.ESTRUTURA.TIPO_DOCUMENTO]: (st, tipos) => st.tiposDocumento = tipos,
    [Mutations.ESTRUTURA.STATUS_TIPO_CONFERENCIA]: (st, status) => st.statusTiposConferencia = status,
    [Mutations.ESTRUTURA.STATUS_TIPO_DOCUMENTO]: (st, status) => st.statusTiposDocumento = status,
    [Mutations.ESTRUTURA.UNIDADE]: (st, unidades) => st.unidades = unidades,
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}