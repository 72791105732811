export default class FuncaoProServ {

  constructor(codfncprs, nomfncprs, staexiareatc) {
      this.codfncprs = codfncprs
      this.nomfncprs = nomfncprs
      this.staexiareatc = staexiareatc
  }

  getCodigo(){
      return this.codfncprs
  }

  getTexto(){
      return `(${this.codfncprs}) - ${this.nomfncprs}`
  }

  toString(){
      return this.getTexto()
  }

  static fromJSON(json={}){
    return new FuncaoProServ(
      json.codfncprs,
      json.nomfncprs,
      json.staexiareatc
    )
  }
}