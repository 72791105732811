const WebdocValidarDocumento = () => import(/* webpackChunkName: "chunk-webdoc" */ '@/webdoc/views/WebdocValidarDocumento.vue')
const WebdocTiposDocumentos = () => import(/* webpackChunkName: "chunk-webdoc" */ '@/webdoc/views/WebdocTiposDocumentos.vue')

export const routes = [
    {
      path: "/:codaucdoc([\\-\\w]{0,50})?",
      name: "validadocweb",
      component: WebdocValidarDocumento, 
      props: route => ({codaucdoc: (route.params.codaucdoc || "").replaceAll("-","")}),
      beforeEnter(to, from, next) {
        if (to.params.codaucdoc == "tiposdocumentos") {
          next({name: "Iddigital:tiposdocumentos"});
        } else next();
      },
      meta: { 
        breadcrumb: [
          { nome: 'IDDigital' },
          { nome: 'Validar Documento' },
        ]
      }
    },
    {
      path: "/tiposdocumentos",
      name: "tiposdocumentos",
      component: WebdocTiposDocumentos,
      meta: {
        breadcrumb: [
          { nome: 'IDDigital' },
          { nome: 'Tipos de Documentos' },
        ]
      }
    },
];