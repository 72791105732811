import { Actions, Getters, Mutations } from "@/proserv/store/types"
import ProServServico from "../dominio/modelos/ProServServico.js"
import SolicitacaoProServ from "../dominio/modelos/SolicitacaoProServ.js"

const api = ProServServico.build({})

// Estado inicial
const module_state = {
    carregarSolicitacoes: false,
    avaliadores: [],
    arquivos: [],
    andamentos: [],
    solicitacao: new SolicitacaoProServ(),
    solicitacoes: [],
    areas: [],
    unidades: [],
    cargos: [],
    departamentos: [],
    funcoes: [],
    vertentes: [],
    avaliacao: []
}

// getters
const module_getters = {
    [Getters.PROSERV.AREAS]: (state) => state.areas,
    [Getters.PROSERV.ARQUIVOS]: (state) => state.arquivos,
    [Getters.PROSERV.AVALIACAO]: (state) => state.avaliacao,
    [Getters.PROSERV.ANDAMENTOS]: (state) => state.andamentos,
    [Getters.PROSERV.AVALIADORES]: (state) => state.avaliadores,
    [Getters.PROSERV.CARREGANDO_SOLICITACOES]:  (state) => state.carregarSolicitacoes,
    [Getters.PROSERV.CARGOS]: (state) => state.cargos,
    [Getters.PROSERV.UNIDADES]: (state) => state.unidades,
    [Getters.PROSERV.DEPARTAMENTOS]: (state) => state.departamentos,
    [Getters.PROSERV.FUNCOES]: (state) => state.funcoes,
    [Getters.PROSERV.SOLICITACAO]: (state) => state.solicitacao,
    [Getters.PROSERV.SOLICITACOES]: (state) => state.solicitacoes,
    [Getters.PROSERV.VERTENTES]: (state) => state.vertentes,
}

// actions
const module_actions = {
    [Actions.PROSERV.CARREGAR_DEPARTAMENTOS]: ({ commit }, unidade) => {
        return api.listarDepartamentos(unidade.codund)
            .then(lista => { 
                commit(Mutations.PROSERV.DEPARTAMENTOS, lista)
                return lista
            })
    },
    [Actions.PROSERV.CARREGAR_AVALIACAO]: ({ commit }, { anosolprs, codsolprs }) => {
        return api.listarAvaliacao(anosolprs, codsolprs)
        .then(lista => commit(Mutations.PROSERV.AVALIACAO, lista))
    },
    [Actions.PROSERV.CARREGAR_ARQUIVOS]: ({ commit }, { anosolprs, codsolprs }) => {
        return api.listarArquivos(anosolprs, codsolprs)
        .then(lista => commit(Mutations.PROSERV.ARQUIVOS, lista))
    },
    [Actions.PROSERV.CARREGAR_AVALIADORES]: ({ commit }, { anosolprs, codsolprs }) => {
        return api.listarAvaliadores(anosolprs, codsolprs)
        .then(lista => commit(Mutations.PROSERV.AVALIADORES, lista))
    },
    [Actions.PROSERV.CARREGAR_FUNCOES]: ({ commit }) => {
        return api.listarFuncoes()
                  .then(lista => commit(Mutations.PROSERV.FUNCOES, lista))
    },
    [Actions.PROSERV.CARREGAR_SOLICITACAO]: ({ commit }, { codsolprs, anosolprs }) => {
        return api.obterSolicitacao(anosolprs, codsolprs)
            .then(solicitacao => {
                commit(Mutations.PROSERV.SOLICITACAO, solicitacao)
                return solicitacao
            })
    },
    [Actions.PROSERV.CARREGAR_UNIDADES]: ({ commit }) => {
        return api.listarUnidades()
            .then(lista =>{ 
                commit(Mutations.PROSERV.UNIDADES, lista)
                return lista
            })
    },
    [Actions.PROSERV.CARREGAR_VERTENTES]: ({ commit }) => {
        return api.listarVertentes()
            .then(lista =>{ 
                commit(Mutations.PROSERV.VERTENTES, lista)
                return lista
            })
    },
    [Actions.PROSERV.CARREGAR_AREAS]: ({ commit }) => {
        return api.listarAreasConhecimento()
            .then(lista =>{ 
                commit(Mutations.PROSERV.AREAS, lista)
                return lista
            })
    },
    [Actions.PROSERV.CARREGAR_ANDAMENTOS]: ({ commit }, { codsolprs, anosolprs }) => {
        return api.listarAndamentos(anosolprs, codsolprs)
            .then(lista =>{ 
                commit(Mutations.PROSERV.ANDAMENTOS, lista)
                return lista
            })
    },
    [Actions.PROSERV.ENVIAR_SOLICITACAO]: (_c, { codsolprs, anosolprs, tipsitprs }) => {
        return api.enviarSolicitacao(anosolprs, codsolprs, tipsitprs)
    },
    [Actions.PROSERV.OBTER_ARQUIVO]: (_c, { anosolprs, codsolprs,numseqarqprs }) =>{
        return api.obterArquivo(anosolprs, codsolprs,numseqarqprs)
    },
    [Actions.PROSERV.OBTER_PESSOA]: (_c, { codpes }) =>{
        return api.obterDadosPessoa(codpes)
    },
    [Actions.PROSERV.LISTAR_SOLICITACOES]: ({ commit }, dados) => {
        commit(Mutations.PROSERV.CARREGAMENTO_SOLICITACOES, true)
        return api.listarSolicitacoes(dados)
                 .then(lista => {
                    commit(Mutations.PROSERV.SOLICITACOES, lista)
                    return lista
                 })
                 .finally(() => commit(Mutations.PROSERV.CARREGAMENTO_SOLICITACOES, false))
    },

    [Actions.PROSERV.SALVAR_SOLICITACAO]: ({ commit }, form) => {
        return api.inserir(form)
            .then(solicitacao => {
                commit(Mutations.PROSERV.ADICIONAR_SOLICITACAO, solicitacao)
                return solicitacao
            })
    },
    [Actions.PROSERV.SALVAR_DETALHES]: ({ commit }, { solicitacao, texto, propriedade }) => {
        return api[solicitacao.detalhes.isVazio() ? 'inserirDetalhe' : 'alterarDetalhe'](
                propriedade,
                texto,
                solicitacao.anosolprs, 
                solicitacao.codsolprs
            )
            .then(detalhes => commit(Mutations.PROSERV.ATUALIZAR_SOLICITACAO, { solicitacao, detalhes: detalhes }))
    },
    [Actions.PROSERV.SALVAR_ARQUIVO]: ({ commit }, { solicitacao, arquivo }) => {
        return api.inserirArquivo(solicitacao.anosolprs, solicitacao.codsolprs, arquivo)
            .then(arq => commit(Mutations.PROSERV.ARQUIVO, arq))
    },
    [Actions.PROSERV.SALVAR_AVALIACAO]: (_c, dados) => {
        return api.salvarAvaliacao(dados)
    },
    [Actions.PROSERV.SALVAR_AVALIADOR]: ({ commit }, { anosolprs, codsolprs, codpesavd }) => {
        return api.salvarAvaliador(anosolprs, codsolprs, codpesavd)
            .then(avaliador => {
                commit(Mutations.PROSERV.AVALIADOR, avaliador)
                return avaliador
            })
    },
    [Actions.PROSERV.REMOVER_SOLICITACAO]: ( _c, solicitacao) => {
        return api.removerSolicitacao(solicitacao.anosolprs, solicitacao.codsolprs)
    },
    [Actions.PROSERV.REMOVER_ARQUIVO]: ({ commit }, { solicitacao, arquivo }) => {
        return api.removerArquivo(solicitacao.anosolprs, solicitacao.codsolprs, arquivo.numseqarqprs)
            .then(() => commit(Mutations.PROSERV.REMOVER_ARQUIVO, arquivo))
    },
    [Actions.PROSERV.REMOVER_AVALIADOR]: ({ commit }, { anosolprs, codsolprs, codpesavd }) => {
        return api.removerAvaliador(anosolprs, codsolprs, codpesavd)
            .then(() => commit(Mutations.PROSERV.REMOVER_AVALIADOR, { codpesavd }))
    }
}

// mutations
const module_mutations = {
    [Mutations.PROSERV.AREAS]: (state, areas) => state.areas = areas,
    [Mutations.PROSERV.ARQUIVOS]: (state, arquivos) => state.arquivos = arquivos,
    [Mutations.PROSERV.ANDAMENTOS]: (state, andamentos) => state.andamentos = andamentos,
    [Mutations.PROSERV.AVALIADOR]: (state, avaliador) => state.avaliadores.push(avaliador),
    [Mutations.PROSERV.AVALIADORES]: (state, avaliadores) => state.avaliadores = avaliadores,
    [Mutations.PROSERV.AVALIACAO]: (state, avaliacao) => { 
        state.avaliacao = []
        avaliacao.forEach(item => {
            let pai = state.avaliacao.find(a => a.tipcrtprs.nomtipcrtprs === item.tipcrtprs.nomtipcrtprs)
            if(!pai){
                state.avaliacao.push(item)
            } else {
                pai.filhos.push(item)
            }
        })
    },
    [Mutations.PROSERV.CARGOS]: (state, cargos) => state.cargos = cargos,
    [Mutations.PROSERV.CARREGAMENTO_SOLICITACOES]: (state, carregamento) => state.carregarSolicitacoes = carregamento,
    [Mutations.PROSERV.DEPARTAMENTOS]: (state, deps) => state.departamentos = deps,
    [Mutations.PROSERV.DELETE_CARGO]: (state, cargo) => state.cargos = state.cargos.filter(c => c !== cargo),
    [Mutations.PROSERV.FUNCOES]: (state, funcoes) => state.funcoes = funcoes,
    [Mutations.PROSERV.UNIDADES]: (state, unidades) => state.unidades = unidades,
    [Mutations.PROSERV.SOLICITACAO]: (state, solicitacao) => state.solicitacao = solicitacao,
    [Mutations.PROSERV.SOLICITACOES]: (state, solicitacoes) => state.solicitacoes = solicitacoes,
    [Mutations.PROSERV.VERTENTES]: (state, vertentes) => state.vertentes = vertentes,
    [Mutations.PROSERV.ARQUIVO]: (state, arquivo) => state.arquivos.push(arquivo),
    [Mutations.PROSERV.REMOVER_ARQUIVO]: (state, arquivo) => state.arquivos = state.arquivos.filter(arq => arq !== arquivo),
    [Mutations.PROSERV.REMOVER_AVALIADOR]: (state, { codpesavd }) => state.avaliadores = state.avaliadores.filter(a => a.codpesavd != codpesavd),
    [Mutations.PROSERV.ADICIONAR_SOLICITACAO]: (state, solicitacao) => {
        let sol = state.solicitacoes.find(s => s.codsolprs === solicitacao.codsolprs && s.anosolprs === solicitacao.anosolprs)
        if(!sol){
            state.solicitacoes.push(solicitacao)
            return
        }
        sol.undste = solicitacao.undste
        sol.setste = solicitacao.setste
        sol.dctrsp = solicitacao.dctrsp
        sol.vtesolprs = solicitacao.vtesolprs
    },
    [Mutations.PROSERV.ATUALIZAR_SOLICITACAO]: (_s, { solicitacao, detalhes }) => {
        Object.getOwnPropertyNames(detalhes)
            .forEach(prop => {
                if(detalhes[prop]){
                    solicitacao.detalhes[prop] = detalhes[prop]
                }
            })
    }
}

export default {
    namespaced: false,
    state: module_state,
    getters: module_getters,
    actions: module_actions,
    mutations: module_mutations
}