<template>
  <li class="nav-item">
    <a 
      :href="linkAparente" 
      :class="classeLink" 
      @click.prevent="ativarLink"
    >
      <em :class="iconeLink" />
      <p>{{ arvore.nome }} <br> 
        <span style="font-weight: 300;">{{ arvore.nomeSistema }}</span> 
        <em 
          :class="iconeArvore" 
          class="icone-arvore" 
        />
      </p>
    </a>
    <ul 
      v-if="possuiSubmenu && ativo"
      class="nested" 
      :class="{ active: ativo }" 
    >
      <PortalNavItemMenu 
        v-for="i in arvore.publico" 
        :key="i.codigo"
        :menu="i"
      />
      <PortalNavItemMenu 
        v-for="i in arvore.privado" 
        v-show="carregado"
        :key="i.codigo"
        :menu="i"
      />
      <PortalNavArvoreMenu
        v-for="a in arvore.subarvores"
        v-show="carregado"
        :key="a.codigo"
        :arvore="a"
      />
      <li v-show="!carregado">
        <div class="usp-loading">
          <div class="usp-loading-item" />
          <div class="usp-loading-item" />
        </div>
      </li>
    </ul>
  </li>
</template>
<script>
import ArvoreMenu from '@/portal/dominio/navegacao/ArvoreMenu';
import PortalNavItemMenu from './PortalNavItemMenu';
import { mapGetters } from 'vuex';
import { Getters } from '@/portal/store/types';

export default {

    name: 'PortalNavArvoreMenu',

    components: {
        PortalNavItemMenu,
    },

    props: {
        arvore: {
            type: ArvoreMenu,
            required: true
        }
    },

    data() {
        return {
            ativo: false
        }
    },

    computed: {
        ...mapGetters({
            carregadoJanus: Getters.NAVEGACAO.STATUS_CARREGADO_JANUS
        }),
        carregado() {
            return this.carregadoJanus(this.arvore);
        },
        classeLink() {
            return 'nav-link nav-link-treeview' + (this.ativo ? ' caret-down' : '');
        },
        iconeArvore() {
            return this.ativo ? 'right fas fa-angle-down' : 'right fas fa-angle-right';
        },
        iconeLink () {
            return this.arvore.icone;
        },
        linkAparente(){
            return this.arvore.getUrl();
        },
        possuiSubmenu(){
            return this.arvore.temFilho();
        }
    },

    methods: {
        ativarLink(){
            this.ativo = !this.ativo
        }
    }

}
</script>
