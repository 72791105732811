export default class Unidade {

    constructor(sglund, nomund, codund) {
        this.sglund = sglund
        this.nomund = nomund
        this.codund = codund
    }

    getCodigo(){
        return this.codund
    }

    getTexto(){
        return `${this.sglund} - ${this.nomund}`
    }

    toString(){
        return this.getTexto()
    }

    static buildFromJson(json={}){
        return new Unidade(
            json.sglund,
            json.nomund, 
            json.codund
        )
    }
}