export default class TipoConferencia {

    constructor(codetrvlrpdl, dscetrvlrpdl, codtipetrpdl) {
        this.codetrvlrpdl = codetrvlrpdl
        this.dscetrvlrpdl = dscetrvlrpdl
        this.codtipetrpdl = codtipetrpdl
    }

    static buildFromJson(json={}){
        return new TipoConferencia(
            json.codetrvlrpdl,
            json.dscetrvlrpdl,
            json.codtipetrpdl
        )
    }
}