<template>
  <div class="usp-abas">
    <ul class="nav nav-tabs">
      <li
        v-for="item in itens"
        :id="item.id"
        :key="item.key"
        class="nav-item"
        :active="abaAtiva == item"
        :disabled="item.desabilitada"
        @click="trocarAba(item)"
      >
        <a 
          class="nav-link"
          :class="{ active: abaAtiva == item }"
        >
          {{ item.titulo }}
        </a>
      </li>
    </ul>
    <div
      v-if="abaAtiva && abaAtiva.componente"
      class="tab-container"
    >
      <component 
        :is="abaAtiva.componente" 
        @trocarAba="trocarAba"
      />
    </div>
  </div>
</template>

<script>
import UspIdUnico from '@/componentes/plugins/UspIdUnico';
export default {
  name: 'UspAbas',

  props: {
    itens: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      abaAtiva: null
    };
  },

  created() {
    /* Criar identificador para cada aba */
    this.itens.forEach((item) => {
      let c = item.nome
      item.id = UspIdUnico.gerar()
      item.key = `${item.id}_${item.nome}`
      this.$options.components[c] = () => item.componente
    })
    this.abaAtiva = this.itens[0]
  },

  methods: {
    trocarAba(aba) {
      if(Number.isInteger(aba)){
        this.abaAtiva = this.itens[aba]
      } else if(typeof aba === 'object') {
        this.abaAtiva = aba
      } else if(typeof aba === 'string') {
        this.abaAtiva = this.itens.find(i => i.nome === aba)
      }
    },
    mudarEstadoAba(lista, estado) {
      this.abas
        .filter((item) => {
          return lista.includes(item.aba);
        })
        .forEach((item) => {
          item.desabilitada = estado;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.usp-abas {
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}
</style>
