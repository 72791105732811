<template>
  <li class="nav-item">
    <a 
      :href="link" 
      :class="classeLink" 
      @click.prevent="ativarLink"
    >
      <em :class="icone" />
      <p>
        {{ nome }} 
        <em :class="iconeArvore" />
      </p>
    </a>
    <ul 
      class="nested" 
      :class="{ active: ativo }"
    >
      <li 
        v-for="item in itens"
        :key="item.codigo"
        class="nav-item"
      >
        <a 
          :href="item.url" 
          class="nav-link nav-link-treeview" 
          target="_blank"
        >
          <em :class="item.icone" />
          <p>{{ item.nome }}</p>
        </a>
      </li>
    </ul>
  </li>
</template>
<script>

export default {

    name: 'PortalNavOutros',

    data() {
        return {
            ativo: false,
            icone: 'fas fa-hands-helping',
            link: '/parcerias-e-beneficios/',
            nome: 'Parcerias e Benefícios',
            itens:[{
                nome: 'Dell',
                url: 'https://uspdigital.usp.br/comumwebdev/arquivos/portal/USP-DELL-20240815.pdf ',
                icone: 'fas fa-laptop'
            },{
                nome: 'SAMSUNG',
                url: 'https://uspdigital.usp.br/comumwebdev/arquivos/portal/USP-SAMSUNG.pdf',
                icone: 'fas fas fa-phone-square-alt'
            },{
                nome: 'SAMSUNG FLIP5',
                url: 'https://uspdigital.usp.br/comumwebdev/arquivos/portal/USP-SAMSUNG-FLIP5.png',
                icone: 'fas fas fa-mobile'
            },{
                nome: 'SAMSUNG FOLD5',
                url: 'https://uspdigital.usp.br/comumwebdev/arquivos/portal/USP-SAMSUNG-FOLD5.png',
                icone: 'fas fas fa-mobile-alt'
            }]
        }
    },

    computed: {
        classeLink() {
            return 'nav-link nav-link-treeview' + (this.ativo ? ' caret-down' : '');
        },
        iconeArvore() {
            return this.ativo ? 'right fas fa-angle-down' : 'right fas fa-angle-right';
        },
    },

    methods: {
        ativarLink(){
            this.ativo = !this.ativo
        }
    }

}
</script>