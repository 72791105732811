import Arquivo from "./Arquivo"
import Assinatura from "./Assinatura"
import TipoOcorrencia from "./TipoOcorrencia"

export default class Ocorrencia {

    constructor(numand, numseqocoand, dtacad, codpescad, nompescad, txtdch, numpro) {
        this.numand = numand
        this.numseqocoand = numseqocoand
        this.dtacad = dtacad
        this.codpescad = codpescad || (localStorage.getItem('AUTH_USUARIO_DISPLAY').split('-')[0])
        this.nompescad = nompescad || (localStorage.getItem('AUTH_USUARIO_DISPLAY').split('-')[1])
        this.txtdch = txtdch || ''
        this.numpro = numpro
    }

    getDescricaoArquivo(){
        if(this.arquivo){
            return `${this.arquivo.nomarq} - ${this.arquivo.qtdpgn} página(s) - ${this.getTamanhoFmt(this.arquivo.tamarq)}`
        } else if(this.temporario){
            return `${this.temporario.nomarqtmp} - ${this.getTamanhoFmt(this.temporario.tamarqtmp)}`
        } else {
            return 'Ocorrência sem arquivo...'
        }
    }

    getKey(){
        return `ocorrencia-${this.numand}-${this.numseqocoand}`
    }

    getTamanhoFmt(tamanho_bytes){
        const unidades = ['B', 'KB', 'MB']
        let tamanho = tamanho_bytes
        let count = 0
        while((tamanho / 1024) > 1) {
            tamanho = (tamanho / 1024).toFixed(2)
            count++
        }
        return `${tamanho} ${unidades[count]}`
    }

    podeEditar(){
        return this.tipo.podeEditar()
    }

    podeVer(){
        return !!this.arquivo?.codarqpdl
    }

    podeVisualizar(){
        return this.tipo.codetroco === 'ocoGeracao'
    }

    setDados(dados){
        this.numand = dados.numand || this.numand
        this.numseqocoand = dados.numseqocoand || this.numseqocoand
        this.dtacad = dados.dtacad || this.dtacad
        this.codpescad = dados.codpescad || this.codpescad
        this.nompescad = dados.nompescad || this.nompescad
        this.txtdch = dados.txtdch || this.txtdch
    }

    static buildFromJson(json, numpro){
        const oco = new Ocorrencia(
            json.numand,
            json.numseqocoand,
            json.dtacad,
            json.codpescad,
            json.nompescad,
            json.txtdch,
            numpro
        )
        oco.assinatura = Assinatura.buildFromJson(json)
        oco.arquivo = Arquivo.buildFromJson(json)
        oco.tipo = TipoOcorrencia.buildFromJson(json)
        return oco
    }
}