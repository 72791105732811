<template>
  <li class="list-group-item">
    <slot name="default" />
  </li>
</template>

<script>
export default({
    name: "UspListaItem"
})
</script>
