<template>
    <section id="destaque">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">
                    <PortalNoticiasCartao />
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <PortalTarefasCartao />
                </div>
                <div class="col-xl-6">
                    <PortalAvisosCartao />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import PortalTarefasCartao from './tarefas/PortalTarefasCartao';
import PortalAvisosCartao from './avisos/PortalAvisosCartao';
import PortalNoticiasCartao from './noticias/PortalNoticiasCartao';

export default {

    name: 'PortalPainelDestaque',

    components:{
        PortalTarefasCartao,
        PortalNoticiasCartao,
        PortalAvisosCartao
    }
    
}
</script>
<style scoped>

</style>