const Monitoramento = () => import(/* webpackChunkName: "chunk-pedroweb" */ '../views/Monitoramento')
const ComumwebListagem = () => import(/* webpackChunkName: "chunk-pedroweb" */ '../views/ComumwebListagem')

export const BASE_NAME = 'interno-sti';
export const COMUMWEB_PATH = 'comumweb';

export const routes = [
  {
    path: '/monitoramento',
    name: 'Monitoramento',
    component: Monitoramento,
    meta: {
      logado: true,
      breadcrumb:[
        { nome: 'Monitoramento' }
      ]
    }
  },
  {
    path: `/${COMUMWEB_PATH}/:path*`,
    name: 'comumweb',
    component: ComumwebListagem,
    meta: {
      logado: true,
      breadcrumb: []
    }
  }
]