<template>
    <div class="acoes">
        <button type="button"
                class="btn btn-default btn-sm"
                v-if="tarefa.podeSeguir()"
                aria-label="Visualizar tarefa"
                @click="seguirLinkTarefa(tarefa)">
            <i class="fas fa-external-link-alt"></i>
        </button>
        <button type="button"
                class="btn btn-default btn-sm"
                @click="tarefaAdiar(tarefa)"
                aria-label="Adiar tarefa"
                v-if="tarefa.podeAdiar()">
            <i class="fas fa-stopwatch"></i>
        </button>
        <button type="button"
                class="btn btn-default btn-sm"
                v-if="tarefa.podeResolver()"
                aria-label="Concluir tarefa"
                @click="baixarTarefa(tarefa)">
            <i class="fas fa-check"></i>
        </button>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { Actions, Mutations } from '../../../store/types';
import Tarefa from '@/portal/dominio/notificacoes/Tarefa';
import eventBus from '@/eventBus';
export default {

    name: 'PortalTarefasAcoes',

    data(){
        return {
            msg: {
                erro: {
                    titulo: 'Erro ao baixar tarefa!',
                },
                sucesso: {
                    titulo: 'Baixa de tarefa',
                    mensagem: 'Tarefa marcada como concluída com sucesso!'
                }
            }
        };
    },

    props: {
        tarefa: {
            required: true,
            type: Tarefa
        }
    },

    methods: {
        ...mapActions({
            baixar: Actions.NOTIFICACOES.BAIXAR_TAREFA,
        }),
        ...mapMutations({
           tarefaAdiar: Mutations.NOTIFICACOES.TAREFA_PARA_ADIAR 
        }),
        baixarTarefa(tarefa){
            this.baixar(tarefa)
                .then(() => {
                    eventBus.mostrarMensagemSucesso(this.msg.sucesso.mensagem, this.msg.sucesso.titulo);
                }).catch((erro) => {
                    eventBus.mostrarMensagemErro(erro.mensagem, this.msg.erro.titulo);
                    this.$logger.error('Erro ao baixar tarefa: '+erro.mensagemDesenvolvedor);
                });
        },
        seguirLinkTarefa(tarefa) {
            let url = tarefa.url;
            if(url.indexOf('http') !== -1){
                window.open(url, '_blank');
            } else {
                if(url[0] === '/'){
                    url = url.substring(1);
                }
                this.$router.push(`/servicos/${tarefa.codigoArea}/${url}`);
            }
        }
    }

}
</script>

<style>

</style>