export default class VertenteProServ {

  constructor(chave, valor, texto, limite) {
      this.chave = chave
      this.valor = valor
      this.texto = texto
      this.limite = limite
  }

  getCodigo(){
      return this.chave
  }

  getTexto(){
      return `${this.texto}`
  }

  toString(){
      return this.getTexto()
  }

  static fromJSON(json={}){
    return new VertenteProServ(
      json.chave,
      json.valor,
      json.texto,
      json.limite
    )
  }
}