import { PortalServico } from '@/utils'
import ItemMenu from './ItemMenu';
import Navegacao from './Navegacao'
import ObjetoMenu from './ObjetoMenu';
/**
 * @type {NavegacaoServico}
 */
export default class NavegacaoServico extends PortalServico {

    obterNavegacaoJanus(arvorePos) {
        const self = this;
        return self.get('/listarMenuUsuario', {}, { baseURL: '/janus' })
                   .then(resultado => ItemMenu.buildLista(resultado, arvorePos));
    }

    obterNavegacaoPublica() {
        const self = this;
        return self.get('/wsportal/navegacao/publica')
                    .then(resultado => Navegacao.build(resultado));
    }

    obterNavegacaoPrivadaPedroweb(){
        return this.get('/wsportal/navegacao/privada').then(resultado => Navegacao.build(resultado));
    }

    async obterNavegacaoPrivadaJanus(){
        return await this.get('listarMenuUsuario', {}, { baseURL: '/janus' })
    }
    
    obterNavegacaoPrivada() {
        const self = this;

        return Promise.all([
            self.get('/wsportal/navegacao/privada').then(resultado => Navegacao.build(resultado)),
            self.get('listarMenuUsuario', {}, { baseURL: '/janus' })
        ]).then(resultado => {
            if(resultado[1] && resultado[1].length > 0){
                const pos = resultado[0].arvores.find(a => a.codigo === 'pos-graduacao');
                const lista = ItemMenu.buildLista(resultado[1], pos);
                pos.addMenuprivado(lista);
            }

            return resultado[0];
        });
    }

    obterNavegacaoObjetoArea(objeto, area, auth) {
        const self = this;
        return self.get(`/wsportal/navegacao/objeto/${auth ? 'autenticado' : 'publico'}/area/${area}/${self._escapeObjetoweb(objeto)}`)
                    .then(resultado => ObjetoMenu.build(resultado))
    }

    obterNavegacaoObjetoLegado(objeto, contexto, auth){
        const self = this;
        return self.get(`/wsportal/navegacao/objeto/${auth ? 'autenticado' : 'publico'}/contexto/${contexto}/${self._escapeObjetoweb(objeto)}`)
                    .then(resultado => ObjetoMenu.build(resultado))
    }

    obterSessaoJanus() {
        const self = this;
        return self.get('/', {}, { baseURL: '/janus' });
    }

    _escapeObjetoweb(objetoweb){
        return (objetoweb || '').replace('.jsp', '_jsp')
                                .replace('.jsf', '_jsf')
                                .replaceAll('/','--');
    }

}