<template>
    <li class="nav-item" v-bind:class="{ active: ativo}">
        <a :href="this.menu.getUrl()" :class="classeLink" v-on:click.prevent="ativarLink" :target="target">
			<i :class="iconeLink"></i>
            <p style="hyphens: none;" >{{ menu.titulo }} <i v-if="temFilhos" :class="iconeArvore"></i></p>
        </a>
        <ul v-if="temFilhos" class="nested" v-bind:class="{ active: ativo}">
            <PortalNavItemMenu 
                v-for="i in menu.filhos" 
                :key="i.codigo"
                :menu="i"
             />
        </ul>
    </li>
</template>
<script>
import ItemMenu from '@/portal/dominio/navegacao/ItemMenu';

export default {

    name: 'PortalNavItemMenu',

    props: {
        menu: {
            type: ItemMenu,
            required: true
        }
    },

    data() {
        return {
            ativo: false
        }
    },

    computed: {
        linkAparente(){
            return this.menu.getUrl();
        },
        classeLink() {
            return 'nav-link ' +
                        (this.menu.filhos.length ? 'nav-link-treeview': '') +
                        (this.ativo ? ' caret-down' : '');
        },
        iconeLink () {
            return 'd-none';
        },
        temFilhos() {
            return !!this.menu.filhos.length;
        },
        iconeArvore() {
            return this.ativo ? 'right fas fa-angle-up' : 'right fas fa-angle-right';
        },
        target() {
            return this.menu.linkExterno ? '_blank' : '_self';
        }
    },

    methods: {
        ativarLink(){
            if(this.menu.ePai()){
                this.ativo = !this.ativo;
            } else if(this.menu.eExterno()) {
                window.open(this.menu.getUrl(), 'blank');
            } else {
                this.$router.push(this.menu.getUrl());
            }
        }
    }

}
</script>
<style scoped>

</style>